import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
const CONSULTATIONS = gql`
  query consultations(
    $where: ConsultationWhereInput
    $orderBy: [ConsultationOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $take: Int # $first: Int # $last: Int
  ) {
    findManyConsultation: findManyConsultation(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take # first: $first # last: $last
    ) {
      id
      scheduledAt
      cancelledAt
      clinic {
        name
      }
      petParent {
        user {
          firstName
          lastName
        }
      }
      cost
      clientCost
      pet {
        name
        firebaseId
      }
      consultationType
      consultationState
      summary
      details
      treat
      callType
      createdAtCopy
      createdAt: createdAtCopy
      openedAt
      resolvedAt
      isVetEmailed
      isVideoChat
      vet {
        user {
          firstName
          lastName
          email
          firebaseId
        }
      }
      firebaseId
    }
    findManyConsultationCount: findManyConsultationCount(where: $where)
  }
`;
const REFUND_CONSULTAION = gql`
  mutation refund($data: RefundConsultationInput) {
    refundConsultation(data: $data) {
      success
    }
  }
`;
function useConsultationsQuery() {
  const [loadConsultations, { called, loading, data }] = useLazyQuery(
    CONSULTATIONS,
    {
      fetchPolicy: "network-only",
    }
  );

  return [loadConsultations, { called, loading, data }];
}
function useRefundConsultationMutation() {
  return useMutation(REFUND_CONSULTAION, { fetchPolicy: "no-cache" });
}
export { useConsultationsQuery, useRefundConsultationMutation };
