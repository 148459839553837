import React from "reactn";
import FormComponent from "../../app/form";
import { useGenericMutation } from "../../app/genericMutations";
import { useHistory } from "react-router-dom";
import { useGenericQuery } from "../../app/genericQuery";
export default function FacilityForm({
  match: {
    params: { id },
  },
}) {
  const [update] = useGenericMutation({ type: "update", entity: "User" });
  const history = useHistory();
  const useQuery = () =>
    useGenericQuery({
      query: "users",
      entity: "User",
      fragment: `{
  id
  email
  firstName
  lastName
  type
  phone
}`,
    });

  async function handleUpdate(state) {
    await update({
      variables: { data: { ...state, id: undefined }, where: { id } },
    });
    history.push("/users");
  }
  return (
    <FormComponent
      onCreate={() => {}}
      onUpdate={handleUpdate}
      useQuery={useQuery}
      onCancel={() => history.push(`/users`)}
      queryName="users"
      id={id}
      title="User"
      fields={[
        [
          { display: "First Name", property: "firstName", required: true },
          { display: "Last Name", property: "lastName", required: true },
        ],
        [{ property: "email", required: true }, { property: "phone" }],
        [
          {
            property: "type",
            display: "User Type",
            options: ["OWNER", "VET"].map((v) => ({ key: v, display: v })),
            type: "select",
          },
          {},
        ],
      ]}
    />
  );
}
