import React, { useGlobal, useEffect } from "reactn";
import { Button } from "../../../styledComponents/form";
import { Row, C1 } from "../../../styledComponents/grid";
import Container from "../../../styledComponents/container";
import { useHistory } from "react-router-dom";
import { useFirebaseQuery } from "../../queries/firebase";
import styled from "styled-components/macro";
const Pre = styled.pre`
  font-family: monospace, monospace;
  font-size: 9px;
`;
function Json() {
  const [json] = useGlobal("json");
  const [getFirebaseObject, { data }] = useFirebaseQuery();
  const history = useHistory();

  useEffect(() => {
    if (json.data?.firebaseId) {
      getFirebaseObject({
        variables: { data: { table: json.type, id: json.data.firebaseId } },
      });
    }
  }, [json, getFirebaseObject]);
  return (
    <Container>
      <Row>
        <Button onClick={() => history.goBack()}>back</Button>
      </Row>

      <Row>
        {data ? (
          <C1>
            <Row>
              <C1>
                <h3>Firebase</h3>
              </C1>
            </Row>

            <Row>
              <C1>
                <Pre>
                  {data ? JSON.stringify(data.firebaseObject, null, 2) : ""}
                </Pre>
              </C1>
            </Row>
          </C1>
        ) : null}
        <C1>
          <Row>
            <C1>
              <h3>Postgres</h3>
            </C1>
          </Row>
          <Row>
            <C1>
              <Pre> {json ? JSON.stringify(json.data, null, 2) : ""}</Pre>
            </C1>
          </Row>
        </C1>
      </Row>
    </Container>
  );
}

export default Json;
