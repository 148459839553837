/* eslint-disable react/jsx-no-target-blank */
import React from "reactn";
import styled from "styled-components/macro";

const stripeEnv = process.env.REACT_APP_ENV !== "production" ? "test/" : "";
const baseStripeUrl = `https://dashboard.stripe.com/${stripeEnv}`;

export default function AddonDetail(props) {
  const { addon, setActiveAddon } = props;

  const publishedStatus = addon.published ? "published" : "unpublished";
  const description = addon.description.slice(0, 100);

  return (
    <Container key={addon.id}>
      <AddonTitle addon={addon}>{addon.title}</AddonTitle> - {publishedStatus} -{" "}
      {description} -{" "}
      <button
        onClick={() => {
          setActiveAddon(addon);
        }}
      >
        Update
      </button>
      {/* <button onClick={() => { deleteAddon(addon.id) }}>Delete</button> */}
    </Container>
  );
}

const AddonTitle = (props) => {
  const { addon } = props;

  if (!!addon.stripePriceId) {
    const stripePriceUrl = `${baseStripeUrl}prices/${addon.stripePriceId}`;
    // eslint-disable-next-line react/jsx-no-target-blank
    return (
      <b>
        <a href={stripePriceUrl} target="_blank">
          {props.children}
        </a>
      </b>
    );
  } else {
    return <b>{props.children}</b>;
  }
};

const Container = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  margin: 5px;
`;
