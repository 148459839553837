import React from "reactn";
import { env as appEnv } from "../../../api/env";

export default function Playground() {
  const qa = parseInt(process.env.QA);
  let qaURL;
  if (qa) {
    // Change this once we reduce the number of old QA environments
    const lastHardQAnumber = 20;
    if (qa > lastHardQAnumber) {
      qaURL = `https://core-api.qa${qa}.dev.televet.com`;
    } else if (qa <= lastHardQAnumber) {
      qaURL = `https://qa${qa}.core-api.gettelevet.com`;
    }
  }

  const sources = {
    development: qaURL || `https://qa1.core-api.gettelevet.com`,
    demo: "https://core-api.demo.gettelevet.com/",
    production: "https://core-api.production.gettelevet.com/",
  };

  const parentStyle = {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    display: "flex",
  };

  const playgroundStyle = {
    // width: "400px"
    flex: "1",
    padding: "15px",
  };
  console.log("core api uri = " + process.env.REACT_APP_CORE_API_URI);
  console.log("app env = " + process.env.REACT_APP_ENV);
  return (
    <div style={parentStyle}>
      {appEnv.REACT_APP_CORE_API_URI ? (
        <embed style={playgroundStyle} src={appEnv.REACT_APP_CORE_API_URI} />
      ) : (
        <embed
          style={playgroundStyle}
          src={
            process.env.REACT_APP_ENV === "local"
              ? sources["development"]
              : sources[process.env.REACT_APP_ENV]
          }
        />
      )}
    </div>
  );
}
