import gql from "graphql-tag";
import { FEATURE_FLAG_FRAGMENT } from "../queries";

export const CREATE_FEATURE_FLAG = gql`
  mutation createFeatureFlag($data: FeatureFlagCreateInput!) {
    createOneFeatureFlag(data: $data) {
      ...FeatureFlagSelection
    }
  }
  ${FEATURE_FLAG_FRAGMENT}
`;

export const UPDATE_FEATURE_FLAG = gql`
  mutation updateFeatureFlag(
    $data: FeatureFlagUpdateInput!
    $where: FeatureFlagWhereUniqueInput!
  ) {
    updateOneFeatureFlag(data: $data, where: $where) {
      ...FeatureFlagSelection
    }
  }
  ${FEATURE_FLAG_FRAGMENT}
`;

export const DELETE_FEATURE_FLAG = gql`
  mutation deleteFeatureFlag($where: FeatureFlagWhereUniqueInput!) {
    deleteOneFeatureFlag(where: $where) {
      ...FeatureFlagSelection
    }
  }
  ${FEATURE_FLAG_FRAGMENT}
`;
