import React from "reactn";
import FormComponent from "../../app/form";
import {
  usePaymentFeeConfigQuery,
  UPDATE_FEES,
  UPDATE_CLINIC_SETTING,
  GET_CLINIC_SETTING,
  PAYMENT_FEE_COLLECTION,
} from "./clinicGraphQL";
import handleCheckbox from "../Utils/handleCheckbox";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useGlobal } from "reactn";
import Container from "../../../styledComponents/container";
import { useMemo } from "react";

export default function FacilityForm({
  match: {
    params: { id },
  },
}) {
  const [updateFees] = useMutation(UPDATE_FEES, {
    errorPolicy: "all",
  });

  const [updateClinicSetting] = useMutation(UPDATE_CLINIC_SETTING, {
    errorPolicy: "all",
  });

  const [clinicName] = useGlobal("clinicName");
  const [clinicSettingId] = useGlobal("clinicSettingId");
  const [, setSnackBars] = useGlobal("snackbars");

  const { data: clinicSettingData } = useQuery(GET_CLINIC_SETTING, {
    variables: {
      where: { id: clinicSettingId },
    },
  });

  const whereClause = { id: clinicSettingId };
  const paymentFeeWhereClause = { id: id };

  const handleChange = (e) => {
    const { checked } = e.target;

    checked
      ? setSnackBars([{ message: "Client Fees Enabled!" }])
      : setSnackBars([{ message: "Client Fees Disabled!" }]);
  };

  const handlePostPaidChange = (e) => {
    const { checked } = e.target;

    checked
      ? setSnackBars([{ message: "Post-paid Fee Collection Enabled!" }])
      : setSnackBars([{ message: "Post-paid Fee Collection Disabled!" }]);
  };

  const handleInterchangePlusChange = (e) => {
    const { checked } = e.target;

    checked
      ? setSnackBars([{ message: "Interchange Plus Pricing Model Enabled!" }])
      : setSnackBars([{ message: "Interchange Plus Pricing Model Disabled!" }]);
  };

  const history = useHistory();

  const usePaymentFeeQuery = () => {
    return usePaymentFeeConfigQuery({
      variables: {
        where: {
          id: {
            equals: id,
          },
        },
      },
    });
  };

  const { data: clinicPaymentFeeData } = useQuery(PAYMENT_FEE_COLLECTION, {
    variables: {
      where: { id },
    },
  });

  const isPostPaid = useMemo(() => {
    return (
      clinicPaymentFeeData?.getPaymentFeeCollection.feeCollection === "PostPaid"
    );
  }, [clinicPaymentFeeData]);

  const isInterchangePlus = useMemo(() => {
    return (
      clinicPaymentFeeData?.getPaymentFeeCollection.pricingModel ===
      "InterchangePlus"
    );
  }, [clinicPaymentFeeData]);

  const handlePostPaidCheckbox = async (whereClause, { target }) => {
    const isElementNowChecked = target.attributes.type.ownerElement.checked;

    await updateFees({
      variables: {
        where: whereClause,
        data: {
          feeCollection: isElementNowChecked ? "PostPaid" : "DuringPayment",
        },
      },
    }).catch((err) => console.log(err));
  };

  const handleInterchangePlusCheckbox = async (whereClause, { target }) => {
    const isElementNowChecked = target.attributes.type.ownerElement.checked;

    await updateFees({
      variables: {
        where: whereClause,
        data: {
          pricingModel: isElementNowChecked ? "InterchangePlus" : "BlendedRate",
        },
      },
    }).catch((err) => console.log(err));
  };

  const checkPFCValues = (pfc) => {
    // Ensure that the values are within the correct boundaries before updating them in the database.
    for (let fee in pfc) {
      console.log(`Checking ${fee}`);
      let upperBound, lowerBound;

      switch (fee) {
        case "ocsfp":
        case "tcsfp":
          upperBound = 0.03;
          lowerBound = 0;
          break;
        case "opfp":
        case "tpfp":
          upperBound = 0.04;
          lowerBound = 0;
          break;
        case "opff":
        case "tpff":
          upperBound = 30;
          lowerBound = 0;
          break;
        default:
          break;
      }

      if (pfc[fee] > upperBound || pfc[fee] < lowerBound) {
        console.log(
          `WARNING: ${fee} in pfc is ${pfc[fee]}, which isn't between ${lowerBound} and ${upperBound}`
        );
        return false;
      }
    }
    return true;
  };

  const isPercent = (value) => value >= 1;

  const formatDecimal = (value) => Math.round(value * 10000) / 10000; // Ensures no trailing .000000009 where there shouldn't be

  async function handleUpdate(state) {
    let {
      onlineProcessingFeePercent: opfp,
      onlineClientServiceFeePercent: ocsfp,
      terminalProcessingFeePercent: tpfp,
      terminalClientServiceFeePercent: tcsfp,
      onlineProcessingFeeFlat: opff,
      terminalProcessingFeeFlat: tpff,
    } = state;

    if (isPercent(opfp)) {
      opfp /= 100;
    }

    if (isPercent(ocsfp)) {
      ocsfp /= 100;
    }

    if (isPercent(tpfp)) {
      tpfp /= 100;
    }

    if (isPercent(tcsfp)) {
      tcsfp /= 100;
    }

    opfp = formatDecimal(opfp);
    ocsfp = formatDecimal(ocsfp);
    tpfp = formatDecimal(tpfp);
    tcsfp = formatDecimal(tcsfp);

    if (
      checkPFCValues({
        opfp,
        ocsfp,
        tpfp,
        tcsfp,
        opff,
        tpff,
      })
    ) {
      console.log("The values are within the bounds!");
    } else {
      console.log(
        "WARNING: VALUES ARE NOT WITHIN BOUNDS. Terminating process."
      );
      console.log({ opfp, ocsfp, tpfp, tcsfp });
      return;
    }

    await updateFees({
      variables: {
        data: {
          onlineProcessingFeePercent: opfp,
          onlineClientServiceFeePercent: ocsfp,
          terminalProcessingFeePercent: tpfp,
          terminalClientServiceFeePercent: tcsfp,
          onlineProcessingFeeFlat: opff,
          terminalProcessingFeeFlat: tpff,
        },
        where: {
          id: id,
        },
      },
    }).catch((err) => console.log(err));
  }

  return (
    <div>
      <h2
        style={{
          color: "darkred",
          textAlign: "center",
        }}
      >
        DO NOT EDIT THESE FEES WITHOUT A ZENDESK ONBOARDING OR TERMINAL TICKET!
        <br />
        <br />
        Fee values should match contract approval values for Clinic Fees.
      </h2>
      <br />
      <br />
      <FormComponent
        onCreate={() => {}}
        onUpdate={handleUpdate}
        useQuery={usePaymentFeeQuery}
        onCancel={() => history.push(`/`)}
        queryName="getPaymentFeeConfiguration"
        id={id}
        title={`Payment Fee Configuration for ${clinicName}`}
        fields={[
          [
            {
              type: "percent",
              min: 0,
              max: 0.04,
              property: "onlineProcessingFeePercent",
              display: "Card Not Present Rate % (Clinic)",
              description:
                "Fee charged by card processor to process card payments.  Fees must match contract approval.",
            },
            {
              type: "percent",
              min: 0,
              max: 0.04,
              property: "terminalProcessingFeePercent",
              display: "Card Present Rate % (Clinic)",
              description:
                "Fee charged by card processor to process card payments.  Fees must match contract approval.",
            },
          ],
          [
            {
              type: "percent",
              property: "onlineClientServiceFeePercent",
              display: "Card Not Present Rate % (Pet Parent)",
              min: 0,
              max: 0.03,
              description:
                "Fee passed on to card user to cover the clinic's cost of processing card transaction (usually 3%)",
            },
            {
              type: "percent",
              min: 0,
              max: 0.03,
              property: "terminalClientServiceFeePercent",
              display: "Card Present Rate % (Pet Parent)",
              description:
                "Fee passed on to card user to cover the clinic's cost of processing card transaction (usually 3%)",
            },
          ],
          [
            {
              type: "number",
              min: 0,
              max: 30,
              property: "onlineProcessingFeeFlat",
              display: "Virtual Payment per Transaction Fee (¢)",
              description:
                "Per transaction flat fee for online payments.  This should almost always be $0.30 unless Pet Owner fee is not zero.",
            },
            {
              type: "number",
              min: 0,
              max: 30,
              property: "terminalProcessingFeeFlat",
              display: "Terminal Payment per Transaction Fee (¢)",
              description:
                "Per transaction flat fee for terminal payments.  This will almost always be 0.",
            },
          ],
        ]}
      />
      <Container style={{ marginTop: "30px" }}>
        <hr style={{ marginBottom: "30px" }} />
        <label for="checkbox">
          <b>Enable Client Fees:</b>
        </label>
        <input
          id="checkbox"
          style={{ marginLeft: "20px", width: "20px", height: "20px" }}
          onChange={(e) => handleChange(e)}
          onClick={(event) =>
            handleCheckbox(
              updateClinicSetting,
              whereClause,
              "hasCustomizableFees",
              event
            )
          }
          type="checkbox"
          defaultChecked={clinicSettingData?.getSetting.hasCustomizableFees}
        ></input>
      </Container>
      <Container style={{ marginTop: "30px" }}>
        <label for="post-paid-checkbox">
          <b>Enable Post-paid Fee Collection:</b>
        </label>
        <input
          id="post-paid-checkbox"
          style={{ marginLeft: "20px", width: "20px", height: "20px" }}
          onChange={(e) => handlePostPaidChange(e)}
          onClick={(event) =>
            handlePostPaidCheckbox(paymentFeeWhereClause, event)
          }
          type="checkbox"
          checked={isPostPaid}
        ></input>
      </Container>
      <Container style={{ marginTop: "30px" }}>
        <label for="interchange-checkbox">
          <b>Enable Interchange Plus</b>{" "}
          <b style={{ color: "red" }}>(requires Post-paid to be enabled)</b>
        </label>
        <input
          id="interchange-checkbox"
          style={{ marginLeft: "20px", width: "20px", height: "20px" }}
          onChange={(e) => handleInterchangePlusChange(e)}
          onClick={(event) =>
            handleInterchangePlusCheckbox(paymentFeeWhereClause, event)
          }
          type="checkbox"
          checked={isInterchangePlus}
        ></input>
      </Container>
    </div>
  );
}
