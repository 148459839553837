import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { FaEdit, FaTimes, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router";
import PrismaServerFilterTable from "../../../app/prismaServerFilterTable";

import React, { useState, useGlobal } from "reactn";
import { FIND_CLINIC_FEATURE_FLAGS } from "../queries";
import { UPDATE_FEATURE_FLAG } from "../mutations";

const ShowClinicFeatureFlags = ({ match }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);

  const [clinicName] = useGlobal("clinicName");
  const queryVariables = {
    where: {
      clinics: {
        some: {
          id: { equals: match.params.id },
        },
      },
    },
  };

  const useFeatureFlagQuery = () => {
    const [loadFeatureFlags, { called, loading, data }] = useLazyQuery(
      FIND_CLINIC_FEATURE_FLAGS,
      {
        errorPolicy: "all",
      }
    );
    return [loadFeatureFlags, { called, loading, data }];
  };
  const [updateFeatureFlag] = useMutation(UPDATE_FEATURE_FLAG, {
    errorPolicy: "all",
  });

  const onRemove = async (id, featureFlagId) => {
    await updateFeatureFlag({
      variables: {
        where: {
          id: featureFlagId,
        },
        data: {
          clinics: {
            disconnect: { id },
          },
        },
      },
    });
  };

  return (
    <div>
      <h1>Feature Flags for {clinicName}</h1>
      <PrismaServerFilterTable
        useQuery={useFeatureFlagQuery}
        queryName="findManyFeatureFlag"
        queryVariables={queryVariables}
        title={`Feature Flags`}
        renderedRows={100}
        pageChanged={(p) => {
          // eslint-disable-next-line no-const-assign
          page = p;
          setPage(p);
        }}
        page={page}
        filterConfigs={[{ property: "key", display: "Key" }]}
        columns={[
          {
            Header: "",
            accessor: ({ data }) => {
              return (
                <span>
                  <FaSearch
                    onClick={() =>
                      history.push(`/feature-flags/clinics/${data.id}`)
                    }
                    size={20}
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    color="#2ABA66"
                    title="View"
                  />
                  <FaEdit
                    onClick={() =>
                      history.push(`/feature-flags/manage/${data.id}`)
                    }
                    size={20}
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    color="#2ABA66"
                    title="Edit Feature Flag"
                  />
                  <FaTimes
                    onClick={() => onRemove(match.params.id, data.id)}
                    color="#E9573F"
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    size={20}
                    title="Remove"
                  />
                </span>
              );
            },
          },
          {
            Header: "Feature Flag Key",
            accessor: ({ data }) => {
              return <p>{data.key}</p>;
            },
          },
          {
            Header: "Feature Flag Description",
            accessor: ({ data }) => {
              return <p>{data.description}</p>;
            },
          },
        ]}
      />
    </div>
  );
};

export default ShowClinicFeatureFlags;
