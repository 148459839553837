import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";

import {
  Paginator,
  TableWrapper,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  Td,
  TdContent,
  Th,
  ThContent,
} from "./styledComps";
import { titleCase } from "../filterFuncs";

const isAdmin = (data) =>
  data.type !== "OWNER" && data.vetInfo?.roles[0]?.role === "ADMIN";

export default function SmartTable({
  data,
  renderedRows,
  columns,
  loading,
  sortable,
  csvExport,
  total,
  page,
  pageChanged,
  style,
}) {
  const [, setTbodyWidth] = useState(0);
  const tbody = useRef(null);
  useEffect(() => {
    if (tbody.current) {
      setTbodyWidth(tbody.current.offsetWidth);
    }
    // eslint-disable-next-line
  }, [tbody.current]);
  let [state, setInternalState] = useState({
    direction: null,
    loading: false,
    filters: [],
    internalColumns: [],
  });
  var { internalColumns } = state;
  data = data || [];
  renderedRows = renderedRows || 10;

  useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, [data]);

  function setState(propObj) {
    setInternalState({ ...state, ...propObj });
  }
  function updateData() {
    var internalColumns = _.map(columns, (col) => {
      if (typeof col === typeof "") {
        col = { accessor: col };
      }
      col.Header = col.Header || titleCase(col.accessor);
      return col;
    });
    state.internalColumns = internalColumns;
    setState({ data, internalColumns });
  }
  function handleSort(clickedColumn) {
    const { column, direction } = state;

    if (column !== clickedColumn) {
      setState({
        column: clickedColumn,
        direction: "ascending",
      });

      return;
    }

    setState({
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  }

  // abstraction to get the data from the column accessor
  function getData({ data, column }) {
    if (typeof column.accessor === typeof "") {
      return data[column.accessor];
    }
    return column.accessor({ data, column });
  }

  function headerClick(col) {
    return (eve) => {
      if (eve.target.tagName !== "INPUT" && !col.blockSort && sortable)
        handleSort(col);
    };
  }
  function pageDown() {
    if (page === 1) return;
    pageChanged(page - 1);
  }
  function pageUp() {
    if (page >= (total || data.length) / renderedRows) return;
    pageChanged(page + 1);
  }

  const renderedData = data;
  if (loading) return <div>Loading...</div>;
  if (!renderedData.length) return <h5>No Rows Returned</h5>;

  const dataRowStyle = (r) => {
    if (r.breed || r.firstName?.length || r.lastName?.length) {
      // If we're looking at pet parents, users, clinic employees, or pets...
      return {
        fontWeight: isAdmin(r) ? "bolder" : "normal",
        color: isAdmin(r) ? "rgb(40, 140, 165)" : "inherit",
        fontStyle: !r.isActive || r.isDeleted ? "italic" : "normal",
        opacity: !r.isActive || r.isDeleted ? ".5" : "1",
      };
    } else return {};
  };

  return (
    <TableWrapper>
      <Table>
        <TableBody ref={tbody}>
          <TableRow style={{ borderTop: "1px solid #ccd1d9" }}>
            {internalColumns.map((col, i) => {
              return (
                <Th key={`col-${i}`} onClick={headerClick(col)}>
                  <ThContent>{col.Header || ""}</ThContent>
                </Th>
              );
            })}
          </TableRow>
          {renderedData.map((r, i) => {
            return (
              <TableRow style={dataRowStyle(r)} key={`row-${i}`}>
                {_.map(internalColumns, (c, i) => (
                  <Td key={i + "cell"} style={{ width: c.width }}>
                    <TdContent>
                      {c.Cell
                        ? c.Cell({
                            value: getData({ column: c, data: r }),
                            column: c,
                            row: r,
                          })
                        : getData({ column: c, data: r })}
                    </TdContent>
                  </Td>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TableFooter>
        <Paginator
          renderedRows={renderedRows}
          interalData={data}
          page={page}
          total={total}
          handlePageLeft={pageDown}
          handlePageRight={pageUp}
          columns={columns}
          csvExport={csvExport}
        />
      </TableFooter>
    </TableWrapper>
  );
}
