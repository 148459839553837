import React from 'react';
import PropTypes from 'prop-types';
import SnackbarContainer from './SnackbarContainer';
import Snackbar from './Snackbar';

const SnackbarBucket = ({ snackbars, removeSnackbar }) => {
  return (
    <SnackbarContainer>
      {snackbars.map(snackbar => {
        return (
          <Snackbar
            key={snackbar.id}
            role="alert"
            id={snackbar.id}
            type={snackbar.type}
            timeoutMs={snackbar.timeoutMs}
            removeSnackbar={removeSnackbar}
          >
            {snackbar.message}
          </Snackbar>
        );
      })}
    </SnackbarContainer>
  );
};

SnackbarBucket.propTypes = {
  snackbars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
    })
  ),
  removeSnackbar: PropTypes.func.isRequired,
};

export default SnackbarBucket;
