import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const WORKFLOW_EVENT_ACTION_HISTORY = gql`
  query getWorkflowEventActionHistory($where: GetEntityHistoryInput!) {
    getWorkflowEventActionHistory(where: $where) {
      data
    }
  }
`;

function useWorkflowEventActionHistory() {
  const [getWorkflowEventAction, { called, loading, data }] = useLazyQuery(
    WORKFLOW_EVENT_ACTION_HISTORY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return [getWorkflowEventAction, { called, loading, data }];
}
export { useWorkflowEventActionHistory, WORKFLOW_EVENT_ACTION_HISTORY };
