import firebase from "firebase/app";
import "firebase/auth";
import config from "./config";
import { env } from "./env";
firebase.initializeApp({
  apiKey: env.REACT_APP_FIREBASE_KEY || config.firebaseApiKey, // Auth / General Use
  authDomain: env.REACT_APP_FIREBASE_DOMAIN || config.firebaseBaseUrl, // Auth with popup/redirect
  databaseURL:
    env.REACT_APP_FIREBASE_DATABASE || `https://${config.firebaseBaseUrl}`,
});
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
export default firebase;
