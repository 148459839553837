import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import orderBy from "lodash/orderBy";
import { Button, Input, Select } from "../../../styledComponents/form";
import { useState, useMemo } from "react";
import { useAppointmentHistory } from "./appointmentHistory";
import { useClinicPetHistory } from "./clinicPetHistory";
import { useClinicPetParentHistory } from "./clinicPetParentHistory";
import { useWorkflowEventSettingHistory } from "./workflowEventSettingHistory";
import { useWorkflowEventActionHistory } from "./workflowEventActionHistory";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

const entitiesWithHistory = [
  "Appointment",
  "Automation",
  "Automation Action",
  "Pet",
  "Pet Parent",
];

const EntityHistory = () => {
  const [getAppointmentHistory, { data: appointmentHistoryData }] =
    useAppointmentHistory();
  const [getClinicPetHistory, { data: clinicPetHistoryData }] =
    useClinicPetHistory();
  const [getClinicPetParentHistory, { data: clinicPetParentHistoryData }] =
    useClinicPetParentHistory();
  const [
    getWorkflowEventSettingHistory,
    { data: workflowEventSettingHistoryData },
  ] = useWorkflowEventSettingHistory();
  const [
    getWorkflowEventActionHistory,
    { data: workflowEventActionHistoryData },
  ] = useWorkflowEventActionHistory();

  const [entity, setEntity] = useState("Appointment");
  const entityIdRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    let data = [];
    switch (entity) {
      case "Appointment":
        data = JSON.parse(
          appointmentHistoryData?.getAppointmentHistory?.data || "[]"
        );
        setData(data);
        return;
      case "Pet":
        data = JSON.parse(
          clinicPetHistoryData?.getClinicPetHistory?.data || "[]"
        );
        setData(data);
        return;
      case "Pet Parent":
        data = JSON.parse(
          clinicPetParentHistoryData?.getClinicPetParentHistory?.data || "[]"
        );
        setData(data);
        return;
      case "Automation":
        data = JSON.parse(
          workflowEventSettingHistoryData?.getWorkflowEventSettingHistory
            ?.data || "[]"
        );
        setData(data);
        return;
      case "Automation Action":
        data = JSON.parse(
          workflowEventActionHistoryData?.getWorkflowEventActionHistory?.data ||
            "[]"
        );
        setData(data);
        return;
      default:
        break;
    }
  }, [
    appointmentHistoryData,
    clinicPetParentHistoryData,
    clinicPetHistoryData,
    entity,
    workflowEventSettingHistoryData,
    workflowEventActionHistoryData,
  ]);

  const handleSearch = useCallback(() => {
    switch (entity) {
      case "Appointment":
        getAppointmentHistory({
          variables: {
            where: {
              id: entityIdRef.current.value,
            },
          },
        });
        break;
      case "Pet Parent":
        getClinicPetParentHistory({
          variables: {
            where: {
              id: entityIdRef.current.value,
            },
          },
        });
        break;
      case "Pet":
        getClinicPetHistory({
          variables: {
            where: {
              id: entityIdRef.current.value,
            },
          },
        });
        break;
      case "Automation":
        getWorkflowEventSettingHistory({
          variables: {
            where: {
              id: entityIdRef.current.value,
            },
          },
        });
        break;
      case "Automation Action":
        getWorkflowEventActionHistory({
          variables: {
            where: {
              id: entityIdRef.current.value,
            },
          },
        });
        break;

      default:
        break;
    }
  }, [
    entity,
    getAppointmentHistory,
    getClinicPetParentHistory,
    getClinicPetHistory,
    getWorkflowEventSettingHistory,
    getWorkflowEventActionHistory,
  ]);

  // Define the columns
  const columns = useMemo(() => {
    if (!data || data.length === 0) return [];
    return Object.keys(data[0]).map((key) => {
      return {
        accessorKey: key,
        header: key,
        accessorFn: (row) =>
          typeof row[key] === "object"
            ? JSON.stringify(row[key], null, 2)
            : row[key],
      };
    });
  }, [data]);

  const table = useReactTable({
    data: orderBy(data, (item) => item.id),
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <EntityHistoryWrapper>
      <Controls>
        <Select
          onChange={(e) => setEntity(e.currentTarget.value)}
          value={entity}
        >
          <option value="">Select Entity</option>
          {entitiesWithHistory.map((entity) => (
            <option key={entity} value={entity}>
              {entity}
            </option>
          ))}
        </Select>
        <Input type="text" placeholder="Id" ref={entityIdRef} />
        <Button onClick={handleSearch}>Search</Button>
      </Controls>
      {data && data.length > 0 && (
        <StyledTable>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </StyledTable>
      )}
    </EntityHistoryWrapper>
  );
};

export default EntityHistory;

const EntityHistoryWrapper = styled.div`
  margin: auto;
`;
const Controls = styled.div`
  display: flex;
  gap: 8px;
  max-width: 1000px;
  margin: auto;
  padding: 8px;
`;

const StyledTable = styled.div`
  max-width: 95vw;
  margin: auto;
  overflow-y: scroll;
  /* Striped table style */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: pre-wrap; /* Preserve whitespace and allow wrapping */
  }

  tr:nth-child(even) {
    background-color: #f2f2f2; /* Light gray for even rows */
  }
`;
