import gql from "graphql-tag";

let date = new Date();
date.setHours(date.getHours() - 1);

const GET_TERMINALS = gql`
  query getTerminalsForClinic($where: StripeTerminalLocationWhereInput) {
    findManyStripeTerminalLocation: findManyStripeTerminalLocation(
      where: $where
    ) {
      stripeId
      terminals(orderBy: {createdAt: asc}) {
        stripeId
        createdAt
        id
        name
        serialNumber
        model
        healthchecks(where: {
            createdAt: {
                gte:"${date.toISOString()}"
            }
        } orderBy: {createdAt: desc}) {
          id
          createdAt
          updatedAt
          status
          ipAddress
        }
      }
    }
  }
`;

const CREATE_TERMINALS = gql`
  mutation createStripeTerminal($displayName: String!, $pairingCode: String!) {
    createOneStripeTerminal: connectStripeTerminal(
      displayName: $displayName
      pairingCode: $pairingCode
    ) {
      id
      name
    }
  }
`;

const CREATE_STRIPE_LOCATION = gql`
  mutation createStripeLocation($displayName: String!) {
    createStripeTerminalLocation: createStripeTerminalLocation(
      displayName: $displayName
    ) {
      id
    }
  }
`;

const UPDATE_TERMINAL = gql`
  mutation updateTerminal(
    $where: StripeTerminalWhereUniqueInput!
    $data: StripeTerminalUpdateInput!
  ) {
    updateStripeTerminal: updateOneStripeTerminal(where: $where, data: $data) {
      id
      name
    }
  }
`;

export {
  GET_TERMINALS,
  CREATE_TERMINALS,
  CREATE_STRIPE_LOCATION,
  UPDATE_TERMINAL,
};
