import React from "reactn";
import styled from "styled-components/macro";
export default function BenefitDetail(props) {
  const { benefit, setActiveBenefit, deleteBenefit } = props;

  const {
    id,
    title,
    tangible,
    description,
    usageUnitOfMeasure,
    planBenefits,
    addonPackageBenefits: addonBenefits,
  } = benefit;
  const services = benefit.organizationCareBenefitToClinicService.map(
    ({ service }) => service
  );
  const measureType = usageUnitOfMeasure === "OCCURENCE" ? "Units" : "Money";

  return (
    <Container key={id}>
      <div style={{ width: 500 }}>
        <b>{title}</b> - {id}
        <br />
        Tangible{" "}
        <input
          type="checkbox"
          disabled="disabled"
          checked={tangible ? "checked" : ""}
        />
        Type: {measureType}
        <br />
        Used in {planBenefits.length} plan(s) and {addonBenefits.length}{" "}
        addon(s)
        <br />
        <button
          onClick={() => {
            setActiveBenefit(benefit);
          }}
        >
          Update
        </button>
        <button onClick={() => deleteBenefit(id)}>Delete</button>
        <br />
        <br />
        <br />
        {description} <br />
        <br />
      </div>
      <div style={{ marginLeft: 15 }}>
        <div>
          <b>Connected Codes</b>
        </div>
        <div>
          {services.length > 0 ? (
            services.map((service) => {
              return (
                <div>
                  {service.name} - {service.code}
                </div>
              );
            })
          ) : (
            <div>None</div>
          )}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  margin: 5px;
  display: flex;
`;
