import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";

const embedDashboard = async (embedUrl, setLoading) => {
  const embeddingContext = await createEmbeddingContext({
    onChange: (changeEvent, metadata) => {
      console.log("Context received a change", changeEvent, metadata);
    },
  });

  const frameOptions = {
    url: embedUrl,
    container: window.quickSightContainer,
    resizeHeightOnSizeChangedEvent: true,
  };

  const contentOptions = {
    locale: "en-US",
    sheetOptions: {},
    toolbarOptions: {
      export: false,
      undoRedo: false,
      reset: false,
    },
    attributionOptions: {
      overlayContent: false,
    },
    onMessage: async (messageEvent) => {
      switch (messageEvent.eventName) {
        case "CONTENT_LOADED": {
          console.log(
            "All visuals are loaded. The title of the document:",
            messageEvent.message.title
          );
          break;
        }
        case "ERROR_OCCURRED": {
          console.log(
            "Error occurred while rendering the experience. Error code:",
            messageEvent.message.errorCode
          );
          break;
        }
        case "PARAMETERS_CHANGED": {
          setLoading(false);
          console.log(
            "Parameters changed. Changed parameters:",
            messageEvent.message.changedParameters
          );
          break;
        }
        case "SELECTED_SHEET_CHANGED": {
          console.log(
            "Selected sheet changed. Selected sheet:",
            messageEvent.message.selectedSheet
          );
          break;
        }
        case "SIZE_CHANGED": {
          console.log("Size changed. New dimensions:", messageEvent.message);
          break;
        }
        case "MODAL_OPENED": {
          window.scrollTo({
            top: 0, // iframe top position
          });
          break;
        }
        default:
          console.log("Unknown message received", messageEvent);
      }
    },
  };

  const embeddedDashboardExperience = await embeddingContext.embedDashboard(
    frameOptions,
    contentOptions
  );
  return embeddedDashboardExperience;
};

export default embedDashboard;
