import React, { useEffect, useState, useGlobal, useCallback } from "reactn";
import { Row, C1, CA } from "../../styledComponents/grid";
import { Input, Button, Select } from "../../styledComponents/form";
import SemTable from "./table";
import { useHistory, useLocation } from "react-router-dom";
import {
  handleFilterConfigs,
  titleCase,
  transformStringFiltersPrisma2,
} from "./filterFuncs";
import Loading from "./loading";
import DatePicker from "react-datepicker";
import styled from "styled-components/macro";
import { exportToCSV, mapExportData } from "../screens/Utils/exportCsv";
import "react-datepicker/dist/react-datepicker.css";
const DateRangeWrapper = styled.div`
  width: 100%;
`;
const DatePickerWrapper = styled.div`
  label {
    font-size: 13px;
    color: #434a54;
  }
  input {
    height: 20px;
    padding: 6px;
    margin-top: 2px;
    width: calc(100% - 14px);
    border-width: 1px;
    border-color: #3c3b3d;
  }
`;
const Header = styled.div`
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const AddButton = styled(Button)`
  float: none;
  // left: 230px;
  position: relative;
`;

const validUrlQuerySearchTerms = {
  users: ["firstName", "lastName", "email", "id", "phone"],
  clinics: ["name", "city", "state", "id"],
};

export default function PrismaServerFilterTable({
  useQuery,
  columns,
  queryName,
  renderedRows,
  filterConfigs,
  title,
  queryVariables,
  urlQueries,
  style,
  disabled,
}) {
  filterConfigs = filterConfigs || [];
  columns = columns || [];
  queryName = queryName || "";
  const [loadData, queryData] = useQuery();
  const [loadExportData, queryExportData] = useQuery();
  const [filters, setFilters] = useGlobal(`${queryName}_filters` || {});
  const history = useHistory();
  const location = useLocation();
  const urlQueryFilter = useCallback(() => {
    if (!urlQueries) {
      return;
    }
    const newFilters = {};
    let filterField;

    switch (queryName) {
      case "findManyUser":
        filterField = "users";
        break;
      case "findManyClinic":
        filterField = "clinics";
        break;
      default:
        filterField = queryName;
        break;
    }

    for (let param of urlQueries.entries()) {
      const [field, searchTerm] = param;
      if (validUrlQuerySearchTerms[filterField]?.includes(field)) {
        newFilters[field] = searchTerm;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQueries, setFilters]);

  const [, path1, path2] = location.pathname.split("/");
  const isClinicFeatureFlag = path1 === "feature-flags" && path2 === "clinic";
  const isUserPage = path1 === "clinics";

  const [modalState, setModalState] = useGlobal("modalState");
  const [render, setRender] = useState(false);
  let [page, setPage] = useState(1);
  let [currentFilters, setCurrentFiltersBase] = useState(filters || {});

  useEffect(() => {
    urlQueryFilter();
  }, [urlQueryFilter, queryName]);


  function setCurrentFilters(key) {
    return (event) => {
      var data = { ...currentFilters };
      data[key] = event.target.value;
      setCurrentFiltersBase(data);
    };
  }

  function setupBaseFilters() {
    filterConfigs.forEach((config) => {
      if (typeof config === typeof "") {
        config = { property: config };
      }
      if (config.type === "date") {
        if (currentFilters[config.property]?.start) {
          currentFilters[config.property].start = new Date(
            currentFilters[config.property].start
          );
        }
        if (currentFilters[config.property]?.end) {
          currentFilters[config.property].end = new Date(
            currentFilters[config.property].end
          );
        }
        currentFilters[config.property] = currentFilters[config.property] || {};
      }
      if (config.property && !config.display) {
        config.display = titleCase(config.property);
      }
    });
    setCurrentFiltersBase(currentFilters);
  }

  useEffect(() => {
    setupBaseFilters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    page = 1;
    setPage(1);
  }, [filters]);

  useEffect(() => {
    const copy = { ...filters };
    const qVarCopy = { ...queryVariables };
    let where = handleFilterConfigs({
      filters: copy,
      filterConfigs,
    });

    if (qVarCopy.where) {
      let pWhere = transformStringFiltersPrisma2(filters);
      where = { ...pWhere, ...qVarCopy.where };
      delete qVarCopy.where;
    }

    loadData({
      variables: {
        ...{
          take: renderedRows || 100,
          skip: (page - 1) * (renderedRows || 100),
          where,
        },
        ...qVarCopy,
      },
    });
    // eslint-disable-next-line
  }, [filters, page]);

  async function exportData() {
    const copy = { ...filters };
    const qVarCopy = { ...queryVariables };
    let where = handleFilterConfigs({
      filters: copy,
      filterConfigs,
    });

    if (qVarCopy.where) {
      let pWhere = transformStringFiltersPrisma2(filters);
      where = { ...pWhere, ...qVarCopy.where };
      delete qVarCopy.where;
    }

    loadExportData({
      variables: {
        ...{
          where,
        },
        ...qVarCopy,
      },
    });
  }
  const totalCheck = (queryData, queryName) => {
    if (queryData.data) {
      return queryData.data[queryName + "Count"];
    } else {
      return 1;
    }
  };

  useEffect(() => {
    if (queryExportData.loading) {
      setModalState({
        ...modalState,
        showModal: true,
        modalHeader: "Loading",
        modalContent: <Loading />,
      });
    } else {
      setModalState({
        ...modalState,
        showModal: false,
        modalHeader: "",
        modalContent: "",
      });
    }
    // eslint-disable-next-line
  }, [queryExportData.loading]);

  useEffect(() => {
    if (queryExportData.data && queryExportData.data[queryName]) {
      exportToCSV(
        queryExportData.data[queryName].map((d) => mapExportData(d, columns)),
        `${queryName}`
      );
    }
    // eslint-disable-next-line
  }, [queryExportData]);

  const generateNewUrlQueryString = () => {
    const url = window.location.href;
    const params = new URLSearchParams(url.search);
    for (let x in currentFilters) {
      const field = x;
      const value = currentFilters[x];
      if (value.length > 0 && value !== "" && value) {
        params.append(field, value);
      } else {
        params.delete(field);
      }
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };

  if (render) {
    return <Loading />;
  }
  return (
    <div
      style={{
        position: "absolute",
        overflowY: "scroll",
        top: `${isClinicFeatureFlag ? "65" : "0"}px`,
        left: "0",
        right: "0",
        bottom: "0",
        padding: "15px",
        ...style,
      }}
    >
      <Row>
        <C1 />
      </Row>
      <Row
        style={{
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          padding: "15px",
        }}
      >
        <CA>
          <div
            style={{
              width: 216,
              marginTop: isUserPage ? "50px" : "0px",
            }}
          >
            <h5>{title || "Data"}</h5>

            <form
              onSubmit={(event) => {
                event.preventDefault();
                setFilters({ ...currentFilters });
                generateNewUrlQueryString();
                // history.push(generateNewUrlQueryString());
              }}
            >
              <div
                style={{
                  maxHeight: 470,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {filterConfigs.map((config, i) => {
                  return (
                    <Row style={{ marginBottom: 16 }} key={`filters-${i}`}>
                      {config.type === "select" ? (
                        <Select
                          value={currentFilters[config.property]}
                          onChange={setCurrentFilters(config.property)}
                        >
                          <option value="">
                            Select {config.display || config.property}
                          </option>
                          {(config.options || []).map((opt) => {
                            return (
                              <option value={opt.value || opt}>
                                {opt.display || opt.value || opt}
                              </option>
                            );
                          })}
                        </Select>
                      ) : config.type === "date" ? (
                        <DateRangeWrapper>
                          <div>
                            <DatePickerWrapper>
                              <div>
                                <label>
                                  {config.display || config.property} Start
                                </label>
                              </div>
                              <DatePicker
                                selected={
                                  typeof currentFilters[config.property]
                                    ?.start === "object"
                                    ? currentFilters[config.property]?.start
                                    : null
                                }
                                onChange={(val) => {
                                  if (!val) {
                                    currentFilters[config.property].start =
                                      null;
                                  } else {
                                    currentFilters[config.property].start = val;
                                  }
                                  setCurrentFiltersBase(currentFilters);
                                  setFilters({ ...currentFilters });
                                }}
                              />
                            </DatePickerWrapper>
                          </div>

                          <div>
                            <DatePickerWrapper>
                              <div>
                                <label>
                                  {config.display || config.property} End
                                </label>
                              </div>
                              <DatePicker
                                placeholder={`${
                                  config.display || config.property
                                } End`}
                                selected={
                                  typeof currentFilters[config.property]
                                    ?.end === "object"
                                    ? currentFilters[config.property]?.end
                                    : null
                                }
                                onChange={(val) => {
                                  if (!val) {
                                    currentFilters[config.property].end = null;
                                  } else {
                                    currentFilters[config.property].end = val;
                                  }
                                  setCurrentFiltersBase(currentFilters);
                                  setFilters({ ...currentFilters });
                                }}
                              />
                            </DatePickerWrapper>
                          </div>
                        </DateRangeWrapper>
                      ) : config.type === "checkbox" ? (
                        <>
                          <label>{config.display || config.property}</label>
                          <Input
                            checked={currentFilters[config.property]}
                            type="checkbox"
                            disabled={disabled}
                            onClick={(event) => {
                              setCurrentFiltersBase({
                                ...currentFilters,
                                [config.property]:
                                  !currentFilters[config.property],
                              });
                              setFilters({
                                ...currentFilters,
                                [config.property]:
                                  !currentFilters[config.property],
                              });
                            }}
                          />
                        </>
                      ) : (
                        <Input
                          defaultValue={currentFilters[config.property]}
                          placeholder={config.display || config.property}
                          onChange={setCurrentFilters(config.property)}
                        />
                      )}
                    </Row>
                  );
                })}
              </div>
              <Row>
                <C1 style={{ paddingLeft: 0 }}>
                  <Button
                    type="button"
                    onClick={() => {
                      currentFilters = {};
                      setCurrentFiltersBase({});
                      setupBaseFilters();
                      setFilters({ ...currentFilters });
                      setRender(true);
                      setTimeout(() => {
                        setRender(false);
                      }, 10);
                    }}
                    style={{ backgroundColor: "#E9573F", float: "left" }}
                    disabled={!Object.keys(currentFilters).length}
                  >
                    Clear
                  </Button>
                </C1>
                <C1 style={{ paddingRight: 0 }}>
                  <Button type="submit" style={{ float: "right" }}>
                    Search
                  </Button>
                </C1>
              </Row>
            </form>
            {window.location.pathname === "/feature-flags" ? (
              <Row>
                <Header>
                  <AddButton
                    onClick={() => history.push("/feature-flags/manage")}
                  >
                    Add Feature Flag
                  </AddButton>
                </Header>
              </Row>
            ) : (
              ""
            )}
          </div>
        </CA>
        <C1
          style={{
            position: "absolute",
            top: "0",
            left: "250px",
            right: "0",
            bottom: "0",
          }}
        >
          {queryData.loading ? (
            <Loading />
          ) : (
            <SemTable
              data={queryData.data ? queryData.data[queryName] : []}
              renderedRows={renderedRows || 100}
              csvExport={exportData}
              pageChanged={(p) => {
                page = p;
                setPage(p);
              }}
              page={page}
              total={totalCheck(queryData, queryName)}
              columns={columns}
            />
          )}
        </C1>
      </Row>
    </div>
  );
}
