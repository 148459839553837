import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

export const FEATURE_FLAG_FRAGMENT = gql`
  fragment FeatureFlagSelection on FeatureFlag {
    id
    createdAt
    updatedAt
    key
    description
    type
    state
    clinics {
      id
      name
    }
  }
`;

export const GET_FEATURE_FLAGS = gql`
  query getFeatureFlags(
    $where: FeatureFlagWhereInput
    $orderBy: [FeatureFlagOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $take: Int
  ) {
    findManyFeatureFlag: findManyFeatureFlag(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      ...FeatureFlagSelection
    }
    findManyFeatureFlagCount: findManyFeatureFlagCount(where: $where)
  }
  ${FEATURE_FLAG_FRAGMENT}
`;

export const GET_FEATURE_FLAG = gql`
  query getFeatureFlag($where: FeatureFlagWhereUniqueInput!) {
    featureFlag: findUniqueFeatureFlag(where: $where) {
      ...FeatureFlagSelection
    }
  }
  ${FEATURE_FLAG_FRAGMENT}
`;

export const FIND_CLINIC = gql`
  query clinics($where: ClinicWhereInput) {
    findManyClinic(where: $where, take: 50) {
      id
      name
    }
  }
`;

export const FIND_CLINIC_FEATURE_FLAGS = gql`
  query findManyFeatureFlag($where: FeatureFlagWhereInput) {
    findManyFeatureFlag(where: $where) {
      id
      key
      description
    }
    findManyFeatureFlagCount(where: $where)
  }
`;

export const useFeatureFlagsQuery = () => {
  const [getFeatureFlags, { called, loading, data, variables, refetch }] =
    useLazyQuery(GET_FEATURE_FLAGS, {
      fetchPolicy: "network-only",
    });

  return [getFeatureFlags, { called, loading, data, variables, refetch }];
};
