import React from "reactn";
import Loader from "react-loader-spinner";
import { CenteredContent } from "../../styledComponents/grid";

export default function Loading() {
  return (
    <CenteredContent>
      <Loader
        type="ThreeDots"
        color="#444559"
        height={100}
        width={100}
        // style={{ marginTop: "32px" }}
      />
    </CenteredContent>
  );
}
