export const careThemeColors = [
  "#E85D5D",
  "#E47792",
  "#F08F02",
  "#DDB607",
  "#5CC098",
  "#ADDFCB",
  "#39ADC3",
  "#5196FC",
  "#AACCFE",
  "#6B7EFC",
  "#272935",
  "#575D7C",
  "#C1C9CA"  
];
