import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const PETS = gql`
  query pets(
    $where: ClinicPetWhereInput
    $orderBy: [ClinicPetOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $take: Int
  ) {
    findManyClinicPet: findManyClinicPet(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      name
      clinic {
        name
        firebaseId
      }
      pimsId
      id
      isActive
      isDeleted
      dateOfBirth
      dateOfDeath
      breed
      gender
      petParents {
        firstName
        lastName
      }
    }
    findManyClinicPetCount: findManyClinicPetCount(where: $where)
  }
`;

const UPDATE_CLINIC_PET = gql`
  mutation updateClinicPet(
    $where: ClinicPetWhereUniqueInput!
    $data: ClinicPetUpdateInput!
  ) {
    clinicPet: updateOneClinicPet(where: $where, data: $data) {
      id
      isActive
      isDeleted
      name
    }
  }
`;

function usePetsQuery() {
  const [loadPets, { called, loading, data }] = useLazyQuery(PETS, {
    fetchPolicy: "cache-and-network",
  });

  return [loadPets, { called, loading, data }];
}
export { usePetsQuery, UPDATE_CLINIC_PET };
