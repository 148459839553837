/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { sortBy } from "lodash";
import { useLazyQuery } from "@apollo/react-hooks";
import { EMBED_URL } from "./reportingGraphQl";
import embedDashboard from "./embedDashboard";
import { List, ListItem } from "../../../styledComponents/list";

const QuickSightReportingList = ({
  quickSightDashboardData,
  setDashboardName,
  setLoading,
}) => {
  const [experience, setExperience] = useState("");
  const [currentDashboardId, setCurrentDashboardId] = useState();
  const [selectedDashboardId, setSelectedDashboardId] = useState(null);

  const isIframeInDom = () => {
    return document.getElementById("experience-container") !== null;
  };

  const [getDashboardURL, { data }] = useLazyQuery(EMBED_URL, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (currentDashboardId) {
      getDashboardURL({
        variables: { where: { dashboardId: currentDashboardId } },
      });
    }
  }, [currentDashboardId, getDashboardURL]);

  useEffect(() => {
    const runEmbedDashboard = async () => {
      if (data?.getQuickSightEmbedURL?.EmbedUrl) {
        const newExperience = await embedDashboard(
          data?.getQuickSightEmbedURL?.EmbedUrl,
          setLoading
        );
        setExperience(newExperience);
      }
    };
    runEmbedDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getQuickSightEmbedURL?.EmbedUrl]);

  const handleDashboardItemClick = (dashboardId) => {
    setLoading(true);
    setDashboardName(
      quickSightDashboardData?.getQuickSightAdminDashboards.find(
        (d) => d.DashboardId === dashboardId
      )?.Name || ""
    );
    if (!isIframeInDom()) {
      getDashboardURL({
        variables: {
          where: { dashboardId: dashboardId },
        },
      });
    } else if (!data?.getQuickSightEmbedURL?.EmbedUrl) {
      setCurrentDashboardId(dashboardId);
    } else {
      try {
        experience?.navigateToDashboard(dashboardId);
      } catch (e) {
        console.log("error", e);
      }
    }
    setSelectedDashboardId(dashboardId);
  };

  return (
    <div>
      {sortBy(
        quickSightDashboardData?.getQuickSightAdminDashboards,
        (dashboard) => dashboard?.Name
      )?.map((dashboard) => {
        return (
          <List key={dashboard?.DashboardId}>
            <ListItem
              id={dashboard?.DashboardId || ""}
              onClick={() => handleDashboardItemClick(dashboard?.DashboardId)}
              style={{
                color:
                  selectedDashboardId === dashboard?.DashboardId
                    ? "#45B1C6"
                    : "black",
                pointerEvents:
                  selectedDashboardId === dashboard?.DashboardId
                    ? "none"
                    : "auto",
              }}
            >
              {dashboard?.Name}
            </ListItem>
          </List>
        );
      })}
    </div>
  );
};

export default QuickSightReportingList;
