import React from "react";
import styled from "styled-components/macro";

const Grid = styled.div``;
const Column1 = styled.div`
  flex: 1;
`;
const Row = styled.div`
  display: flex;
  flex: 0 0;
`;
const C1 = styled.div`
  flex: 1;
  padding: 8px 16px;
`;
const C2 = styled.div`
  flex: 2;
  padding: 8px 16px;
`;
const CA = styled.div``;
const Row1 = styled.div`
  display: flex;
  flex: 1 1;
`;
function CenteredContent({ children }) {
  return (
    <Row>
      <C1 />
      <CA>{children}</CA>
      <C1 />
    </Row>
  );
}
function VerticleCenter({ children }) {
  return (
    <div>
      <Row1 />
      <Row>{children}</Row>
      <Row1 />
    </div>
  );
}

export {
  Grid,
  Row,
  C1,
  C2,
  CA,
  Row1,
  Column1,
  CenteredContent,
  VerticleCenter,
};
