import React, { useGlobal } from "reactn";
import { FaCode, FaDollarSign } from "react-icons/fa";
import { Row, CA } from "../../../styledComponents/grid";
import { useHistory } from "react-router-dom";
import {
  useConsultationsQuery,
  useRefundConsultationMutation,
} from "./consultationsGraphQL";
import moment from "moment";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import Form from "../../app/form";
import Loading from "../../app/loading";
export default function Consultations() {
  const [, setJson] = useGlobal("json");
  const history = useHistory();
  const [modalState, setModalState] = useGlobal("modalState");
  const [refundConsultation] = useRefundConsultationMutation();
  const [, setSnackBars] = useGlobal("snackbars");
  async function refundConfirmed({ consultId, amount }) {
    setModalState({
      ...modalState,
      modalHeader: "Processing",
      modalContent: <Loading />,
      showModal: true,
    });
    await refundConsultation({
      variables: { data: { id: consultId, amount: +amount } },
    });
    setSnackBars([{ message: "Success" }]);
  }
  function refundConsultationClicked({ id, clientCost }) {
    return () => {
      setModalState({
        ...modalState,
        modalHeader: "Refund Consultation",
        modalContent: (
          <Form
            fields={[[{ property: "amount", required: true }]]}
            defaultState={{ consultId: id, amount: clientCost }}
            title="Refund Consultation"
            onCreate={refundConfirmed}
            onCancel={() =>
              setModalState({
                ...modalState,
                showModal: false,
              })
            }
          />
        ),
        showModal: true,
        hideButtons: true,
      });
    };
  }
  return (
    <PrismaServerFilterTable
      title="Consultations"
      queryName="findManyConsultation"
      useQuery={useConsultationsQuery}
      renderedRows = {100}
      queryVariables={{ orderBy: {createdAtCopy: "desc"}, take: 500 }}
      filterConfigs={[
        { property: "createdAtCopy", display: "Created", type: "date" },
        { property: "pet.name", display: "Pet" },
        {
          property: "petParent.user.firstName",
          display: "Pet Owner First Name",
        },
        { property: "petParent.user.lastName", display: "Pet Owner Last Name" },
        { property: "clinic.name", display: "Clinic" },
        { property: "scheduledAt", display: "Scheduled", type: "date" },
        { property: "resolvedAt", display: "Resolved", type: "date" },
        {
          property: "consultationType",
          display: "Type",
          type: "select",
          options: [
            { value: "CONSULT", display: "Consult" },
            { value: "FOLLOWUP", display: "Follow UP" },
          ],
        },
        {
          property: "consultationState",
          display: "State",
          type: "select",
          options: [
            { value: "NEW", display: "New" },
            { value: "SCHEDULED", display: "Scheduled" },
            { value: "RESOLVED", display: "Resolved" },
            { value: "PAID", display: "Paid" },
          ],
        },
      ]}
      columns={[
        {
          Header: "",
          hidefilter: true,
          blockSort: true,
          accessor: ({ data }) => {
            return (
              <Row>
                <CA>
                  <FaCode
                    color="#2ABA66"
                    size={20}
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    title="View Json"
                    onClick={() => {
                      setJson({ type: "consults", data });
                      history.push("/json/view");
                    }}
                  />
                </CA>
                <CA>
                  <FaDollarSign
                    color="#E9573F"
                    size={20}
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    title="Refund Consultation"
                    onClick={refundConsultationClicked(data)}
                  />
                </CA>
              </Row>
            );
          },
        },
        {
          Header: "Created At",
          accessor: "createdAt",
          Cell: ({ value }) => {
            return moment(value).format("MM/DD/YYYY HH:mm");
          },
        },
        { Header: "Type", accessor: "consultationType" },
        { Header: "State", accessor: "consultationState" },
        {
          Header: "Clinic",
          accessor: ({ data: { clinic } }) => clinic?.name,
        },
        {
          Header: "Pet",
          accessor: ({ data: { pet } }) => (pet ? pet.name : ""),
        },
        {
          Header: "Owner",
          accessor: ({ data: { petParent } }) =>
            petParent
              ? `${petParent?.user?.firstName} ${petParent?.user?.lastName}`
              : "",
        },
        {
          Header: "Scheduled At",
          accessor: "scheduledAt",
          Cell: ({ value }) => {
            return value ? moment(value).format("MM/DD/YYYY HH:mm") : "";
          },
        },
        {
          Header: "Resolved At",
          accessor: "resolvedAt",
          Cell: ({ value }) => {
            return value ? moment(value).format("MM/DD/YYYY HH:mm") : "";
          },
        },
        { Header: "Summary", accessor: "summary" },
      ]}
    />
  );
}
