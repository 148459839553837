import React, { useGlobal, useState } from "reactn";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";
import config from "../../api/config";
import {
  FaBars,
  FaUser,
  FaClinicMedical,
  FaFlag,
  FaBriefcaseMedical,
  FaLockOpen,
  FaKeyboard,
  FaChartBar,
} from "react-icons/fa";
import { Row1, CA, C1 } from "../../styledComponents/grid";
import { Link } from "react-router-dom";
import logo from "../../images/otto.png";
import { useResizeDetector } from "react-resize-detector";

const HeaderWrapper = styled.div`
  width: calc(100% - 32px);
  background-color: #f6f7fa;
  color: #444559;
  padding: 16px;
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #d4d4df;
  position: absolute;
  top: 0;
`;

const Button = styled.button`
  border: rgba(0, 0, 0, 0.2) solid 1px;
  flex: 1;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  border-radius: 15px;
`;

const HeaderImage = styled.img`
  height: 42px;
  margin-top: -8px;
  margin-left: 15px;
`;
const HeaderText = styled.div`
  // height: 52px;
  // margin-top: 16px;
  margin-left: 8px;
`;
export default function Header() {
  const [drawerOpen, setDrawerOpen] = useGlobal("drawerOpen");
  const location = useLocation();

  console.log(config.title);

  const trimBackSlash = (pathname) => {
    if (pathname[0] === "/") {
      return pathname.substring(1, pathname.length);
    } else return pathname;
  };
  const envLinks = {
    development: `https://admin.test.dev.otto.vet/${trimBackSlash(
      location.pathname
    )}${location.search ? trimBackSlash(location.search) : ""}`,
    demo: `https://demo-admin.gettelevet.com/${trimBackSlash(
      location.pathname
    )}${location.search ? trimBackSlash(location.search) : ""}`,
    production: `https://admin.otto.vet/${trimBackSlash(location.pathname)}${
      location.search ? trimBackSlash(location.search) : ""
    }`,
  };
  const [token] = useGlobal("token");
  const [copied, setCopied] = useState(false);

  const { width, ref } = useResizeDetector();

  const [user] = useGlobal("user");
  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  const doneLabelStyle = {
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "5px",
    borderTopLeftRadius: "0",
    border: "rgba(0,0,0,0.2) solid 1px",
    boxShadow: "3px 3px 5px rgba(0,0,0,0.5)",
    background: "white",
    padding: "5px",
  };

  const onEnvChange = ({ target }) => {
    console.log(target.value);
    window.location.replace(target.value);
  };

  return (
    <HeaderWrapper ref={ref}>
      <Row1 style={{ alignItems: "center" }}>
        <CA style={{ display: "flex", alignItems: "center" }}>
          <FaBars size={24} style={{ paddingTop: 4 }} onClick={toggleDrawer} />
        </CA>
        <CA style={{ display: "flex", alignItems: "center" }}>
          <HeaderImage src={logo} />
        </CA>
        <CA style={{ display: "flex", alignItems: "center" }}>
          {/* <HeaderText>Admin - Dev</HeaderText> */}
          <select
            style={{
              border: "none",
              fontSize: "18px",
              background: "inherit",
              cursor: "pointer",
              paddingLeft: "1em",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onChange={onEnvChange}
            defaultValue={envLinks[config.title]}
          >
            <option value={envLinks.development}>Admin - Dev</option>
            <option value={envLinks.demo}>Admin - Demo</option>
            <option value={envLinks.production}>Admin - Prod</option>
          </select>
        </CA>
        <C1 />
        {location.pathname.includes("signin") ? (
          ""
        ) : (
          <>
            <C1 style={{ textAlign: "center" }}>
              <Link to="/">
                <Button
                  style={{
                    background: `${
                      location.pathname === "/"
                        ? "rgba(0, 0, 0, 0.2)"
                        : "inherit"
                    }`,
                  }}
                  href="/"
                  title="Clinics"
                >
                  {width > 1300 ? (
                    "Clinics"
                  ) : (
                    <FaClinicMedical title="Clinics" size={20} />
                  )}
                </Button>
              </Link>
            </C1>
            <C1 style={{ textAlign: "center" }}>
              <Link to="/users">
                <Button
                  style={{
                    background: `${
                      location.pathname === "/users"
                        ? "rgba(0, 0, 0, 0.2)"
                        : "inherit"
                    }`,
                  }}
                  href="users"
                  title="Users"
                >
                  {width > 1300 ? "Users" : <FaUser title="Users" size={20} />}
                </Button>
              </Link>
            </C1>
            <C1 style={{ textAlign: "center" }}>
              <Link to="/clinicEmployees">
                <Button
                  style={{
                    background: `${
                      location.pathname === "/clinicEmployees"
                        ? "rgba(0, 0, 0, 0.2)"
                        : "inherit"
                    }`,
                  }}
                  href="clinicEmployees"
                  title="Clinic Employees"
                >
                  {width > 1300 ? (
                    "Clinic Employees"
                  ) : (
                    <FaBriefcaseMedical title="Clinic Employees" size={20} />
                  )}
                </Button>
              </Link>
            </C1>
            <C1 style={{ textAlign: "center" }}>
              <Link to="/feature-flags">
                <Button
                  style={{
                    background: `${
                      location.pathname === "/feature-flags"
                        ? "rgba(0, 0, 0, 0.2)"
                        : "inherit"
                    }`,
                  }}
                  href="feature-flags"
                  title="Feature Flags"
                >
                  {width > 1300 ? (
                    "Feature Flags"
                  ) : (
                    <FaFlag title="Feature Flags" size={20} />
                  )}
                </Button>
              </Link>
            </C1>
            <C1 style={{ textAlign: "center" }}>
              <Link to="/playground">
                <Button
                  style={{
                    background: `${
                      location.pathname === "/playground"
                        ? "rgba(0, 0, 0, 0.2)"
                        : "inherit"
                    }`,
                  }}
                  href="playground"
                  title="Core-Api Playground"
                >
                  {width > 1300 ? (
                    "Playground"
                  ) : (
                    <FaKeyboard title="Core-Api Playground" size={20} />
                  )}
                </Button>
              </Link>
            </C1>
            <C1 style={{ textAlign: "center" }}>
              <Link to="/reporting">
                <Button
                  style={{
                    background: `${
                      location.pathname === "/reporting"
                        ? "rgba(0, 0, 0, 0.2)"
                        : "inherit"
                    }`,
                  }}
                  href="reporting"
                  title="Quicksight Reports"
                >
                  {width > 1300 ? (
                    "Reporting"
                  ) : (
                    <FaChartBar title="Quicksight Reporting" size={20} />
                  )}
                </Button>
              </Link>
            </C1>

            <C1 style={{ textAlign: "center" }}>
              <div style={{ position: "relative" }}>
                <p style={copied ? doneLabelStyle : { display: "none" }}>
                  Copied!
                </p>
                <Button
                  title="Copy Firebase Token"
                  style={{
                    border: "white dotted 2px",
                    background: "rgba(40,140,165,1)",
                    color: "#f5f7fa",
                  }}
                  onClick={() => {
                    setCopied(true);
                    navigator.clipboard.writeText(token);
                    setTimeout(() => {
                      setCopied(false);
                    }, 1500);
                  }}
                >
                  {width > 1300 ? (
                    "Copy Firebase Token"
                  ) : (
                    <FaLockOpen title="Copy Firebase Token" size={20} />
                  )}
                </Button>
              </div>
            </C1>
          </>
        )}
        <CA>
          <HeaderText>
            {user.fname} {user.lname}
          </HeaderText>
        </CA>
      </Row1>
    </HeaderWrapper>
  );
}
