import React, { useGlobal } from "reactn";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useFeatureFlagsQuery } from "./queries";
import { DELETE_FEATURE_FLAG } from "./mutations";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Button } from "../../../styledComponents/form";
import { Row, CA } from "../../../styledComponents/grid";

const FeatureFlags = () => {
  const history = useHistory();
  const [modalState, setModalState] = useGlobal("modalState");

  const [deleteFeatureFlag] = useMutation(DELETE_FEATURE_FLAG, {
    errorPolicy: "all",
    refetchQueries: ["getFeatureFlags"],
    awaitRefetchQueries: true,
  });

  const onDeleteFeatureFlag = async (id) => {
    await deleteFeatureFlag({
      variables: {
        where: {
          id,
        },
      },
    });

    setModalState({
      ...modalState,
      showModal: false,
    });
  };

  const onShowDeleteFeatureFlagConfirm = (id) => {
    setModalState({
      ...modalState,
      showModal: true,
      hideButtons: true,
      modalHeader: "",
      modalContent: (
        <ModalContent>
          <h1>Delete Feature Flag</h1>
          <p>Are you sure you want to delete this feature flag?</p>
          <ModalActions>
            <DeleteButton onClick={() => onDeleteFeatureFlag(id)} type="button">
              Delete
            </DeleteButton>
            <CancelButton
              onClick={() =>
                setModalState({
                  ...modalState,
                  showModal: false,
                })
              }
              type="button"
            >
              Cancel
            </CancelButton>
          </ModalActions>
        </ModalContent>
      ),
    });
  };

  return (
    <Container>
      <PrismaServerFilterTable
        title="Feature Flags"
        queryName="findManyFeatureFlag"
        useQuery={useFeatureFlagsQuery}
        renderedRows={100}
        queryVariables={{ orderBy: { createdAt: "asc" } }}
        filterConfigs={[
          { property: "key", display: "Key" },
          { property: "description", display: "Description" },
          {
            property: "type",
            display: "Type",
            type: "select",
            options: [
              { value: "Clinic", display: "Clinic" },
              { value: "Global", display: "Global" },
            ],
          },
          {
            property: "state",
            display: "State",
            type: "select",
            options: [
              { value: "On", display: "On" },
              { value: "Off", display: "Off" },
            ],
          },
        ]}
        columns={[
          {
            Header: "",
            hidefilter: true,
            blockSort: true,
            accessor: ({ data }) => {
              return (
                <Row>
                  <CA>
                    <FaEdit
                      color="#2ABA66"
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="Edit Feature Flag"
                      onClick={() => {
                        history.push(`/feature-flags/manage/${data.id}`);
                      }}
                    />
                    <FaTrashAlt
                      color="#E9573F"
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="Remove Feature Flag"
                      onClick={() => onShowDeleteFeatureFlagConfirm(data.id)}
                    />
                  </CA>
                </Row>
              );
            },
          },
          { Header: "Key", accessor: "key" },
          { Header: "Description", accessor: "description" },
          { Header: "Type", accessor: "type" },
          { Header: "State", accessor: "state" },
          {
            Header: "Add Clinics",
            accessor: ({ data: { type, id } }) => {
              if (type === "Clinic") {
                return (
                  <Button
                    onClick={() => {
                      history.push(`/feature-flags/clinics/${id}`);
                    }}
                  >
                    View
                  </Button>
                );
              }
              return null;
            },
          },
        ]}
      />
    </Container>
  );
};

export default FeatureFlags;

const Container = styled.div`
  width: 100%;
`;

const ModalContent = styled.div`
  position: relative;
  text-align: center;
`;

const ModalActions = styled.div`
  text-align: left;
`;

const DeleteButton = styled(Button)`
  float: none;
  margin-right: 10px;
  display: inline-block;
  background-color: #ff3e28;
`;

const CancelButton = styled(Button)`
  float: none;
  display: inline-block;
`;
