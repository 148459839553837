import React, { useState, useGlobal, useEffect, useCallback } from "reactn";
import {
  Input,
  SaveButton,
  Form,
  Button,
  TextArea,
} from "../../styledComponents/form";
import { Row, CA, Column1 } from "../../styledComponents/grid";
import Container from "../../styledComponents/container";
import Combobox from "./comboBox";
import DatePicker from "react-datepicker";
import styled from "styled-components/macro";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import Loading from "./loading";

function titleCase(str) {
  let string = str.toLowerCase().split(" ");
  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(" ");
}

const DatePickerWrapper = styled.div`
  label {
    font-size: 13px;
    color: #434a54;
  }
  input {
    height: 20px;
    padding: 6px;
    margin-top: 2px;
    width: calc(100% - 14px);
    border-width: 1px;
    border-color: #3c3b3d;
  }
`;
const FieldWrapper = styled.div`
  flex: 1;
  padding: 8px 10px;
  :first-child {
    padding-left: 0px;
  }
`;
function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}

export default function FormComp({
  onCreate,
  onUpdate,
  fields,
  onCancel,
  defaultState,
  title,
  useQuery,
  queryName,
  id,
  copyId,
  notCrud,
  onChange,
  hideCancel,
  hideSave,
  saveTitle,
  joinTables,
  prismaVersion,
}) {
  useQuery =
    useQuery ||
    function () {
      return [{}, {}];
    };
  fields = fields || [];
  let [state, setState] = useState(defaultState || {});
  const forceUpdate = useForceUpdate();
  const [modalState, setModalState] = useGlobal("modalState");
  const [loadData, queryData] = useQuery();
  const [hovering, setHovering] = useState("");

  const horizontalBreakStyle = {
    border: "none",
    height: "2px",
    borderTop: "2px solid rgb(200,200,200)",
    flex: "1",
  };

  const hrDivStyle = {
    width: "100%",
    margin: "50px 0",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  };

  const descriptionStyle = {
    display: "block",
    position: "absolute",
    bottom: "100%",
    left: "1em",
    background: "rgb(300,300,245)",
    width: "100%",
    padding: "15px",
    boxShadow: "-2px 2px 3px black",
  };

  useEffect(() => {
    if ((id || copyId) && loadData) {
      if (prismaVersion === 2) {
        loadData({ variables: { where: { id: { equals: id || copyId } } } });
      } else {
        loadData({ variables: { where: { id: id || copyId } } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, copyId, loadData]);

  useEffect(() => {
    if (queryData.data && queryData.data[queryName]) {
      if (joinTables) {
        console.log(joinTables);
        join(joinTables.table1, joinTables.table2, joinTables.fk);
      }
      let row = queryData.data[queryName];
      delete row.__typename;
      if (copyId) {
        delete row.id;
      }
      setState(convertToDotNotation(row));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.data, copyId, queryName]);

  function handleStateEventChange(prop, targetAttribute) {
    return function (event) {
      handleStateChange(prop)(
        targetAttribute ? event.target[targetAttribute] : event.target.value
      );
    };
  }
  function handleStateChange(prop) {
    return (value) => {
      state[prop] = value;
      setState(state);
      if (onChange) {
        onChange(state);
      }
    };
  }

  function convertState(st) {
    const copy = { ...st };
    _.chain(fields)
      .flatten()
      .each((field) => {
        if (copy[field.property] && field.type === "number") {
          copy[field.property] = +copy[field.property];
        }
      })
      .value();
    return copy;
  }
  async function submitWrapper(eve) {
    eve.preventDefault();
    try {
      _.chain(fields)
        .flatten()
        .filter("required")
        .each((field) => {
          if (!state[field.property]) {
            throw new Error(
              `${field.display || titleCase(field.property)} is Required`
            );
          }
        })
        .value();
      if (onCreate || onUpdate) {
        setModalState({
          ...modalState,
          showModal: true,
          modalHeader: "Processing",
          modalContent: <Loading />,
        });
        await (id
          ? onUpdate(deepen(convertState({ ...state })))
          : onCreate(deepen(convertState({ ...state }))));
        setModalState({
          ...modalState,
          showModal: false,
        });
      }
    } catch (err) {
      setModalState({
        ...modalState,
        showModal: true,
        modalHeader: "Error",
        modalContent: err.message,
      });
    }
  }

  function fieldParentMapper(field, i) {
    if (field.length) {
      return <Row key={`form-row-${i}`}>{field.map(fieldMapper)}</Row>;
    }
    return <Row key={`form-row-${i}`}>{fieldMapper(field)}</Row>;
  }
  function RequiredLabel() {
    return <span style={{ color: "#E9573F" }}>*</span>;
  }
  function fieldMapper(
    {
      display,
      property,
      options,
      required,
      type,
      listProperty,
      placeholder,
      min,
      max,
      description,
      conditional,
    },
    i
  ) {
    function Label(props) {
      return (
        <div {...props}>
          {type === "list" ? (
            <Button
              type="button"
              onClick={() => {
                handleStateChange(property)([...(state[property] || []), {}]);
                forceUpdate();
              }}
            >
              Add {display || titleCase(property)}
            </Button>
          ) : (
            <label>
              {display || titleCase(property)}{" "}
              {required ? <RequiredLabel /> : null}
            </label>
          )}
        </div>
      );
    }

    if (type === "hr") {
      return (
        <span style={hrDivStyle}>
          <hr style={horizontalBreakStyle} />
          {!display ? "" : <p style={{ padding: "0 10px" }}>{display}</p>}
          <hr style={horizontalBreakStyle} />
        </span>
      );
    }

    if (!property) {
      return <FieldWrapper key={i || "blank"}> </FieldWrapper>;
    }

    if (type === "select") {
      return (
        <FieldWrapper key={property}>
          <Label />
          <Combobox
            value={state[property]}
            options={options}
            onChange={handleStateChange(property)}
          />
        </FieldWrapper>
      );
    }
    if (type === "date") {
      return (
        <DatePickerWrapper key={property}>
          <Label />
          <DatePicker
            selected={state[property]}
            onChange={handleStateChange(property)}
          />
        </DatePickerWrapper>
      );
    }
    if (type === "list") {
      const selects = (state[property] || []).map((item, i) => (
        <Combobox
          key={`${property}-${i}`}
          value={item[listProperty]}
          options={options}
          onChange={handleStateChange(property)}
        />
      ));

      return (
        <FieldWrapper key={`${property}`}>
          <Row>
            <Label />
          </Row>
          <Row style={{ marginTop: 8 }}>{selects}</Row>
        </FieldWrapper>
      );
    }
    if (type === "checkbox") {
      return (
        <FieldWrapper key={property}>
          <Label />
          <Input
            style={{ width: "30%" }}
            type="checkbox"
            checked={!!state[property]}
            onChange={() => {
              handleStateChange(property)(!state[property]);
              forceUpdate();
            }}
          />
        </FieldWrapper>
      );
    }
    if (type === "textarea") {
      return (
        <FieldWrapper key={property}>
          <Label />
          <TextArea
            defaultValue={state[property]}
            onChange={handleStateEventChange(property)}
          />
        </FieldWrapper>
      );
    }
    if (type === "percent") {
      handleStateEventChange(property);
      return (
        <FieldWrapper
          onMouseOver={
            description
              ? () => {
                  setHovering(property);
                }
              : ""
          }
          onMouseLeave={description ? () => setHovering("") : ""}
          style={description ? { position: "relative" } : {}}
          key={property}
        >
          {description ? (
            <p
              style={
                hovering === property ? descriptionStyle : { display: "none" }
              }
            >
              {description}
            </p>
          ) : (
            ""
          )}
          <Label />

          <Input
            key={property + state[property]} // Added to have DefaultValue show up
            type="number"
            min={(min * 100).toFixed(3)}
            max={(max * 100).toFixed(3)}
            step="any"
            defaultValue={
              state[property] * 100 < 100
                ? Math.round(state[property] * 100 * 1000) / 1000
                : state[property]
            }
            placeholder={
              state[property] * 100 < 100
                ? Math.round(state[property] * 100 * 1000) / 1000
                : state[property]
            }
            onChange={handleStateEventChange(property)}
          />
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        onMouseOver={
          description
            ? () => {
                setHovering(property);
              }
            : ""
        }
        onMouseLeave={description ? () => setHovering("") : ""}
        style={description ? { position: "relative" } : {}}
        key={property}
      >
        {description ? (
          <p
            style={
              hovering === property ? descriptionStyle : { display: "none" }
            }
          >
            {description}
          </p>
        ) : (
          ""
        )}
        <Label />

        <Input
          key={property + state[property]} // Added to have DefaultValue show up
          type={type === "number" ? "number" : "text"}
          min={type === "number" ? min : null}
          max={type === "number" ? max : null}
          step={type === "number" ? "any" : null}
          defaultValue={state[property]}
          placeholder={state[property]}
          onChange={handleStateEventChange(property)}
        />
      </FieldWrapper>
    );
  }
  function join(table1, table2, fk) {
    console.log(table1, table2, fk);
    const queryState = state[queryName];
    console.log(queryState);
  }
  return (
    <Container>
      <h4>
        {notCrud ? "" : state.id ? "Update" : "Create"} {title}
      </h4>
      <Form onSubmit={submitWrapper}>
        {fields.map(fieldParentMapper)}
        <Row>
          <Column1>
            <RequiredLabel /> indicates required field
          </Column1>
          {hideCancel ? null : (
            <CA>
              <SaveButton
                type="button"
                onClick={onCancel}
                style={{ backgroundColor: "#DA4453" }}
              >
                Cancel
              </SaveButton>
            </CA>
          )}
          {hideSave ? null : (
            <CA>
              <SaveButton type="submit">{saveTitle || "Save"}</SaveButton>
            </CA>
          )}
        </Row>
      </Form>
    </Container>
  );
}

function deepen(o) {
  var oo = {},
    t,
    parts,
    part;
  for (var k in o) {
    t = oo;
    parts = k.split(".");
    var key = parts.pop();
    while (parts.length) {
      part = parts.shift();
      t = t[part] = t[part] || {};
    }
    t[key] = o[k];
  }
  return oo;
}
function convertToDotNotation(ob) {
  const blank = {};
  const test = recurse(ob);
  return test;
  function recurse(obj, current) {
    for (var key in obj) {
      var value = obj[key];
      var newKey = current ? current + "." + key : key; // joined key with dot
      if (value && typeof value === "object") {
        recurse(value, newKey); // it's a nested object, so do it again
      } else {
        blank[newKey] = value; // it's not an object, so set the property
      }
    }
    return blank;
  }
}
