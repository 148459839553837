import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_FEATURE_FLAG } from "../queries";
import { CREATE_FEATURE_FLAG, UPDATE_FEATURE_FLAG } from "../mutations";
import {
  Form,
  Input,
  SaveButton,
  Select,
} from "../../../../styledComponents/form";

const ManageFeatureFlag = ({ match }) => {
  const [formState, setFormState] = useState({
    key: "",
    description: "",
    type: "Global",
    state: "On",
  });

  const [errors, setErrors] = useState([]);

  const history = useHistory();

  const [getFeatureFlag, { data: featureFlagData }] =
    useLazyQuery(GET_FEATURE_FLAG);
  const featureFlagId = match.params.id;

  const [createFeatureFlag, { loading: createFeatureFlagLoading }] =
    useMutation(CREATE_FEATURE_FLAG, {
      errorPolicy: "all",
    });

  const [updateFeatureFlag, { loading: updateFeatureFlagLoading }] =
    useMutation(UPDATE_FEATURE_FLAG, {
      errorPolicy: "all",
    });

  /**
   * Get featureFlag if we are in edit mode
   */
  useEffect(() => {
    if (featureFlagId) {
      getFeatureFlag({
        variables: {
          where: {
            id: featureFlagId,
          },
        },
      });
    }
  }, [featureFlagId, getFeatureFlag]);

  /**
   * Fill form state if editing
   */
  useEffect(() => {
    if (!featureFlagData?.featureFlag) return;
    setFormState(featureFlagData.featureFlag);
  }, [featureFlagData]);

  const onChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setErrors([]);
    try {
      if (!formState.key) {
        setErrors(['"Key" is required.']);
        return;
      }
      if (featureFlagId) {
        await updateFeatureFlag({
          variables: {
            data: {
              key: formState.key,
              description: formState.description,
              type: formState.type,
              state: formState.state,
            },
            where: {
              id: featureFlagId,
            },
          },
        });
      } else {
        await createFeatureFlag({
          variables: {
            data: formState,
          },
        });
      }
      history.goBack();
    } catch (e) {
      setErrors([
        'There was an error creating your feature flag. Please ensure you are using a unique "Key"',
      ]);
    }
  };

  return (
    <ContentContainer>
      <FormContainer>
        <Header>
          <Title>
            {featureFlagId ? "Edit Feature Flag" : "Add Feature Flag"}
          </Title>
        </Header>
        <>
          {errors.map((error) => {
            return <FormError>{error}</FormError>;
          })}
        </>
        <Form onSubmit={onSubmit}>
          <InputWrapper>
            <Label>Key *</Label>
            <Input name="key" value={formState.key} onChange={onChange} />
          </InputWrapper>
          <InputWrapper>
            <Label>Description</Label>
            <Input
              name="description"
              value={formState.description}
              onChange={onChange}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Type</Label>
            <Select name="type" value={formState.type} onChange={onChange}>
              <option value="Clinic">Clinic</option>
              <option value="Global">Global</option>
            </Select>
          </InputWrapper>
          <InputWrapper>
            <Label>State</Label>
            <Select name="state" value={formState.state} onChange={onChange}>
              <option value="On">On</option>
              <option value="Off">Off</option>
            </Select>
          </InputWrapper>
          <ButtonGroup>
            <SaveButton
              type="submit"
              disabled={createFeatureFlagLoading || updateFeatureFlagLoading}
            >
              Save
            </SaveButton>
            <CancelButton type="button" onClick={() => history.goBack()}>
              Cancel
            </CancelButton>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </ContentContainer>
  );
};

export default ManageFeatureFlag;

const ContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;
`;

const FormContainer = styled.div`
  min-width: 400px;
  width: 50%;
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
`;

const Title = styled.h1``;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 1em;
  color: #434a54;
  padding-bottom: 5px;
  display: block;
`;

const ButtonGroup = styled.div``;

const CancelButton = styled(SaveButton)`
  background-color: #46475a;
`;

const FormError = styled.p`
  color: #ff3e28;
`;
