import moment from "moment";
const stateName = "televet-admin-state-unencoded";
const dateFormat = "YYYY-MM-DDTHH:mm";
const blankState = {
  appInitialized: false,
  drawerOpen: false,
  token: "",
  user: {},
  loginUser: {},
  snackbars: [],
  currentTimespan: {
    key: "thisWeek",
    display: "This Week",
    start: moment().startOf("week").format(dateFormat),
    end: moment().endOf("week").format(dateFormat),
  },
  modalState: {
    modalHeader: "",
    modalContent: "",
    modalColor: "",
    showModal: false,
    modalConfirm: () => {},
  },
  aiModelId: "anthropic.claude-3-haiku-20240307-v1:0",
};
function storeGlobalState(global, ignoreKeys) {
  const clone = { ...global };
  if (ignoreKeys) {
    ignoreKeys.forEach((key) => {
      delete clone[key];
    });
  }
  localStorage.setItem(stateName, JSON.stringify(clone));
}
function removeGLobalState() {
  localStorage.removeItem(stateName);
}
function getStoredState() {
  const state = localStorage.getItem(stateName);
  if (state) {
    return JSON.parse(state);
  } else {
    localStorage.setItem(stateName, JSON.stringify(blankState));
    return blankState;
  }
}
export { getStoredState, storeGlobalState, blankState, removeGLobalState };
