import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Input } from "../../styledComponents/form";

import styled from "styled-components/macro";
import { FaCaretDown } from "react-icons/fa";
const BoxContainer = styled.div``;
const Row = styled.div`
  display: flex;
  flex: 0 0;
`;
const C1 = styled.div`
  flex: 1;
`;
const OptionsContainer = styled.div`
  position: absolute;
  background-color: #fefefe;
  border: 1px solid #aab2bd;
  max-height: 300px;
  overflow-x: scroll;
`;
const IconContainer = styled.div`
  background-color: #fefefe;
  border: 1px solid black;
`;
const CA = styled.div``;
export default function ComboBox({ onChange, value, options, autoComplete }) {
  const containerRef = useRef();
  const inputRef = useRef();
  options = options || [];
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
  const [currentDisplay, setCurrentDisplay] = useState("");
  const [inputHeight, setInputHeight] = useState(30);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  useEffect(() => {
    if (value && options) {
      const selected = _.find(options, { key: value });
      setCurrentDisplay(selected?.display);
    }
  }, [value, options]);
  useEffect(() => {
    if (options) {
      setCurrentOptions(options);
    }
  }, [options]);
  useEffect(() => {
    setCurrentSelectedIndex(0);
    if (!currentDisplay) {
      return setCurrentOptions(options);
    }
    setCurrentOptions(
      _.filter(options, ({ display }) => {
        display = display || "";
        return (
          display.toLowerCase().indexOf(currentDisplay.toLowerCase()) !== -1
        );
      })
    );
  }, [currentDisplay, options]);
  useEffect(() => {
    const { current } = inputRef;
    setInputHeight(current.clientHeight || 30);
    current.addEventListener("focus", inputFocused);
    current.addEventListener("blur", inputBlurred);
    return () => {
      current.removeEventListener("focus", inputFocused);
      current.removeEventListener("blur", inputBlurred);
    };
  }, [inputRef]);
  function inputFocused() {
    setTimeout(() => {
      setShowOptions(true);
      setCurrentSelectedIndex(0);
    }, 100);
  }
  function inputBlurred() {
    setTimeout(() => {
      setShowOptions(false);
      setCurrentSelectedIndex(0);
    }, 600);
  }
  function toggleOptions() {
    setCurrentOptions(options);
    setShowOptions(!showOptions);
  }
  function inputChanged(eve) {
    setCurrentDisplay(eve.target.value);
  }
  function handleKeyboardEvents(eve) {
    if (eve.key === "Tab") {
      onChange(currentOptions[currentSelectedIndex].key);
      inputChanged({
        target: { value: currentOptions[currentSelectedIndex].display },
      });
      setShowOptions(false);
      setCurrentSelectedIndex(0);
    }
    if (eve.key === "Enter") {
      eve.preventDefault();
      onChange(currentOptions[currentSelectedIndex].key);
      inputChanged({
        target: { value: currentOptions[currentSelectedIndex].display },
      });
      setShowOptions(false);
      setCurrentSelectedIndex(0);
    }
    if (eve.key === "ArrowDown") {
      if (currentSelectedIndex >= currentOptions.length - 1) return;
      setCurrentSelectedIndex(currentSelectedIndex + 1);
    }
    if (eve.key === "ArrowUp") {
      if (currentSelectedIndex === 0) return;
      setCurrentSelectedIndex(currentSelectedIndex - 1);
    }
  }
  return (
    <BoxContainer ref={containerRef}>
      <Row>
        <C1>
          <Input
            value={currentDisplay}
            onChange={inputChanged}
            ref={inputRef}
            onKeyDown={handleKeyboardEvents}
            type="text"
            autoComplete={autoComplete || "none"}
          />
        </C1>
        <CA>
          <IconContainer style={{ height: inputHeight }}>
            <FaCaretDown
              size={30}
              style={{ paddingLeft: 4, paddingRight: 4 }}
              onClick={toggleOptions}
            />
          </IconContainer>
        </CA>
      </Row>
      <OptionsContainer
        style={{
          display: showOptions ? "" : "none",
          width: containerRef.current
            ? containerRef.current.getBoundingClientRect().width
            : "100%",
        }}
      >
        {currentOptions.map((opt, index) => (
          <Row
            style={{
              padding: 8,
              borderBottom: "1px solid #E6E9ED",
              backgroundColor: currentSelectedIndex === index ? "#E6E9ED" : "",
            }}
            key={opt.key}
            onClick={() => {
              setCurrentDisplay(opt.display);
              onChange(opt.key);
              setCurrentSelectedIndex(0);
              setShowOptions(false);
            }}
          >
            <C1>{opt.display}</C1>
          </Row>
        ))}
      </OptionsContainer>
    </BoxContainer>
  );
}
