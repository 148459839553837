import styled from "styled-components/macro";
const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  img {
    border-radius: 5px 5px 0 0;
  }
`;
const CardDivider = styled.hr`
  margin: 8px;
`;
const CardContent = styled.div`
  padding: 8px 16px;
`;

export { Card, CardContent, CardDivider };
