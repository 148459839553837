import React, { useGlobal, useEffect, useState } from "reactn";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FaArrowRight, FaTrash, FaPlus } from "react-icons/fa";
import Loading from "../../app/loading";
import { Row, Col } from "react-flexa";
export default function ClinicRoleView({
  match: {
    params: { clinicId },
  },
}) {
  const [clinicName] = useGlobal("clinicName");
  const [updateRole] = useMutation(UPDATE_ROLE);
  const [modalState, setModalState] = useGlobal("modalState");
  const [currentRole, setCurrentRole] = useState();
  const { data, loading, refetch } = useQuery(MANAGE_CLINIC_ROLES, {
    fetchPolicy: "network-only",
    variables: {
      clinicId,
    },
  });
  useEffect(() => {
    console.log(data);
    if (data && !currentRole) {
      setCurrentRole(data.roles[0]);
    } else {
      if (data) {
        setCurrentRole(data.roles.find(({ id }) => id === currentRole.id));
      }
    }
  }, [data, currentRole, setCurrentRole, loading]);
  if (!data || loading) {
    return <Loading />;
  }
  const { permissions, roles, findManyUser: users } = data;
  async function updateBaseRole(data) {
    setModalState({ ...modalState, showModal: false });
    await updateRole({
      variables: {
        where: {
          id: currentRole?.id,
        },
        data,
      },
    });
    refetch();
  }
  function addPermission({ id }) {
    return () => {
      updateBaseRole({
        permissions: { connect: { id } },
      });
    };
  }
  function deletePermission({ id }) {
    return () => {
      updateBaseRole({
        permissions: { disconnect: { id } },
      });
    };
  }
  function addUser({ vetInfo }) {
    return () => {
      updateBaseRole({
        users: { connect: { id: vetInfo?.id } },
      });
    };
  }
  function deleteUser({ id }) {
    return () => {
      updateBaseRole({
        users: { disconnect: { id } },
      });
    };
  }
  function AddPermission() {
    const avaliable = permissions.filter((perm) => {
      return (
        currentRole?.permissions.map(({ id }) => id).indexOf(perm.id) === -1
      );
    });
    if (!avaliable.length) {
      return <h4>No permissions to add</h4>;
    }
    return avaliable.map((perm) => (
      <Row>
        <Col>
          <FaPlus onClick={addPermission(perm)} color="#2ABA66" />
        </Col>
        <Col>{perm.type}</Col>
      </Row>
    ));
  }
  function AddUser() {
    const avaliable = users.filter((user) => {
      return (
        currentRole?.users.map(({ id }) => id).indexOf(user?.vetInfo?.id) === -1
      );
    });
    if (!avaliable.length) {
      return <h4>No users to add</h4>;
    }
    return avaliable.map((user) => (
      <Row>
        <Col>
          <FaPlus onClick={addUser(user)} color="#2ABA66" />
        </Col>
        <Col>{user?.email}</Col>
      </Row>
    ));
  }
  return (
    <div>
      <h4>
        {clinicName} <FaArrowRight size={16} /> Roles
      </h4>
      <Row justifyContent="center">
        <Col xs={2}>
          <b style={{ paddingBottom: 8 }}>Role: &nbsp;</b>
          {roles.map((role) => {
            return (
              <Row
                style={{
                  padding: 8,
                  border: "1px solid grey",
                  margin: 4,
                  backgroundColor: role.id === currentRole?.id ? "#E6E9ED" : "",
                }}
                onClick={() => setCurrentRole(role)}
                key={role.id}
              >
                {role.role}
              </Row>
            );
          })}
        </Col>
        <Col xs={4}>
          <Row style={{ paddingBottom: 8 }}>
            <Col xs={10} md={6} lg={4}>
              <b>Permissions: </b>
            </Col>
            <Col>
              <FaPlus
                color="#2ABA66"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    modalHeader: "Add Permission",
                    modalContent: <AddPermission />,
                    showModal: true,
                  });
                }}
              />
            </Col>
          </Row>
          {currentRole?.permissions.map(({ id, type }) => (
            <Row style={{ padding: 8, border: "1px solid #E6E9ED" }} key={id}>
              <Col xs={6}>{type}</Col>
              <Col xs={6}>
                <FaTrash
                  color="red"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={deletePermission({ id })}
                />
              </Col>
            </Row>
          ))}
        </Col>
        <Col xs={6}>
          <Row style={{ paddingBottom: 8 }}>
            <Col sm={3} lg={2}>
              <b>Users: </b>
            </Col>
            <Col>
              <FaPlus
                color="#2ABA66"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModalState({
                    ...modalState,
                    modalHeader: "Add User",
                    modalContent: <AddUser />,
                    showModal: true,
                  });
                }}
              />
            </Col>
          </Row>

          {currentRole?.users.map(({ id, user }) => (
            <Row style={{ padding: 8, border: "1px solid #E6E9ED" }} key={id}>
              <Col xs={2}>{user?.firstName}</Col>
              <Col xs={2}>{user?.lastName}</Col>
              <Col xs={6}>{user?.email}</Col>
              <Col xs={1}>
                <FaTrash
                  color="red"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={deleteUser({ id })}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </div>
  );
}

const UPDATE_ROLE = gql`
  mutation ($where: StaffRoleWhereUniqueInput!, $data: StaffRoleUpdateInput!) {
    updateOneStaffRole(data: $data, where: $where) {
      id
    }
  }
`;
const MANAGE_CLINIC_ROLES = gql`
  query ($clinicId: String!) {
    permissions: findManyPermission(orderBy: { type: asc }) {
      id
      type
    }
    roles: findManyStaffRole(where: { clinic: { id: { equals: $clinicId } } }) {
      id
      role
      permissions(orderBy: { type: asc }) {
        id
        type
      }
      users {
        id
        user {
          firstName
          lastName
          email
        }
      }
    }
    findManyUser(
      where: {
        type: { equals: VET }
        vetInfo: { clinics: { some: { id: { equals: $clinicId } } } }
      }
      orderBy: { email: asc }
    ) {
      id
      firstName
      lastName
      email
      vetInfo {
        id
      }
    }
  }
`;
