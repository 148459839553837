import React, { useGlobal } from "reactn";
import Users from "../users";
import { FaArrowRight } from "react-icons/fa";
export default function ClinicUserView({
  match: {
    params: { clinicId, userType }
  }
}) {
  const [clinicName] = useGlobal("clinicName");
  return (
    <div>
      <h4>
        {clinicName} <FaArrowRight size={16} /> {userType}s
      </h4>
      <Users clinicId={clinicId} userType={userType.toUpperCase()} />
    </div>
  );
}
