import React from "react";
import styled from "styled-components/macro";

const Modal = ({ children }) => {
  const Comp = styled.div`
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  `;
  return <Comp>{children}</Comp>;
};
const ModalHeader = styled.div`
  background-color: #e6e9ed;
  padding: 4px;
`;
const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 175px auto;
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  max-width: 1000px;
`;
const ModalInnerContent = styled.div`
  padding: 20px;
  min-height: 120px;
`;
const ModalFooter = styled.div`
  padding: 12px;
`;
const ModalButton = styled.button`
  margin: 4px;
  padding: 12px 24px;
  font-size: 14px;
  color: #f5f7fa;
  background-color: #2aba66;
  border-radius: 4px;
`;
export {
  Modal,
  ModalContent,
  ModalHeader,
  ModalInnerContent,
  ModalFooter,
  ModalButton,
};
