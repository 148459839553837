import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useGlobal } from "reactn";
import CareBenefit from "../modals/careBenefit";

export const useBenefitModal = (
  orgId,
  benefit,
  integrations,
  onClose,
  client
) => {
  const [, setModalState] = useGlobal("modalState");
  const [saveNewBenefit] = useMutation(gql`
    mutation addBenefit($data: CareBenefitCreateInput!) {
      createOneCareBenefit(data: $data) {
        id
        title
        description
        createdAt
        updatedAt
        usageUnitOfMeasure
      }
    }
  `);
  const [updateBenefit] = useMutation(gql`
    mutation updateBenefit($id: String!, $data: CareBenefitUpdateInput!) {
      updateOneCareBenefit(where: { id: $id }, data: $data) {
        id
        title
        description
        createdAt
        updatedAt
        usageUnitOfMeasure
      }
    }
  `);
  const onBenefitSave = async (values) => {
    const { id, ...data } = values;
    if (!id) {
      return await saveNewBenefit({
        variables: {
          data: {
            ...data,
            organization: {
              connect: {
                id: orgId,
              },
            },
          },
        },
      });
    } else {
      return await updateBenefit({
        variables: {
          id,
          data,
        },
      });
    }
  };
  useEffect(() => {
    if (!benefit) return;
    setModalState({
      showModal: true,
      modalHeader: <h3>Benefit - {benefit?.id || "new"}</h3>,
      modalContent: (
        <CareBenefit
          benefit={benefit || {}}
          integrations={integrations || []}
          onSave={onBenefitSave}
          client={client}
        />
      ),
      modalConfirm: onClose,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefit]);
  const open = () => {
    setModalState({
      showModal: true,
      modalHeader: <h3>Benefit - {benefit?.id || "new"}</h3>,
      modalContent: (
        <CareBenefit
          benefit={benefit || {}}
          integrations={integrations || []}
          onSave={onBenefitSave}
          client={client}
        />
      ),
      modalConfirm: onClose,
    });
  };
  return {
    open,
  };
};
