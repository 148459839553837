import gql from "graphql-tag";

const SEND_UI_REFRESH_EVENT = gql`
  mutation sendUIRefreshEvent($data: UiRefreshInput!) {
    sendUiRefreshEvent(data: $data) {
      refreshType
    }
  }
`;

export { SEND_UI_REFRESH_EVENT };
