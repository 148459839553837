import React from "reactn";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import { useTransactionsQuery } from "./transactionsGraphQL";
import { formatGridDate } from "../../app/financialData";
export default function Transactions() {
  return (
    <div>
      <PrismaServerFilterTable
        title="Financial Transactions"
        prismaVersion={2}
        queryName="findManyTransaction"
        useQuery={useTransactionsQuery}
        filterConfigs={[
          { property: "createdAt", display: "Created", type: "date" },
          {
            property: "consultation.resolvedAt",
            display: "Resolved At",
            type: "date",
          },
          { property: "consultation.clinic.name", display: "Clinic" },
          {
            property: "consultation.clinic.organization.name",
            display: "Organization",
          },
        ]}
        queryVariables={{
          orderBy: { createdAt: "desc" },
          where: { status: { not: { equals: "AUTHORIZED" } } },
          take: 500,
        }}
        columns={[
          {
            Header: "Created",
            accessor: ({ data }) => data.createdAt,
            Cell: formatGridDate,
          },
          {
            Header: "Resolved At",
            accessor: ({ data }) => data.consultation.resolvedAt,
            Cell: formatGridDate,
          },
          {
            Header: "Clinic",
            accessor: ({ data }) => data.consultation.clinic?.name,
          },
          {
            Header: "Corporate Id",
            accessor: ({ data }) => data.consultation.clinic?.corporateId,
          },
          {
            Header: "Organization",
            accessor: ({ data }) =>
              data.consultation.clinic?.organization?.name,
          },
          {
            Header: "Vet",
            accessor: ({ data }) =>
              `${data.consultation.vet?.user?.firstName || ""} ${
                data.consultation.vet?.user?.lastName || ""
              }`,
          },
          {
            Header: "Nurse",
            accessor: ({ data }) =>
              `${data.consultation.nurse?.user.firstName || ""} ${
                data.consultation.nurse?.user.lastName || ""
              }`,
          },
          {
            Header: "Owner",
            accessor: ({ data }) =>
              `${data.consultation.petParent?.user?.firstName || ""} ${
                data.consultation.petParent?.user?.lastName || ""
              }`,
          },
          {
            Header: "Summary",
            accessor: ({ data }) => data.consultation.summary,
          },
          { accessor: "transactionId" },
          {
            Header: "Consultation FB Id",
            accessor: ({ data }) => data.consultation.firebaseId,
          },
          { accessor: "status" },
          {
            accessor: "settlementDate",
            Cell: formatGridDate,
            Header: "Settlement Date",
          },
          {
            accessor: "disbursementDate",
            Header: "Disbursement Date",
          },
          { accessor: "amount", Cell: formatMoney },
          {
            accessor: "amountForSettlement",
            Cell: formatMoney,
            Header: "Amount For Settlement",
          },
          {
            accessor: "serviceFeeAmount",
            Cell: formatMoney,
            Header: "Service Fee Amount",
          },
          { accessor: "cardType", Header: "Card Type" },
          { accessor: "cardLast4", Header: "Card Last 4" },
          {
            accessor: "amountSettled",
            Cell: formatMoney,
            Header: "Amount Settled",
          },
          {
            accessor: "clinicEarnings",
            Cell: formatMoney,
            Header: "Clinic Earnings",
          },
        ]}
      />
    </div>
  );
}
function formatMoney({ value }) {
  return (value || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
