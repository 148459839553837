import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const APPOINTMENT_HISTORY = gql`
  query getAppointmentHistory($where: GetEntityHistoryInput!) {
    getAppointmentHistory(where: $where) {
      data
    }
  }
`;

function useAppointmentHistory() {
  const [getAppointmentHistory, { called, loading, data }] = useLazyQuery(
    APPOINTMENT_HISTORY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return [getAppointmentHistory, { called, loading, data }];
}
export { useAppointmentHistory, APPOINTMENT_HISTORY };
