import React, { useGlobal } from "reactn";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalInnerContent,
  ModalFooter,
  ModalButton,
} from "../../styledComponents/modal";
import { FaWindowClose } from "react-icons/fa";
import { Row, C1, CA } from "../../styledComponents/grid";

export default function AppModal() {
  const [modalState, setGlobalModalState] = useGlobal("modalState");
  function setModalState(propObj) {
    setGlobalModalState({ ...modalState, ...propObj });
  }
  function closeModal() {
    setModalState({ showModal: false });
  }
  function confirmAction() {
    if (modalState.modalConfirm) {
      modalState.modalConfirm();
    }
    closeModal();
  }


  return modalState.showModal ? (
    <Modal>
      <ModalContent size={modalState.size}>
        <ModalHeader>
          <Row>
            <C1>{modalState.modalHeader}</C1>
            <CA>
              <FaWindowClose
                size={32}
                style={{ margin: 8 }}
                onClick={closeModal}
              />
            </CA>
          </Row>
        </ModalHeader>
        <ModalInnerContent>
          {modalState.modalContent || "Hello"}
        </ModalInnerContent>
        {!modalState.hideButtons ? (
          <ModalFooter>
            <Row>
              <C1 />
              <CA>
                <ModalButton onClick={confirmAction}>OK</ModalButton>
              </CA>
            </Row>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  ) : (
    <div />
  );
}
