import { useQuery } from "@apollo/react-hooks";
import React from "react";
import styled from "styled-components/macro";
import gql from "graphql-tag";

const Inform = () => {
  const { data, loading: isLoading } = useQuery(gql`
    query {
      findManyInformPartnerProgramEnrollment(
        where: { informPartnerProgram: { name: { equals: "Hills-to-Home" } } }
        orderBy: { clinic: { name: asc } }
      ) {
        updatedAt
        clinicId
        clinic {
          name
        }
        status
        statusUpdatedAt
        informPartnerProgram {
          name
        }
      }
    }
  `);

  return (
    <InformWrapper>
      <Title>Hills to Home Enrollments</Title>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Table>
          <Thead>
            <Th>Clinic Id</Th>
            <Th>Name</Th>
            <Th>Enrollment Status</Th>
            <Th>Program</Th>
            <Th>Updated at</Th>
          </Thead>
          <Tbody>
            {data?.findManyInformPartnerProgramEnrollment.map((enrollment) => (
              <Tr key={enrollment.clinicId}>
                <Td>{enrollment.clinicId}</Td>
                <Td>{enrollment.clinic.name}</Td>
                <Td>{enrollment.status}</Td>
                <Td>{enrollment.informPartnerProgram.name}</Td>
                <Td>{new Date(enrollment.updatedAt).toLocaleString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </InformWrapper>
  );
};

export default Inform;

const InformWrapper = styled.div``;
const Title = styled.h1``;
const Table = styled.table``;
const Th = styled.th`
  padding: 10px;
`;
const Tr = styled.tr``;
const Td = styled.td`
  padding: 10px 20px;
  border-bottom: 1px dotted black;
`;
const Thead = styled.thead`
  padding: 10px;
`;
const Tbody = styled.tbody`
  padding: 10px;
`;
