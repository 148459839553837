import { useEffect } from "react";
import React, { useState } from "reactn";

import { useServiceSelector } from "./useServiceSelector";
import { useCareBenefit } from "./useCareBenefit";

const theme = {
  titleColor: "rgb(0, 11, 142)",
  textColor: "#575d7c",
};

export default function CareBenefitWithData({
  benefit: _benefit,
  client,
  ...props
}) {
  const [benefitId, setBenefitId] = useState("");
  useEffect(() => {
    setBenefitId(_benefit.id);
  }, [_benefit]);
  const careBenefit = useCareBenefit(client, benefitId);
  const serviceSelector = useServiceSelector(client, careBenefit);
  if (careBenefit.data) {
    return (
      <CareBenefit
        careBenefit={careBenefit}
        serviceSelector={serviceSelector}
        setBenefitId={setBenefitId}
        {...props}
      />
    );
  } else {
    return <div>Loading...</div>;
  }
}

function CareBenefit({
  onSave: _onSave,
  careBenefit,
  integrations,
  serviceSelector,
  setBenefitId,
}) {
  const id = careBenefit.data.id;
  const { title, serviceSearchInput, description, tangible, unitOfMeasure } =
    careBenefit.fields;

  const [pimsIntegrationId, setPimsIntegrationId] = useState(
    integrations[0].id
  );

  const {
    searchServices,
    availableServices,
    page,
    setPage,
    pageSize,
    total = 0,
    connectService,
    connectedServices,
    disconnectService,
  } = serviceSelector;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runSearch = () => {
    searchServices(pimsIntegrationId, serviceSearchInput.value, page);
  };

  useEffect(() => {
    runSearch();
  }, [page, runSearch]);

  const onSave = _onSave || (() => {});

  const save = async () => {
    const result = await onSave({
      id: id,
      title: title.value,
      description: description.value,
      tangible: tangible.value,
      usageUnitOfMeasure: unitOfMeasure.value,
    });
    if (result?.data?.createOneCareBenefit?.id) {
      setBenefitId(result?.data?.createOneCareBenefit?.id);
    }
  };

  return (
    <div style={{ padding: "0 15px" }}>
      <div>
        <h3>Basic Details</h3>
        <div>
          <div style={{ color: theme.titleColor, fontSize: 16 }}>Title:</div>
          <div>
            <input {...title} />
          </div>
          <br />
        </div>
        <div>
          <label>Tangible</label>
          <input
            type="checkbox"
            {...tangible}
            checked={tangible.value ? "checked" : ""}
          />
        </div>
        <div>
          <label>Unit of Measure</label>
          <select {...unitOfMeasure}>
            <option value="OCCURENCE">Occurence</option>
            <option value="CENTS">Cents</option>
          </select>
        </div>
        <br />
        <div>
          <label>Description:</label>
          <br />
          <textarea {...description} />
          <br />
        </div>
        <button
          onClick={() => {
            save();
          }}
        >
          Save
        </button>
      </div>
      {/* TODO: Split this into it's own component */}
      <div>
        <h3>Connected Services</h3>
        {id && (
          <>
            <h4>Relevant</h4>
            {connectedServices.map(({ service }) => {
              return (
                <div key={service.id}>
                  {service.name}
                  <br />
                  {service.code}
                  <button onClick={() => disconnectService(service.id)}>
                    Disconnect Service
                  </button>
                </div>
              );
            })}
            <h4>Browse</h4>
            <label>Integration</label>
            <select
              value={pimsIntegrationId}
              onChange={(e) => setPimsIntegrationId(e.target.value)}
            >
              {integrations.map((int) => (
                <option value={int.id}>
                  {int.type} - {int.id}
                </option>
              ))}
            </select>
            <br />
            <label>Search</label>
            <input type="text" {...serviceSearchInput} />
            <button onClick={() => runSearch()}>Search</button>
            {total > 0 ? (
              <>
                <b>
                  Showing {page * pageSize + 1} -{" "}
                  {Math.min((page + 1) * pageSize, total)} of {total}
                </b>
                <button
                  onClick={() => setPage((prev) => Math.max(0, prev - 1))}
                >
                  Previous
                </button>
                <button
                  onClick={() =>
                    setPage((prev) =>
                      Math.min(prev + 1, Math.floor(total / pageSize))
                    )
                  }
                >
                  Next
                </button>
              </>
            ) : (
              <b>No matching services</b>
            )}
            <div style={{ padding: 15 }}>
              {availableServices.map((service) => (
                <div style={{ marginBottom: 15 }}>
                  <b>{service.name}</b>
                  <br />
                  {service.code}
                  <br />
                  <button onClick={() => connectService(service.id)}>
                    Connect to benefit
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
