import React from "react";
import PropTypes from "prop-types";
import SnackbarBucket from "./SnackbarBucket";

const Snackbars = ({ children, snackbars, removeSnackbar }) => {
  return (
    <>
      <SnackbarBucket snackbars={snackbars} removeSnackbar={removeSnackbar} />
      {children}
    </>
  );
};

Snackbars.propTypes = {
  children: PropTypes.node,
  snackbars: PropTypes.array.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
};

export default Snackbars;
