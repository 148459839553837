import { useEffect } from "react";
import React, { useState } from "reactn";
import { TwitterPicker as ColorPicker } from "react-color";
import { careThemeColors } from "../colors";
export default function CarePlan({ onSave: _onSave, plan, benefits }) {
  console.log("plan", plan);
  const stripeEnv = process.env.REACT_APP_ENV !== "production" ? "test/" : "";
  const baseStripeUrl = `https://dashboard.stripe.com/${stripeEnv}`;

  const id = plan?.id || "";
  const title = useInput(plan?.title || "");
  const description = useInput(plan?.description || "");
  const published = useInput(plan?.published || false);
  const stripePriceId = useInput(plan?.stripePriceId || "");
  const pricePerRenewal = useInput(plan?.pricePerRenewal || 0);
  const billingCycle = useInput(plan?.billingCycle || "Monthly");
  const renewalCycle = useInput(plan?.renewalCycle || "Monthly");
  const targetSpecies = useInput(plan?.targetSpecies || null);
  const earliestTargetPetAgeInMonths = useInput(
    plan?.earliestTargetPetAgeInMonths || null
  );
  const latestTargetPetAgeInMonths = useInput(
    plan?.latestTargetPetAgeInMonths || null
  );

  const [activePlanBenefits, setActivePlanBenefits] = useState([]);
  console.log("activePlanBenefits", activePlanBenefits);
  const [themeColor, setThemeColor] = useState(null);

  useEffect(() => {
    setActivePlanBenefits(plan.planBenefits || []);
    title.setValue(plan?.title || "");
    description.setValue(plan?.description || "");
    published.setValue(plan?.published || "");
    stripePriceId.setValue(plan?.stripePriceId || "");
    setThemeColor(plan.themeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, benefits]);

  const onSave = _onSave || (() => {});
  const save = () => {
    console.log(earliestTargetPetAgeInMonths, latestTargetPetAgeInMonths);
    onSave({
      id: id,
      title: title.value,
      description: description.value,
      published: published.value || false,
      stripePriceId: stripePriceId.value,
      pricePerRenewal: +pricePerRenewal.value || 0,
      billingCycle: billingCycle.value,
      renewalCycle: renewalCycle.value,
      earliestTargetPetAgeInMonths: earliestTargetPetAgeInMonths.value
        ? +earliestTargetPetAgeInMonths.value
        : null,
      latestTargetPetAgeInMonths: latestTargetPetAgeInMonths.value
        ? +latestTargetPetAgeInMonths.value
        : null,
      targetSpecies:
        targetSpecies.value === "none" ? null : targetSpecies.value,
      activePlanBenefits,
      themeColor,
    });
  };

  const swapOrder = (idx1, idx2) => {
    setActivePlanBenefits((bs) => {
      if (idx1 < 0 || idx1 >= bs.length || idx2 < 0 || idx2 >= bs.length) {
        return bs;
      }

      const newBs = [...bs];
      const temp = newBs[idx1];
      newBs[idx1] = newBs[idx2];
      newBs[idx2] = temp;
      return newBs;
    });
  };
  const addPlanBenefit = (benefit) => {
    setActivePlanBenefits((prior) => [
      ...prior,
      {
        planId: plan.id,
        includedUses: benefit.tangible ? 0 : null,
        benefit: benefit,
      },
    ]);
  };
  const removePlanBenefit = (index) => {
    setActivePlanBenefits((items) => items.filter((_, idx) => idx !== index));
  };
  const otherBenefits = benefits.filter(
    (benefit) => !activePlanBenefits.find((b) => b.benefit.id === benefit.id)
  );
  const planBenefits = activePlanBenefits;
  const updateIncludedUses = (index, value) => {
    setActivePlanBenefits((prev) => {
      return prev.map((b, _idx) => {
        if (index === _idx) {
          return { ...b, includedUses: value };
        } else {
          return b;
        }
      });
    });
  };
  const updateProjectedSavings = (index, value) => {
    setActivePlanBenefits((prev) => {
      return prev.map((b, _idx) => {
        if (index === _idx) {
          return { ...b, projectedSavings: value };
        } else {
          return b;
        }
      });
    });
  };

  return (
    <div style={{ background: "#DDDDFF", minWidth: 300, minHeight: 300 }}>
      <div>
        <label>Title</label>
        <input {...title} />
      </div>
      <div>
        <label>Description</label>
        <textarea {...description} />
      </div>
      <div>
        <label>Published</label>
        <input type="checkbox" {...published} checked={published.value} />
      </div>
      <div>
        <label>Earliest Target Pet Age in Months</label>
        <input type="number" {...earliestTargetPetAgeInMonths} />
      </div>
      <div>
        <label>Latest Target Pet Age in Months</label>
        <input type="number" {...latestTargetPetAgeInMonths} />
      </div>
      <div>
        <label>Stripe Price Id - (Copy over from the Stripe Dashboard)</label>
        <input {...stripePriceId} />
        {!plan.stripePriceId ? (
          <a
            href="https://dashboard.stripe.com/test/products/create"
            target="_blank"
            rel="noopener noreferrer"
          >
            Create
          </a>
        ) : (
          <a
            href={`${baseStripeUrl}prices/${plan.stripePriceId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Update
          </a>
        )}
      </div>
      <div>
        <label>Price Per Renewal (in cents)</label>
        <input {...pricePerRenewal} type="number" />
      </div>
      <div>
        <label>Billing Cycle</label>
        <select {...billingCycle}>
          <option value="Monthly">Monthly</option>
          <option value="Semianually">Semianually</option>
          <option value="Annually">Annually</option>
        </select>
      </div>
      <div>
        <label>Renewal Cycle</label>
        <select {...renewalCycle}>
          <option value="Monthly">Monthly</option>
          <option value="Semianually">Semianually</option>
          <option value="Annually">Annually</option>
        </select>
      </div>
      <div>
        <label>Target Species</label>
        <select {...targetSpecies}>
          <option value="none">All</option>
          <option value="Feline">Feline</option>
          <option value="Canine">Canine</option>
          <option value="Avian">Avian</option>
          <option value="Bovine">Bovine</option>
          <option value="Equine">Equine</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div>
        <div
          style={{
            width: 50,
            height: 50,
            border: "1px solid black",
            backgroundColor: themeColor,
            marginBottom: "5px",
          }}
        />
        <ColorPicker
          colors={careThemeColors}
          color={themeColor || "#FFF"}
          onChange={(color) => {
            setThemeColor(color.hex);
          }}
        />
        <button onClick={() => setThemeColor(null)}>Clear</button>
      </div>

      <h3>Plan Benefits</h3>
      <div>
        {planBenefits.map((pb, idx) => {
          return (
            <div style={{ margin: 10 }}>
              {pb.benefit.title} -{" "}
              {pb.benefit.tangible ? (
                <>
                  <input
                    type="number"
                    onChange={(e) => {
                      updateIncludedUses(idx, e.target.value);
                    }}
                    value={pb.includedUses}
                  />
                  {pb.benefit.usageUnitOfMeasure === "OCCURENCE"
                    ? "Occurences"
                    : "Cents"}
                </>
              ) : (
                "included"
              )}
              <button onClick={() => swapOrder(idx, idx - 1)}>Move Up</button>
              <button onClick={() => swapOrder(idx, idx + 1)}>Move Down</button>
              <button onClick={() => removePlanBenefit(idx)}>Remove</button>
              <div>
                Projected Savings{" "}
                <input
                  type="string"
                  onChange={(e) => {
                    updateProjectedSavings(
                      idx,
                      e.target.value.trim() === "" ? null : e.target.value
                    );
                  }}
                  value={pb.projectedSavings}
                />
              </div>
            </div>
          );
        })}
      </div>
      <h3>Available</h3>
      {otherBenefits.map((benefit) => {
        return (
          <div>
            {benefit.title} - {benefit.id}
            <button
              onClick={() => {
                addPlanBenefit(benefit);
              }}
            >
              Add
            </button>
          </div>
        );
      })}
      <button
        onClick={() => {
          save();
        }}
      >
        Save
      </button>
    </div>
  );
}

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setValue((value) => !value);
    } else {
      setValue(event.target.value);
    }
  };
  return {
    value,
    setValue,
    onChange: handleChange,
  };
};
