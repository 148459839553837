import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SnackbarContent from "./SnackbarContent";
import SnackbarCloseButton from "./SnackbarCloseButton";

const Snackbar = ({
  children,
  id,
  type = "info",
  timeoutMs = 1500,
  removeSnackbar,
}) => {
  useEffect(() => {
    let timeout;

    if (timeoutMs) {
      timeout = setTimeout(() => removeSnackbar(id), timeoutMs);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeoutMs, removeSnackbar, id]);

  return (
    <SnackbarContent role="alert" type={type}>
      {children}
      <SnackbarCloseButton onClick={() => removeSnackbar(id)}>
        &times;
      </SnackbarCloseButton>
    </SnackbarContent>
  );
};

Snackbar.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
  timeoutMs: PropTypes.number,
};

export default Snackbar;
