import moment from "moment";
function mapFinancialConsults(consult) {
  formatDates({
    dateKeys: [
      "resolvedAt",
      "createdAt",
      "payment_createdAt",
      "payment_settlementDate",
      "payment_disbursementDate",
    ],
    consult,
  });
  return consult;
}
function formatGridDate({ value }) {
  return formatDate(value);
}
function formatDate(date) {
  if (!date || date === "N/A") return date;
  return moment(date).format("MM-DD-YYYY hh:mm a");
}
function formatDates({ dateKeys, consult }) {
  dateKeys.forEach((key) => {
    consult[key] = formatDate(consult[key]);
  });
}
export { mapFinancialConsults, formatDate, formatGridDate };
