import React, { useGlobal } from "reactn";
import { useHistory } from "react-router-dom";
import {
  useClinicEmployeeLazyQuery,
  TOGGLE_DEFAULT_EXPORTER,
} from "./clinicEmployeeGraphQL";

import { FaCode } from "react-icons/fa";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import { useMutation } from "@apollo/react-hooks";
import { useEffect } from "react";

const ClinicEmployees = () => {
  let queryVariables = { orderBy: { firstName: "asc" }, where: {}, take: 500 };
  const [, setJson] = useGlobal("json");
  const history = useHistory();
  const [toggleDefaultExporter] = useMutation(TOGGLE_DEFAULT_EXPORTER, {
    errorPolicy: "all",
  });

  useEffect(() => {
    sessionStorage.removeItem('clinic')
  }, [])

  const handleRadio = async (clinicEmployeeId, { target }) => {
    await toggleDefaultExporter({
      variables: {
        whereOne: {
          id: clinicEmployeeId,
        },
        whereAll: {
          isDefaultExporter: {
            equals: true,
          },
          clinic: {
            id: {
              equals: target.name,
            },
          },
        },
        data: {
          isDefaultExporter: true, // cannot set a single exporter to "false" without a replacement
        },
      },
    });
  };

  return (
    <PrismaServerFilterTable
      title="Clinic Employees"
      queryName="clinicEmployees"
      useQuery={useClinicEmployeeLazyQuery}
      queryVariables={queryVariables}
      filterConfigs={[
        { property: "id", display: "Clinic Employee Id" },
        { property: "clinicId", display: "Clinic Id" },
        { property: "clinic.name", display: "Clinic Name" },
        { property: "firstName", display: "First Name" },
        { property: "lastName", display: "Last Name" },
        {
          property: "isDefaultExporter",
          handlePropertyFilter: ({ filters, filterEdit }) => {
            filterEdit[filters.property] = true;
          },
          type: "select",
          options: [
            { value: "true", display: "True" },
            { value: "false", display: "False" },
          ],
          display: "Is Default Exporter",
        },
      ]}
      prismaVersion={2}
      columns={[
        {
          Header: "",
          hidefilter: true,
          blockSort: true,
          accessor: ({ data }) => {
            return (
              <div>
                <FaCode
                  color="#2ABA66"
                  size={20}
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  title="View Json"
                  onClick={() => {
                    setJson({ type: "clinicEmployees", data });
                    history.push("/json/view");
                  }}
                />
              </div>
            );
          },
        },
        {
          Header: "Id",
          accessor: ({ data }) => {
            if (data.id.length <= 0 || data.id === null) {
              return <em style={{ opacity: ".5" }}>none</em>;
            } else {
              return data.id;
            }
          },
        },
        {
          Header: "Status",
          accessor: ({ data: { isActive, isDeleted, isVisible } }) => {
            if (isActive && !isDeleted && isVisible) {
              return <p>OK</p>;
            }
            return (
              <div>
                {!isActive ? (
                  <p
                    style={{ cursor: "pointer" }}
                    title="This employee is marked as 'isActive: false' in our database"
                  >
                    INACTIVE
                  </p>
                ) : (
                  ""
                )}
                {isDeleted ? (
                  <p
                    style={{ cursor: "pointer" }}
                    title="This employee is marked as 'deleted' in our database"
                  >
                    DELETED
                  </p>
                ) : (
                  ""
                )}
                {!isVisible ? (
                  <p
                    style={{ cursor: "pointer" }}
                    title="This employee is marked as 'isVisible: false' in our database"
                  >
                    INVISIBLE
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
        {
          Header: "Firstname",
          accessor: ({ data }) => {
            if (data.firstName.length <= 0 || data.firstName === null) {
              return <em style={{ opacity: ".5" }}>none</em>;
            } else {
              return data.firstName;
            }
          },
        },
        { Header: "Lastname", accessor: "lastName" },
        {
          Header: "Type",
          accessor: ({ data }) => {
            if (data.type === "CalendarResource") {
              return <em style={{ color: "red" }}>{data.type}</em>;
            } else {
              return data.type;
            }
          },
        },
        {
          Header: "Clinic Name",
          accessor: ({ data: { clinic } }) => clinic?.name ?? "",
        },
        {
          Header: "Clinic Id",
          accessor: ({ data: { clinic } }) => (clinic ? clinic.id : ""),
        },
        {
          Header: "Default Exporter",
          accessor: ({ data }) => {
            const isCR = data.type === "CalendarResource";
            return (
              <span>
                <input
                  key={data.id + "_key"}
                  type="radio"
                  name={data?.clinic?.id}
                  title={
                    isCR ? "Cannot use Calendar Resources as Exporters" : null
                  }
                  data-dex={"blank"}
                  disabled={isCR || !data.isActive || data.isDeleted}
                  onClick={(event) => handleRadio(data.id, event)}
                  defaultChecked={data.isDefaultExporter}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    cursor:
                      !isCR && data.isActive && !data.isDeleted
                        ? "pointer"
                        : "",
                  }}
                />
              </span>
            );
          },
        },
      ]}
    />
  );
};

export default ClinicEmployees;
