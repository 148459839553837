

const handleCheckbox = async (mutation, whereClause, field, { target }) => {

  const isElementNowChecked = target.attributes.type.ownerElement.checked;

  console.log(whereClause, isElementNowChecked);

  await mutation({
    variables: {
      where: whereClause,
      data: {
        [field]: isElementNowChecked,
      },
    },
  }).catch((err) => console.log(err));
};

export default handleCheckbox;
