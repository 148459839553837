import styled from "styled-components/macro";
const Input = styled.input`
  width: 100%;
  height: 32px;
  padding-left: 8px;
  font-size: 16px;
  display: block;
  border: 1px solid #d4d4df;
  border-radius: 3px;
  box-shadow: none;
  :focus {
    outline: #3c3b3d;
    border: 1px solid #2aba66;
  }
  :disabled {
    background-color: #e6e9ed;
  }
`;
const Select = styled.select`
  width: 100%;
  height: 36px;
  padding-left: 8px;
  font-size: 16px;
  display: block;
  border: 1px solid #3c3b3d;
  border-radius: 3px;

  box-shadow: none;
  :focus {
    outline: #3c3b3d;
    border: 1px solid #2aba66;
  }
`;
const ButtonGroup = styled.div`
  :after {
    content: "";
    clear: both;
    display: table;
  }
`;
const Button = styled.button`
  background-color: #46475a;
  color: #fefefe;
  padding: 10px 24px;
  border: 1px solid #3c3b3d;
  cursor: pointer;
  float: left;
  :not(:last-child) {
    border-right: none; /* Prevent double borders */
  }
  :hover {
    background-color: #3c3b3d;
  }
  :disabled {
    background-color: #656d78 !important;
  }
  &.active {
    background-color: #4caf50;
    :hover {
      background-color: #2aba66;
    }
  }
  :focus {
    outline: 0;
  }
`;

const SaveButton = styled.button`
  margin: 4px;
  padding: 12px 24px;
  font-size: 14px;
  color: #f5f7fa;
  cursor: pointer;
  background-color: #2aba66;
  border-radius: 4px;
  :disabled {
    background-color: #656d78;
  }
`;

const ClickLink = styled.a`
  font-size: 16px;
  color: #444559;
  cursor: pointer;
  margin-top: -10px;
`;
const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  display: block;
  border: 1px solid #3c3b3d;
  border-radius: 3px;
  box-shadow: none;
  resize: vertical;

  :focus {
    outline: #3c3b3d;
    border: 1px solid #2aba66;
  }
  :disabled {
    background-color: #e6e9ed;
  }
`;
const Form = styled.form``;

const InputLeftAddon = styled.inputLeftAddon;
export {
  Input,
  SaveButton,
  Select,
  ClickLink,
  ButtonGroup,
  Button,
  TextArea,
  Form,
  InputLeftAddon,
};
