import React, { useGlobal } from "reactn";
import { Row, CA, C1 } from "../../styledComponents/grid";
import { Input, SaveButton } from "../../styledComponents/form";
import Container from "../../styledComponents/container";
import { useHistory } from "react-router-dom";
import logo from "../../images/otto.png";
import firebase from "../../api/firebase";
var qs = require("qs");

export default function SignIn({ location: { search } }) {
  const history = useHistory();
  const [loginUser, setLoginUser] = useGlobal("loginUser");
  let [, setToken] = useGlobal("token");
  const [, setLoading] = useGlobal("loading");
  const [, setSnackbars] = useGlobal("snackbars");
  const previous = qs.parse(search || "")["?previous"];
  function setState(prop) {
    return (eve) => {
      let obj = { ...loginUser };
      obj[prop] = eve.target.value;
      setLoginUser(obj);
    };
  }
  async function executeLogin() {
    const { email, password } = { ...loginUser };
    setLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      const token = await firebase.auth().currentUser.getIdToken();
      if (!token) {
        setLoading(false);
        return;
      }
      setToken(token);
      history.push(previous || "/");
    } catch ({ message }) {
      setSnackbars([
        {
          id: Date.now(),
          type: "error",
          message,
        },
      ]);
    }
    setLoading(false);
  }

  return (
    <Container style={{ maxWidth: 640 }}>
      <form>
      <Row>
        <C1 />
        <CA>
          <img src={logo} style={{ height: 148 }} alt="Logo" />
        </CA>
        <C1 />
      </Row>

      <Row>
        <C1>
          <Row>
            <label>Email</label> <br />
          </Row>
          <Input onChange={setState("email")} value={loginUser.email} />
        </C1>
      </Row>

      <Row>
        <C1>
          <Row>
            <label>Password</label>
          </Row>
          <Input
            onChange={setState("password")}
            type="password"
            value={loginUser.password}
          />
        </C1>
      </Row>
      <Row>
        <C1 />
        <CA>
          <SaveButton
            onClick={executeLogin}
            disabled={!(loginUser.email && loginUser.password)}
          >
            Login
          </SaveButton>
        </CA>
      </Row>
      </form>
    </Container>
  );
}
