import React from "reactn";
import FormComponent from "../../app/form";
import { useGenericMutation } from "../../app/genericMutations";
import { useHistory } from "react-router-dom";
import { useOneClinicQuery } from "./clinicGraphQL";
import usStates from "../../../api/usStates";
export default function ClinicForm({
  match: {
    params: { id },
  },
}) {
  const [update] = useGenericMutation({ type: "update", entity: "Clinic" });
  const history = useHistory();
  const useQuery = () => {
    return useOneClinicQuery({
      variables: {
        where: {
          id: {
            equals: id,
          },
        },
      },
    });
  };

  async function handleUpdate(state) {
    await update({
      variables: { data: { ...state, id: undefined }, where: { id } },
    });
    history.push("/");
  }
  return (
    <>
      <FormComponent
        onCreate={() => {}}
        onUpdate={handleUpdate}
        useQuery={useQuery}
        onCancel={() => history.push(`/`)}
        queryName="getClinicInfo"
        hideSave={true}
        hideCancel={true}
        id={id}
        title="Clinic"
        fields={[
          [
            { display: "Name", property: "name", required: true },
            { property: "phone" },
          ],
          [
            { display: "Address", property: "addressLine1" },
            { display: "Address 2", property: "addressLine2" },
          ],
          [{ display: "Full Address", property: "fullAddress" }],
          [
            { property: "city" },
            {
              property: "state",
              type: "select",
              options: usStates.map(({ name, abbreviation }) => ({
                key: abbreviation,
                display: `${abbreviation} - ${name}`,
              })),
            },
          ],
          [
            {
              property: "timezone",
              type: "select",
              options: [
                { name: "Atlantic Standard Time", offset: 4 },
                { name: "Eastern Standard Time", offset: 5 },
                { name: "Central Standard Time", offset: 6 },
                { name: "Mountain Standard Time", offset: 7 },
                { name: "Pacific Standard Time", offset: 8 },
                { name: "Alaska Standard Time", offset: 9 },
              ].map((item, i) => ({
                key: item.offset,
                display: item.name,
              })),
            },
          ],
        ]}
      />
    </>
  );
}
