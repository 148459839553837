import { useQuery } from "@apollo/react-hooks";
import { useState, useMemo } from "reactn";
import gql from "graphql-tag";
import { useEffect } from "react";
export const useCareBenefit = (client, benefitId, isNew) => {
  const {
    loading,
    data: _data,
    refetch,
  } = useQuery(
    gql`
      query getCareBenefit($id: String!) {
        findUniqueCareBenefit(where: { id: $id }) {
          id
          title
          description
          tangible
          usageUnitOfMeasure
          organizationCareBenefitToClinicService {
            service {
              id
              name
              code
            }
          }
        }
      }
    `,
    {
      client,
      variables: {
        id: benefitId,
      },
    }
  );
  const backup = useMemo(
    () => ({
      organizationCareBenefitToClinicService: [],
    }),
    []
  );
  const data = benefitId ? _data?.findUniqueCareBenefit : backup;

  const id = benefitId;
  const title = useInput("");
  const serviceSearchInput = useInput("Rabies");
  const description = useInput("");
  const tangible = useInput(true);
  const unitOfMeasure = useInput("OCCURENCE");

  useEffect(() => {
    if (_data) {
      title.setValue(data?.title);
      description.setValue(data?.description);
      tangible.setValue(data?.tangible ?? true);
      unitOfMeasure.setValue(data?.usageUnitOfMeasure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    loading,
    id,
    data,
    fields: {
      title,
      description,
      tangible,
      unitOfMeasure,

      serviceSearchInput,
    },
    refetch,
  };
};

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setValue((value) => !value);
    } else {
      setValue(event.target.value);
    }
  };
  return {
    value,
    setValue,
    onChange: handleChange,
  };
};
