import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const TRANSACTIONS = gql`
  query transactions(
    $where: TransactionWhereInput
    $orderBy: [TransactionOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $take: Int
  ) {
    findManyTransaction: findManyTransaction(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      transactionId
      createdAt
      consultation {
        firebaseId
        id
        resolvedAt
        clinic {
          name
          corporateId
          organization {
            name
          }
        }
        vet {
          user {
            firstName
            lastName
          }
        }
        nurse {
          user {
            firstName
            lastName
          }
        }
        petParent {
          user {
            firstName
            lastName
          }
        }
        summary
      }
      status
      settlementDate
      disbursementDate
      amount
      amountForSettlement
      serviceFeeAmount
      cardType
      cardLast4
      amountSettled
      clinicEarnings
    }
    findManyTransactionCount: findManyTransactionCount(where: $where)
  }
`;

const useTransactionsQuery = () => {
  const [loadTransactions, { called, loading, data }] = useLazyQuery(
    TRANSACTIONS,
    {
      fetchPolicy: "network-only",
    }
  );
  return [loadTransactions, { called, loading, data }];
};

export { useTransactionsQuery };
