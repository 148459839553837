import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const CLINIC_PET_PARENT_HISTORY = gql`
  query getClinicPetParentHistory($where: GetEntityHistoryInput!) {
    getClinicPetParentHistory(where: $where) {
      data
    }
  }
`;

function useClinicPetParentHistory() {
  const [getClinicPetParentHistory, { called, loading, data }] = useLazyQuery(
    CLINIC_PET_PARENT_HISTORY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return [getClinicPetParentHistory, { called, loading, data }];
}
export { useClinicPetParentHistory, CLINIC_PET_PARENT_HISTORY };
