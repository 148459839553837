import gql from "graphql-tag";

const DASHBOARD = gql`
  query getAdminDashboards {
    getQuickSightAdminDashboards {
      Name
      PublishedVersionNumber
      DashboardId
    }
  }
`;

const EMBED_URL = gql`
query getQuickSightEmbedURL($where: getQuickSightEmbedURLInput!) {
  getQuickSightEmbedURL(where: $where) {
    Status
    EmbedUrl
    RequestId
  }
}
`

export { DASHBOARD, EMBED_URL };
