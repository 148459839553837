/* eslint-disable no-unused-expressions */
import React, { memo, useEffect, useRef } from "react";

const QuicksightReportIFrame = () => {
  const containerRef = useRef(null);

  console.log("Rendering QuicksightReportIFrame");
  const quickSightContainer = window.quickSightContainer;
  useEffect(() => {
    if (quickSightContainer) {
      containerRef.current.replaceWith(quickSightContainer);
    }

    return () => {
      window.quickSightContainer.innerHTML = "";
    };
  }, [quickSightContainer]);

  return (
    <div style={{ justifyContent: "center", height: "100%" }}>
      <div ref={containerRef} />
    </div>
  );
};
export default memo(QuicksightReportIFrame);
