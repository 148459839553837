/* eslint-disable react/jsx-no-target-blank */
import React from "reactn";
import styled from "styled-components/macro";

const stripeEnv = process.env.REACT_APP_ENV !== "production" ? "test/" : "";
const baseStripeUrl = `https://dashboard.stripe.com/${stripeEnv}`;

export default function PlanDetail(props) {
  const { plan, setActivePlan } = props;

  return (
    <Container key={plan.id}>
      <PlanTitle plan={plan}>{plan.title}</PlanTitle>-{" "}
      {plan.published ? "published" : "unpublished"} -{" "}
      {plan.description.slice(0, 100)}{" "}
      <button
        onClick={() => {
          setActivePlan(plan);
        }}
      >
        Update
      </button>
      {/* <button onClick={ () => { deletePlan(plan.id)}}>Delete</button> */}
    </Container>
  );
}

const PlanTitle = (props) => {
  const { plan } = props;

  if (!!plan.stripePriceId) {
    const stripePriceUrl = `${baseStripeUrl}prices/${plan.stripePriceId}`;
    // eslint-disable-next-line react/jsx-no-target-blank
    return (
      <b>
        <a href={stripePriceUrl} target="_blank">
          {props.children}
        </a>
      </b>
    );
  } else {
    return <b>{props.children}</b>;
  }
};

const Container = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  margin: 5px;
`;
