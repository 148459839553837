/* eslint-disable no-unused-vars */
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState, useEffect, useGlobal } from "reactn";
import BenefitDetail from "./details/benefitDetail";
import { useBenefitModal } from "./hooks/useBenefitModal";
import { useCarePlanModal } from "./hooks/useCarePlanModal";
import { useCareAddonModal } from "./hooks/useCareAddonModal";
import _ from "lodash";
import PlanDetail from "./details/planDetail";
import AddonDetail from "./details/addonDetail";
import { useCareData } from "./query/useCareData";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { OrganizationCareProvider } from "./organizationCareProvider";
import { OrganizationBenefits } from "./components/OrganizationBenefits";
import { OrganizationOfferings } from "./components/OrganizationOfferings";

export default function OrganizationCare(props) {
  const client = useApolloClient();
  const stripeEnv = process.env.REACT_APP_ENV !== "production" ? "test/" : "";
  const baseStripeUrl = `https://dashboard.stripe.com/${stripeEnv}`;
  const { data, loading, refetch } = useCareData(props.match.params.id);

  const [activeBenefit, setActiveBenefit] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [activeAddon, setActiveAddon] = useState(null);
  const [deleteCareBenefit] = useMutation(gql`
    mutation deleteCareBenefit($id: String!) {
      deleteOneCareBenefit(where: { id: $id }) {
        id
      }
    }
  `);

  const [deleteCareAddon] = useMutation(gql`
    mutation deleteCareAddon($id: String!) {
      deleteOneCareAddonPackage(where: { id: $id }) {
        id
      }
    }
  `);
  const [deleteCareAddonBenefits] = useMutation(gql`
    mutation deleteCareAddonBenefits($id: String!) {
      deleteManyCareAddonPackageBenefit(
        where: { addOnPackageId: { equals: $id } }
      ) {
        count
      }
    }
  `);

  const { open: openBenefitModal } = useBenefitModal(
    data?.findUniqueOrganization?.id,
    activeBenefit,
    data?.findUniqueOrganization?.paymentProcessorClinic?.integrations || [],
    () => {
      setActiveBenefit(null);
      refetch();
    },
    client
  );
  const { open: openPlanModal } = useCarePlanModal(
    data?.findUniqueOrganization?.id,
    activePlan,
    data?.findUniqueOrganization?.careBenefits,
    () => {
      setActivePlan(null);
      refetch();
    }
  );

  const { open: openAddonModal } = useCareAddonModal(
    data?.findUniqueOrganization?.id,
    activeAddon,
    data?.findUniqueOrganization?.careBenefits,
    () => {
      setActiveAddon(null);
      refetch();
    }
  );

  const deleteBenefit = async (id) => {
    if (window.confirm("Are you sure you want to delete this benefit?")) {
      await deleteCareBenefit({ variables: { id } });
      refetch();
    }
  };

  const deleteAddon = async (id) => {
    if (window.confirm("Are you sure you want to delete this addon?")) {
      await deleteCareAddonBenefits({ variables: { id } });
      await deleteCareAddon({ variables: { id } });
      refetch();
    }
  };

  const [_deletePlan] = useMutation(gql`
    mutation deleteCarePlan($id: String!) {
      deleteOneCarePlan(where: { id: $id }) {
        id
      }
    }
  `);
  const [_deletePlanBenefits] = useMutation(gql`
    mutation deleteManyCarePlanBenefit($id: String!) {
      deleteManyCarePlanBenefit(where: { planId: { equals: $id } }) {
        count
      }
    }
  `);
  const deletePlan = async (id) => {
    await _deletePlanBenefits({
      variables: {
        id,
      },
    });
    await _deletePlan({
      variables: {
        id,
      },
    });
    refetch();
  };

  const [showBenefits, setShowBenefits] = useState(true);

  // useEffect(() => {

  // },[])

  const { path, url } = useRouteMatch();
  if (!data) {
    return <div></div>;
  }
  const orgId = props.match.params.id;
  const {
    name: orgName,
    careBenefits,
    carePlans,
    careAddonPackage: careAddons,
  } = data.findUniqueOrganization;
  console.log(path);
  return (
    // <OrganizationCareProvider orgId={orgId}>
    //   <div>
    //     Warning, this tool is still VERY work in progress
    //     <h1>
    //       {orgName} - {orgId}
    //     </h1>
    //     <div>
    //       <div>
    //         <Link to={`/organizations/${orgId}/care`}>Main</Link>
    //       </div>
    //       <div>
    //         <Link to={`/organizations/${orgId}/care/benefits`}>Benefits</Link>
    //       </div>
    //       <div>
    //         <Link to={`/organizations/${orgId}/care/plans`}>Plans</Link>
    //       </div>
    //       <div>
    //         <Link to={`/organizations/${orgId}/care/addons`}>addons</Link>
    //       </div>
    //     </div>
    //     <Route path={`/organizations/:id/care/benefits`}>
    //       <div>
    //         <OrganizationBenefits />
    //       </div>
    //     </Route>
    //     <Route path={`/organizations/:id/care/plans`}>
    //       <OrganizationOfferings plans />
    //     </Route>
    //     <Route path={`/organizations/:id/care/addons`}>
    //       <OrganizationOfferings addons />
    //     </Route>
    //     <Route exact path="/organizations/:id/care">
    //       <h2>
    //         Benefits -{" "}
    //         <button onClick={() => setShowBenefits((b) => !b)}>
    //           {showBenefits ? "Hide" : "Show"}
    //         </button>
    //       </h2>
    //       {showBenefits && (
    //         <div>
    //           <button onClick={() => setActiveBenefit({})}>Add New</button>
    //           {careBenefits.map((cb) => {
    //             return (
    //               <BenefitDetail
    //                 key={cb.id}
    //                 benefit={cb}
    //                 setActiveBenefit={setActiveBenefit}
    //                 deleteBenefit={deleteBenefit}
    //               />
    //             );
    //           })}
    //         </div>
    //       )}
    //       <h2>Plans</h2>
    //       <div>
    //         <button onClick={() => setActivePlan({})}>Add New</button>
    //         {_.sortBy(carePlans, (cp) => cp.title.toLowerCase()).map((plan) => {
    //           return (
    //             <PlanDetail
    //               key={plan.id}
    //               plan={plan}
    //               setActivePlan={setActivePlan}
    //             />
    //           );
    //         })}
    //       </div>
    //       <h2>Addons</h2>
    //       <div>
    //         <button onClick={() => setActiveAddon({})}>Add New</button>
    //         {_.sortBy(careAddons, (ca) => ca.title.toLowerCase()).map(
    //           (addon) => {
    //             return (
    //               <AddonDetail
    //                 key={addon.id}
    //                 addon={addon}
    //                 setActiveAddon={setActiveAddon}
    //               />
    //             );
    //           }
    //         )}
    //       </div>
    //     </Route>
    //   </div>
    // </OrganizationCareProvider>
    <>
      This Page is Under Construction. Until it is fixed, please reach out to
      Careousel devs to make any plan changes.
    </>
  );
}
