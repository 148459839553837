import React, { useGlobal, useRef, useEffect } from "reactn";
import styled from "styled-components/macro";
import { List, ListItem, ListItemHeader } from "../../styledComponents/list";
import { useHistory } from "react-router-dom";

import {
  FaSitemap,
  FaSignOutAlt,
  FaLockOpen,
  FaUser,
  FaClinicMedical,
  FaClipboard,
  FaDog,
  FaDollarSign,
  FaFlag,
  FaBell,
  FaBriefcaseMedical,
  FaKeyboard,
  FaHeart,
  FaChartBar,
  FaRobot,
  FaHome,
  FaHistory,
} from "react-icons/fa";
const DrawerWrapper = styled.div`
  height: calc(100% - 102px);
  position: absolute;
  top: 68px;
  padding: 16px;
  width: 240px;
  background-color: #fefefe;
  border: 1px solid #dfdfdf;
  z-index: 999999;
`;
function useOutsideAlerter(ref) {
  const [, setShow] = useGlobal("drawerOpen");

  function handleClickOutside(event) {
    if (event.target.tagName === "svg") return;
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}
export default function Drawer() {
  const wrapperRef = useRef(null);

  const [show, setShow] = useGlobal("drawerOpen");
  const [, setUser] = useGlobal("user");
  const [token, setToken] = useGlobal("token");
  const history = useHistory();
  useOutsideAlerter(wrapperRef);
  function logout() {
    setUser({});
    setToken("");
    setShow(false);
    history.push("/signin");
  }
  function goto(route) {
    return () => {
      setShow(false);
      history.push(route);
    };
  }

  return show ? (
    <DrawerWrapper ref={wrapperRef}>
      <List>
        {token ? (
          <div>
            <ListItem onClick={goto("/")}>
              <ListItemHeader>
                <FaClinicMedical size={20} /> Clinics
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/organizations")}>
              <ListItemHeader>
                <FaSitemap size={20} /> Organizations
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/enrollments")}>
              <ListItemHeader>
                <FaHeart size={20} /> Enrollments
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/transactions")}>
              <ListItemHeader>
                <FaDollarSign size={20} /> Financial Transactions
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/consultations")}>
              <ListItemHeader>
                <FaClipboard size={20} /> Consultations
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/users")}>
              <ListItemHeader>
                <FaUser size={20} /> Users
              </ListItemHeader>
            </ListItem>

            <ListItem onClick={goto("/clinicEmployees")}>
              <ListItemHeader>
                <FaBriefcaseMedical size={20} /> Clinic Employees
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/petParents")}>
              <ListItemHeader>
                <FaUser size={20} /> Pet Parents
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/pets")}>
              <ListItemHeader>
                <FaDog size={20} /> Pets
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/reporting")}>
              <ListItemHeader>
                <FaChartBar size={20} /> Reporting
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/feature-flags")}>
              <ListItemHeader>
                <FaFlag size={20} /> Feature Flags
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/firebase/token")}>
              <ListItemHeader>
                <FaLockOpen size={20} /> Firebase Token
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/ui-refresh")}>
              <ListItemHeader>
                <FaBell size={20} /> UI Refresh
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/playground")}>
              <ListItemHeader>
                <FaKeyboard size={20} /> Core-Api Playground
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/hills-to-home")}>
              <ListItemHeader>
                <FaHome size={20} /> Hills to Home
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/ai")}>
              <ListItemHeader>
                <FaRobot size={20} /> AI
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={goto("/entity-history")}>
              <ListItemHeader>
                <FaHistory size={20} /> Entity history
              </ListItemHeader>
            </ListItem>
            <ListItem onClick={logout}>
              <ListItemHeader>
                <FaSignOutAlt size={20} /> Logout
              </ListItemHeader>
            </ListItem>
          </div>
        ) : null}
      </List>
    </DrawerWrapper>
  ) : null;
}
