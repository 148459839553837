import React from "react";
import styled from "styled-components/macro";
import { FaAngleLeft, FaAngleRight, FaFileExcel } from "react-icons/fa";
import { Row, CA } from "../../../styledComponents/grid";

const tableColor = "#e6e9ed";
const tableBorderColor = "#ccd1d9";
const TableWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 0px;
  right: 15px;
  bottom: 15px;
  margin-top: 2px;
  overflow-x: scroll;
`;
const Table = styled.table`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 53px;
  border-left: 1px solid ${tableBorderColor};
  background-color: ${tableColor};
  box-shadow: none;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 4px;
  overflow-y: scroll;
  width: 100%;
  display: block;
`;

const TableHeader = styled.thead`
  padding: 0;
  box-shadow: none;
`;
const TableRow = styled.tr`
  border: none;
  margin: 0;
  width: 100%;
`;
const Th = styled.td`
  margin: 0;
  padding: 12px;
  background-color: #e6e9ed;
  border-right: 1px solid ${tableBorderColor};
  border-bottom: 1px solid ${tableBorderColor};
  white-space: nowrap;
`;
const Td = styled.td`
  padding: 8px;
  border-inline-end: 1px solid ${tableBorderColor};
  border-bottom: 1px solid ${tableBorderColor};
  box-shadow: none;
  border-spacing: 0;
  min-width: 85px;
`;
const ThContent = styled.div``;
const TdContent = styled.div``;
const TableBody = styled.tbody`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f5f7fa;
  tr:nth-child(even) {
    background-color: #fefefe;
  }
  overflow-y: auto;
  display: inline-block;
  min-width: 100%;
`;
const TableFooter = styled.div`
  position: absolute;
  bottom: 0;
  background-color: #e6e9ed;
  border-bottom: 1px solid ${tableBorderColor};
  border-left: 1px solid ${tableBorderColor};
  border-right: 1px solid ${tableBorderColor};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: inline-block;
`;
const PaginateWrapper = styled.div`
  padding: 8px;
`;
const IconWrapper = styled.div`
  background-color: #fefefe;
  width: 32px;
  padding-top: 4px;
  border: 1px solid ${tableBorderColor};
`;
const InfoWrapper = styled.div`
  padding: 8px;
  color: #656d78;
`;
const Input = styled.input`
  height: 24px;
  padding: 6px;
  margin-top: 6px;
  width: 90%;
`;
const DatePickerWrapper = styled.div`
  input {
    height: 24px;
    padding: 6px;
    margin-top: 6px;
    width: 90%;
    border-width: 1px;
    border-color: #ccd1d9;
  }
`;
const Paginator = ({
  columns,
  interalData,
  renderedRows,
  page,
  handlePageLeft,
  handlePageRight,
  csvExport,
  total,
}) => {
  total = total || interalData.length;
  return (
    <PaginateWrapper>
      <Row>
        <CA>
          <IconWrapper onClick={handlePageLeft}>
            <FaAngleLeft size={24} color={page === 1 ? tableBorderColor : ""} />
          </IconWrapper>
        </CA>
        <CA>
          <IconWrapper onClick={handlePageRight}>
            <FaAngleRight
              size={24}
              color={page >= total / renderedRows ? tableBorderColor : ""}
            />
          </IconWrapper>
        </CA>

        {csvExport ? (
          <CA>
            <IconWrapper onClick={() => csvExport()}>
              <FaFileExcel size={24} color="green" title="Export" />
            </IconWrapper>
          </CA>
        ) : null}

        <CA>
          <InfoWrapper>
            {total ? (page - 1) * renderedRows + 1 : 0} -{" "}
            {renderedRows * page > total ? total : renderedRows * page} of{" "}
            {total}
          </InfoWrapper>
        </CA>
      </Row>
    </PaginateWrapper>
  );
};

export {
  Paginator,
  TableWrapper,
  Input,
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableRow,
  Td,
  TdContent,
  Th,
  ThContent,
  DatePickerWrapper,
};
