import React from "reactn";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import { useEnrollmentsQuery } from "./enrollmentGraphQL";

export const Enrollments = () => {
  return (
    <div>
      <PrismaServerFilterTable
        title="Enrollments (Active)"
        queryName="findManyCarePlanEnrollment"
        useQuery={useEnrollmentsQuery}
        prismaVersion={2}
        filterConfigs={[
          { property: "plan.title", display: "Plan Name" },
          { property: "organizationPet.preferredName", display: "Patient Name" },
          { property: "organizationPet.id", display: "Organization Pet Id" },
          { property: "careStripeSubscription.subscriptionOwner.preferredFirstName", display: "Client Name" },
          { property: "careStripeSubscription.associatedCarePlanProviderGroup.paymentProcessorClinic.name", display: "Clinic Name" },
          { property: "careStripeSubscription.associatedCarePlanProviderGroup.paymentProcessorClinic.id", display: "Payment Processor Clinic Id" },
          { property: "careStripeSubscription.subscriptionOwner.stripeCustomerTableId", display: "Stripe Customer Table Id" },
        ]}
        columns={[
          {
            Header: "#",
            accessor: ({data}) => data.numberDesc || '',
          },
          {
            Header: "Stripe Customer Table Id",
            accessor: ({data}) => data.careStripeSubscription?.subscriptionOwner?.stripeCustomerTableId || '',
          },
          {
            Header: "Client Name(s)",
            accessor: ({ data }) =>
              data.careStripeSubscription?.subscriptionOwner
                ? `${data.careStripeSubscription.subscriptionOwner.preferredFirstName} ${data.careStripeSubscription.subscriptionOwner.preferredLastName}`
                : "",
          },
          {
            Header: "Patient Name",
            accessor: ({ data }) => data.organizationPet.preferredName,
          },
          {
            Header: "Enrollment Start Time",
            accessor: ({data}) => {
              const date = new Date(data.createdAt);
              return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
            },
          },
          {
            Header: "Clinic Group",
            accessor: ({ data }) => data.careStripeSubscription?.associatedCarePlanProviderGroup?.paymentProcessorClinic?.name || '',
          },     
          {
            Header: "Plan Name",
            accessor: ({data}) => data.plan.title,
          },
          {
            Header: "Monthly Cost",
            accessor: ({data}) => data.monthly_cost,
          }
        ]}
      />
    </div>
  );
}
export default Enrollments;
