import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const invokeConverseAiRequestGraphQL = gql`
  query invokeConverseAiRequest($data: InvokeAiConverseInput!) {
    invokeConverseAiRequest(data: $data) {
      message
      role
    }
  }
`;

function useInvokeConverseAiRequestQuery() {
  const [invokeConverseAiRequest, { called, loading, data }] = useLazyQuery(
    invokeConverseAiRequestGraphQL,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return [invokeConverseAiRequest, { called, loading, data }];
}
export { useInvokeConverseAiRequestQuery, invokeConverseAiRequestGraphQL };
