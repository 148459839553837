import React from "reactn";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import { useOrganizationsQuery } from "./organizationGraphQL";
import { useHistory } from 'react-router-dom';
export default function Organizations() {
  const history = useHistory();
  function goto(route) {
    return () => {
      history.push(route);
    };
  }
  return (
    <div>
      <PrismaServerFilterTable
        title="Organizations"
        queryName="findManyOrganization"
        useQuery={useOrganizationsQuery}
        prismaVersion={2}
        filterConfigs={[{ property: "name", display: "Name" }]}
        queryVariables={{ orderBy: {name: "asc"} }}
        columns={[
          { Header: "Name", accessor: "name" },
          {
            Header: "Id",
            accessor: ({ data }) => (data.id),
          },
          {
            Header: "Clinics",
            accessor: ({ data }) => (data.clinics ? data.clinics.length : 0),
          },
          {
            Header: "Open Care Editor",
            accessor: ({data}) => <button onClick={goto(`/organizations/${data.id}/care`)}>View Care Data</button>
          }
        ]}
      />
    </div>
  );
}
