import React, { useEffect, useGlobal } from "reactn";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Transactions from "../screens/transactions";
import Home from "../screens/home";
import ClinicEmployee from "../screens/clinicEmployees";
import PaymentFeeConfigForm from "../screens/home/clinicPaymentFeeConfigForm";
import ClinicForm from "../screens/home/clinicForm";
import ClinicVetView from "../screens/home/clinicUserView";
import Users from "../screens/users";
import PetParents from "../screens/petParents/petParents";
import UserForm from "../screens/users/usersForm";
import Token from "../screens/showToken";
import SignIn from "../screens/signin";
import ManageRoles from "../screens/manageClinicRoles";
import Json from "../screens/viewJson";
import Organizations from "../screens/organizations";
import Consultations from "../screens/consultations";
import OrganizationsCare from "../screens/organizations/organizationCare";
import Pets from "../screens/pets";
import FeatureFlags from "../screens/FeatureFlags";
import Playground from "../screens/playground";
import ManageFeatureFlag from "../screens/FeatureFlags/components/ManageFeatureFlag";
import ManageClinicFeatureFlags from "../screens/FeatureFlags/components/ManageClinicFeatureFlags";
import ShowClinicFeatureFlags from "../screens/FeatureFlags/components/ShowClinicFeatureFlags";
import UIRefresh from "../screens/UIRefresh";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components/macro";
import Header from "./header";
import Drawer from "./drawer";
import firebase from "../../api/firebase";
import Loading from "./loading";
import Terminal from "../screens/terminals/terminal";
import Reporting from "../screens/reporting/reporting";
import _ from "lodash";
import Enrollments from "../screens/enrollments/enrollments";
import Ai from "../screens/AI/aiPage";
import HillsToHome from "../screens/hillToHome/hillsToHomePage";
import EntityHistory from "../screens/EntityHistory";

const Container = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 32px;
  padding-top: 4px;
`;

const ContainerPositioningBox = styled.div`
  position: absolute;
  top: 69px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

export default function AppRouter() {
  const [loading] = useGlobal("loading");
  return (
    <Router>
      <Permissioned />
      <Header />
      <Drawer />
      <ContainerPositioningBox>
        <Container>
          {loading ? (
            <Loading />
          ) : (
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/ai" component={Ai} />
              <Route exact path="/hills-to-home" component={HillsToHome} />
              <Route exact path="/entity-history" component={EntityHistory} />

              <Route
                exact
                path="/clinics/:clinicId/:userType"
                component={ClinicVetView}
              />
              <Route exact path="/users" component={Users} />
              <Route exact path="/users/:id" component={UserForm} />
              <Route exact path="/clinics/:id" component={ClinicForm} />
              <Route
                exact
                path="/clinics/manage/roles/:clinicId"
                component={ManageRoles}
              />
              <Route
                exact
                path="/paymentFeeConfigs/:id"
                component={PaymentFeeConfigForm}
              />
              <Route exact path="/clinicEmployees" component={ClinicEmployee} />
              <Route exact path="/consultations" component={Consultations} />

              <Route exact path="/pets" component={Pets} />
              <Route exact path="/petparents" component={PetParents} />

              <Route exact path="/organizations" component={Organizations} />
              <Route exact path="/enrollments" component={Enrollments} />
              <Route
                path="/organizations/:id/care"
                component={OrganizationsCare}
              />
              <Route exact path="/transactions" component={Transactions} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/json/view" component={Json} />
              <Route exact path="/firebase/token" component={Token} />
              <Route exact path="/ui-refresh" component={UIRefresh} />
              <Route exact path="/feature-flags" component={FeatureFlags} />
              <Route exact path="/terminals/:id" component={Terminal} />
              <Route exact path="/reporting" component={Reporting} />
              <Route
                exact
                path="/feature-flags/clinic/:id"
                component={ShowClinicFeatureFlags}
              />

              <Route
                path="/feature-flags/manage/:id?"
                component={ManageFeatureFlag}
              />
              <Route
                path="/feature-flags/clinics/:id?"
                component={ManageClinicFeatureFlags}
              />
              <Route
                exact
                path="/signin?previous=:previous"
                component={SignIn}
              />
              <Route exact path="/playground" component={Playground} />
            </Switch>
          )}
        </Container>
      </ContainerPositioningBox>
    </Router>
  );
}
const WHO = gql`
  query {
    who: getCurrentUser {
      id
      email
      firstName
      lastName
      firebaseId
      isAdmin
    }
  }
`;
function Permissioned() {
  const { refetch } = useQuery(WHO, {
    fetchPolicy: "network-only",
  });
  // eslint-disable-next-line
  let [token, setToken] = useGlobal("token");
  let [, setUser] = useGlobal("user");
  const [, setSnackBars] = useGlobal("snackbars");
  const history = useHistory();
  async function getWhoData() {
    const { data } = (await refetch().catch(console.log)) || {};
    return data;
  }
  async function refresh() {
    if (token) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          setToken(await user.getIdToken());
        }
        let data = await getWhoData();
        if (data && data.who) {
          setUser(data.who);
          if (!data.who.isAdmin) {
            logoutUser();
          }
        }
      });
    } else {
      logoutUser();
    }
  }
  async function logoutUser() {
    if (token) {
      setSnackBars([
        { id: Date.now(), type: "error", message: "User is not Authorized" },
      ]);
    }
    setToken("");
    setUser({});
    await firebase.auth().signOut();
    history.push(`/signin?previous=${history.location.pathname}`);
  }
  useEffect(() => {
    if (!isPublicRoute(history.location.pathname)) {
      refresh();
      return;
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  return null;
}
function isPublicRoute(pathname) {
  return !!_.find(publicRoutes, (route) => pathname.indexOf(route) !== -1);
}
const publicRoutes = ["/signin", "/impersonante"];
