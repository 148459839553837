import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const FIREBASE_OBJECT = gql`
  query firebaseObject($data: FirebaseObjectInput!) {
    firebaseObject(data: $data)
  }
`;
function useFirebaseQuery() {
  const [loadFirebaseObject, { called, loading, data }] = useLazyQuery(
    FIREBASE_OBJECT,
    {
      fetchPolicy: "network-only"
    }
  );

  return [loadFirebaseObject, { called, loading, data }];
}
export { useFirebaseQuery };
