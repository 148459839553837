import React, { useGlobal } from "reactn";
import Snackbars from "./components/Snackbars";

const SnackBar = () => {
  const [snackbars, setSnackBars] = useGlobal("snackbars");
  const removeSnackbar = (id) => {
    setSnackBars(snackbars.filter((sb) => sb.id !== id));
  };
  return <Snackbars snackbars={snackbars} removeSnackbar={removeSnackbar} />;
};
export default SnackBar;
