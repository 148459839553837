import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useGlobal } from "reactn";
import CarePlan from "../modals/carePlan";

export const useCarePlanModal = (orgId, plan, benefits, onClose) => {
  const [, setModalState] = useGlobal("modalState");

  const [updatePlan] = useMutation(gql`
    mutation updateCarePlan($id: String!, $data: CarePlanUpdateInput!) {
      updateOneCarePlan(where: { id: $id }, data: $data) {
        id
      }
    }
  `);

  const [createPlan] = useMutation(gql`
    mutation createCarePlan($data: CarePlanCreateInput!) {
      createOneCarePlan(data: $data) {
        id
      }
    }
  `);

  const onPlanSave = async (_data) => {
    const { id, activePlanBenefits, ...data } = _data;
    if (!id || id === "") {
      // Plan Save
      await createPlan({
        variables: {
          data: {
            ...data,
            planBenefits: {
              createMany: {
                data: activePlanBenefits.map((apb, index) => {
                  return {
                    includedUses: +apb.includedUses,
                    benefitId: apb.benefit.id,
                    displayOrder: index,
                    projectedSavings: apb.projectedSavings,
                    reportingExpectedValue: apb.reportingExpectedValue || 0,
                    reportingExpectedUsages: apb.reportingExpectedUsages || 0,
                  };
                }),
              },
            },
            organization: {
              connect: {
                id: orgId,
              },
            },
          },
        },
      });
    } else {
      await updatePlan({
        variables: {
          id,
          data: {
            ...data,
            planBenefits: {
              deleteMany: {},
            },
            organization: {
              connect: {
                id: orgId,
              },
            },
          },
        },
      });
      await updatePlan({
        variables: {
          id,
          data: {
            planBenefits: {
              createMany: {
                data: activePlanBenefits.map((apb, index) => {
                  return {
                    includedUses: +apb.includedUses,
                    benefitId: apb.benefit.id,
                    displayOrder: index,
                    projectedSavings: apb.projectedSavings,
                    reportingExpectedValue: apb.reportingExpectedValue || 0,
                    reportingExpectedUsages: apb.reportingExpectedUsages || 0,
                  };
                }),
              },
            },
          },
        },
      });
    }
  };
  useEffect(() => {
    if (!plan) return;
    setModalState({
      showModal: true,
      modalHeader: <h3>Plan - {plan?.id || "new"}</h3>,
      modalContent: (
        <CarePlan
          plan={plan || {}}
          benefits={benefits || []}
          onSave={onPlanSave}
        />
      ),
      modalConfirm: onClose,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, benefits]);
  const open = () => {
    setModalState({
      showModal: true,
      modalHeader: <h3>Plan - {plan?.id || "new"}</h3>,
      modalContent: (
        <CarePlan
          plan={plan || {}}
          benefits={benefits || []}
          onSave={onPlanSave}
        />
      ),
      modalConfirm: onClose,
    });
  };
  return {
    open,
  };
};
