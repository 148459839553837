import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const WORKFLOW_EVENT_SETTING_HISTORY = gql`
  query getWorkflowEventSettingHistory($where: GetEntityHistoryInput!) {
    getWorkflowEventSettingHistory(where: $where) {
      data
    }
  }
`;

function useWorkflowEventSettingHistory() {
  const [getWorkflowEventSetting, { called, loading, data }] = useLazyQuery(
    WORKFLOW_EVENT_SETTING_HISTORY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return [getWorkflowEventSetting, { called, loading, data }];
}
export { useWorkflowEventSettingHistory, WORKFLOW_EVENT_SETTING_HISTORY };
