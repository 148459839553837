import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useGlobal } from "reactn";
import CareAddon from "../modals/careAddon";

export const useCareAddonModal = (orgId, addon, benefits, onClose) => {
  const [, setModalState] = useGlobal("modalState");

  const [updateAddon] = useMutation(gql`
    mutation updateCareAddon(
      $id: String!
      $data: CareAddonPackageUpdateInput!
    ) {
      updateOneCareAddonPackage(where: { id: $id }, data: $data) {
        id
      }
    }
  `);

  const [createAddon] = useMutation(gql`
    mutation createCareAddon($data: CareAddonPackageCreateInput!) {
      createOneCareAddonPackage(data: $data) {
        id
      }
    }
  `);

  const onAddonSave = async (_data) => {
    const { id, activeAddonBenefits, ...data } = _data;
    if (!id) {
      await createAddon({
        variables: {
          data: {
            ...data,
            addonPackageBenefit: {
              createMany: {
                data: activeAddonBenefits.map((apb, index) => {
                  return {
                    includedUses: +apb.includedUses,
                    benefitId: apb.benefit.id,
                    displayOrder: index,
                    projectedSavings: apb.projectedSavings,
                    reportingExpectedValue: apb.reportingExpectedValue || 0,
                    reportingExpectedUsages: apb.reportingExpectedUsages || 0,
                  };
                }),
              },
            },
            organization: {
              connect: {
                id: orgId,
              },
            },
          },
        },
      });
    } else {
      await updateAddon({
        variables: {
          id,
          data: {
            ...data,
            addonPackageBenefit: {
              deleteMany: {},
            },
            organization: {
              connect: {
                id: orgId,
              },
            },
          },
        },
      });
      await updateAddon({
        variables: {
          id,
          data: {
            addonPackageBenefit: {
              createMany: {
                data: activeAddonBenefits.map((apb, index) => {
                  return {
                    includedUses: +apb.includedUses,
                    benefitId: apb.benefit.id,
                    displayOrder: index,
                    projectedSavings: apb.projectedSavings,
                    reportingExpectedValue: apb.reportingExpectedValue || 0,
                    reportingExpectedUsages: apb.reportingExpectedUsages || 0,
                  };
                }),
              },
            },
          },
        },
      });
    }
  };
  useEffect(() => {
    if (!addon) return;
    setModalState({
      showModal: true,
      modalHeader: <h3>Addon - {addon?.id || "new"}</h3>,
      modalContent: (
        <CareAddon
          addon={addon || {}}
          benefits={benefits || []}
          onSave={onAddonSave}
        />
      ),
      modalConfirm: onClose,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addon, benefits]);
  const open = () => {
    setModalState({
      showModal: true,
      modalHeader: <h3>Addon - {addon?.id || "new"}</h3>,
      modalContent: (
        <CareAddon
          addon={addon || {}}
          benefits={benefits || []}
          onSave={onAddonSave}
        />
      ),
      modalConfirm: onClose,
    });
  };
  return {
    open,
  };
};
