import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const mapExportData = (d, columns) => {
  var data = {};
  columns = columns || [];
  columns.forEach((col) => {
    var name = col.Header || col.accessor || col;
    if (typeof name !== "string") return;
    let value = "";
    if (typeof col.accessor === "function") {
      value = col.accessor({ data: d });
    } else {
      value = d[col.accessor || col];
    }
    if (col.Cell) {
      data[name] = col.Cell({ value });
    } else {
      data[name] = value;
    }
  });
  return data;
}

export const exportToCSV = (dat, fileName, sheetName = 'data', fileExtension = 'xlsx') => {
  const ws = XLSX.utils.json_to_sheet(dat);
  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}.${fileExtension}`);
};
