import gql from "graphql-tag";
import { useState, useEffect } from "reactn";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

export const useServiceSelector = (client, careBenefit) => {
  // State
  const benefit = careBenefit.data;
  const [page, setPage] = useState(0);
  const [availableServices, setAvailableServices] = useState([]);
  const [connectedServices, setConnectedServices] = useState([]);
  // Network data
  const [getServices, servicesData] = useLazyQuery(
    gql`
      query getServices(
        $contains: String!
        $integrationId: String!
        $skip: Int!
      ) {
        findManyService(
          where: {
            name: { contains: $contains }
            isDeleted: { equals: false }
            integrationId: { equals: $integrationId }
          }
          take: 10
          skip: $skip
        ) {
          id
          name
          code
          isDeleted
        }
        findManyServiceCount(
          where: {
            name: { contains: $contains }
            isDeleted: { equals: false }
            integrationId: { equals: $integrationId }
          }
        )
      }
    `,
    { client }
  );
  const [connectBenefitToService] = useMutation(
    gql`
      mutation connectBenefitToService(
        $data: OrganizationCareBenefitToClinicServiceCreateInput!
      ) {
        createOneOrganizationCareBenefitToClinicService(data: $data) {
          benefitId
          serviceId
        }
      }
    `,
    { client }
  );
  const connectService = async (serviceId) => {
    await connectBenefitToService({
      variables: {
        data: {
          service: {
            connect: {
              id: serviceId,
            },
          },
          benefit: {
            connect: {
              id: benefit.id,
            },
          },
        },
      },
    });
    careBenefit.refetch();
  };

  const [disconnecteBenefitFromService] = useMutation(
    gql`
      mutation disconnecteBenefitFromService(
        $where: OrganizationCareBenefitToClinicServiceWhereUniqueInput!
      ) {
        deleteOneOrganizationCareBenefitToClinicService(where: $where) {
          benefitId
          serviceId
        }
      }
    `,
    { client }
  );

  const disconnectService = async (serviceId) => {
    await disconnecteBenefitFromService({
      variables: {
        where: {
          benefitId_serviceId: {
            benefitId: benefit.id,
            serviceId: serviceId,
          },
        },
      },
    });
    careBenefit.refetch();
  };

  const total = servicesData?.data?.findManyServiceCount || 0;
  // Effects
  useEffect(() => {
    if (!!servicesData?.data?.findManyService) {
      setAvailableServices(
        (servicesData?.data?.findManyService || []).filter((service) => {
          console.log(service, connectedServices);
          return !connectedServices.find((s) => s.service.id === service.id);
        })
      );
    }
  }, [servicesData, connectedServices]);

  useEffect(() => {
    if (benefit) {
      setConnectedServices(
        benefit?.organizationCareBenefitToClinicService || []
      );
    }
  }, [benefit]);

  // Functions
  const searchServices = (integrationId, contains, skip = 0) => {
    getServices({
      variables: {
        contains,
        integrationId,
        skip,
      },
    });
  };
  return {
    page,
    setPage,
    pageSize: 10,
    total,
    searchServices,
    connectService,
    servicesData,
    availableServices,
    connectedServices,
    disconnectService,
  };
};
