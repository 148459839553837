import React, { useGlobal, useState, useEffect } from "reactn";

import {
  GET_TERMINALS,
  CREATE_STRIPE_LOCATION,
  CREATE_TERMINALS,
  UPDATE_TERMINAL,
} from "./terminalGraphQL";

import { FaCaretDown } from "react-icons/fa";

import { useQuery, useMutation } from "@apollo/react-hooks";

import { Input } from "../../../styledComponents/form";
import Loading from "../../app/loading";

import { Card, CardContent } from "../../../styledComponents/card";
import { SaveButton } from "../../../styledComponents/form";
import { FaWindowClose } from "react-icons/fa";

export default function Terminal({
  match: {
    params: { id },
  },
}) {
  const [clinicName] = useGlobal("clinicName");
  const [showUpdateCard, setShowUpdateCard] = useState(false);
  const [showCreateCard, setShowCreateCard] = useState(false);
  const [hover, setHover] = useState();
  const [currentTerminal, setCurrentTerminal] = useState({
    id: "",
    stripeId: "",
    name: "",
    serialNumber: "",
    model: "",
    healthCheck: "",
    healthCheckHistory: {},
  });

  useEffect(() => {
    sessionStorage.setItem("clinic", id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showCreate = () => {
    setShowCreateCard(true);
  };

  const showUpdate = () => {
    setShowUpdateCard(true);
  };
  const {
    data: terminals,
    refetch,
    loading,
  } = useQuery(GET_TERMINALS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        clinicId: {
          equals: id,
        },
      },
    },
  });

  const [
    createLocation,
    { error: createLocationError, loading: locationIsLoading },
  ] = useMutation(CREATE_STRIPE_LOCATION, {
    errorPolicy: "all",
    variables: {
      displayName: clinicName,
    },
  });

  const createTerminalLocation = () => {
    createLocation().then(() => {
      refetch();
    });
  };

  const CreateTerminalsCard = () => {
    const [values, setValues] = useState({ displayName: "", pairingCode: "" });
    const [inputError, setInputError] = useState(false);
    const handleInput = (e) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    };

    const [createTerminal, { error: createTerminalError, loading }] =
      useMutation(CREATE_TERMINALS, {
        errorPolicy: "all",
        variables: {
          displayName: values.displayName,
          pairingCode: values.pairingCode,
        },
      });

    const createTerminals = () => {
      if (!values.pairingCode || !values.displayName) {
        setInputError(true);
      } else {
        createTerminal().then(() => {
          refetch();
          setInputError(false);
          setShowCreateCard(false);
        });
      }
    };

    return (
      <Card
        style={{
          ...centered,
          padding: "50px",
          width: "30%",
          backgroundColor: "#DCDCDC",
          top: "40%",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <FaWindowClose
            onClick={() => {
              setShowCreateCard(false);
            }}
            size={32}
            style={{ margin: 8, cursor: "pointer" }}
          />
        </div>
        <h2>Create New Terminal</h2>
        <CardContent
          style={{
            backgroundColor: "#fefefe",
            padding: "50px",
            borderRadius: "10px",
          }}
        >
          {inputError ? (
            <p style={errorBanner}>
              Fill out both display name and pairing code
            </p>
          ) : null}
          {createTerminalError ? (
            <p style={errorBanner}>
              {createTerminalError.message +
                "." +
                " " +
                "Check the pairing code and make sure it is correct"}
            </p>
          ) : null}
          <label htmlFor="displayName">
            <b>Terminal Display Name</b>
          </label>
          <Input
            style={{ marginTop: "10px", marginBottom: "25px" }}
            type="text"
            name="displayName"
            onChange={handleInput}
          />
          <label htmlFor="pairingCode">
            <b>Pairing Code</b>
          </label>
          <Input
            style={{ marginTop: "10px" }}
            type="text"
            name="pairingCode"
            onChange={handleInput}
          />
        </CardContent>
        <div style={{ textAlign: "right", marginTop: "10px" }}>
          {loading ? (
            <Loading />
          ) : (
            <SaveButton onClick={createTerminals}>Save</SaveButton>
          )}
        </div>
      </Card>
    );
  };

  const UpdateTerminalsCard = () => {
    const [showUpdateInput, setShowUpdateInput] = useState(false);
    const [newTerminalname, setNewTerminalName] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [updateTerminals] = useMutation(UPDATE_TERMINAL, {
      errorPolicy: "all",
      variables: {
        where: {
          id: currentTerminal.id,
        },
        data: {
          name: newTerminalname,
        },
      },
    });
    const handleNameInput = (e) => {
      setNewTerminalName(e.target.value);
    };
    const submitNewName = () => {
      updateTerminals().then(() => {
        refetch();
        setShowUpdateCard(false);
      });
    };
    return (
      <Card
        style={{
          ...centered,
          padding: "50px",
          width: "30%",
          backgroundColor: "#DCDCDC",
          top: "40%",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <FaWindowClose
            onClick={() => {
              setShowUpdateCard(false);
            }}
            size={32}
            style={{ marginBottom: "20px", cursor: "pointer" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!showUpdateInput ? (
            <h2>{currentTerminal.name}</h2>
          ) : (
            <Input
              defaultValue={currentTerminal.name}
              placeholder="Update terminal name"
              style={{ width: "50%", marginBottom: "10px" }}
              onChange={handleNameInput}
            />
          )}
          {!showUpdateInput ? (
            <button
              onClick={() => {
                setShowUpdateInput(true);
              }}
              style={{ ...button, height: "50%", marginTop: "3%" }}
            >
              Update
            </button>
          ) : (
            <button
              onClick={submitNewName}
              style={{ ...button, height: "50%" }}
            >
              Save
            </button>
          )}
        </div>
        <CardContent
          style={{
            backgroundColor: "#fefefe",
            padding: "50px",
            borderRadius: "10px",
            height: currentTerminal.healthCheck ? "280px" : "inherit",
            overflowY: "scroll",
          }}
        >
          <div style={cardContent}>
            <p>Stripe ID</p>
            <p>{currentTerminal.stripeId}</p>
          </div>
          <div style={cardContent}>
            <p>Serial Number</p>
            <p>{currentTerminal.serialNumber}</p>
          </div>
          <div style={cardContent}>
            <p>Model</p>
            <p>{currentTerminal.model}</p>
          </div>
          <div style={cardContent}>
            <p>Status</p>
            <p
              style={
                currentTerminal.healthCheck === "Online"
                  ? terminalStatusOnline
                  : currentTerminal.healthCheck === "Offline"
                  ? terminalStatusOffline
                  : terminalStatusPending
              }
            >
              {currentTerminal.healthCheck
                ? currentTerminal.healthCheck
                : "Pending"}
            </p>
          </div>
          {currentTerminal.healthCheck ? (
            <div>
              <h4
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              >
                Status History <FaCaretDown />
              </h4>
              {currentTerminal.healthCheckHistory.map(
                ({ status, createdAt, id }) => {
                  let statusTimeCheck = new Date(createdAt);
                  let localTimeCheck = statusTimeCheck.toLocaleString();
                  if (showHistory) {
                    return (
                      <ul key={id}>
                        <li>
                          <b>Status:</b> {status}
                        </li>
                        <li>
                          <b>Updated At:</b> {localTimeCheck}
                        </li>
                      </ul>
                    );
                  }
                  return undefined;
                }
              )}
            </div>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    );
  };

  const updateTerminals = (terminalInfo) => {
    setCurrentTerminal({
      id: terminalInfo.id,
      stripeId: terminalInfo.stripeId,
      name: terminalInfo.name,
      serialNumber: terminalInfo.serialNumber,
      model: terminalInfo.model,
      healthCheck: terminalInfo.healthchecks[0]?.status,
      healthCheckHistory: terminalInfo?.healthchecks,
    });
  };

  const toggleHover = (id) => {
    setHover({
      rowIsActive: id,
    });
  };

  if (loading) {
    return <Loading />;
  }
  return !terminals?.findManyStripeTerminalLocation.length ? (
    <>
      <div style={centered}>
        <h3>{clinicName} does not have configured terminals</h3>
        <div style={{ textAlign: "center" }}>
          {locationIsLoading ? (
            <Loading />
          ) : (
            <button onClick={createTerminalLocation} style={button}>
              Configure Terminals
            </button>
          )}
          {createLocationError ? (
            <p style={errorBanner}>{createLocationError.message}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  ) : (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Terminals for {clinicName}</h1>
        <h4>
          Stripe Location ID:{" "}
          {terminals.findManyStripeTerminalLocation[0].stripeId}
        </h4>
      </div>
      <div
        style={{
          textAlign: "right",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <button onClick={showCreate} style={button}>
          New +
        </button>
      </div>
      {showUpdateCard && <UpdateTerminalsCard />}
      {showCreateCard && <CreateTerminalsCard />}

      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Terminal Created At</th>
            <th style={tableHeaderStyle}>Terminal Name</th>
            <th style={tableHeaderStyle}>Serial Number</th>
            <th style={tableHeaderStyle}>Terminal Model</th>
            <th style={tableHeaderStyle}>Status</th>
            <th style={tableHeaderStyle}>Status Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {terminals?.findManyStripeTerminalLocation[0]?.terminals.map(
            (terminal) => {
              const currentStatus = terminal.healthchecks[0]?.status;
              let terminalCreatedate = new Date(terminal.createdAt);
              let localTerminlCreateDate =
                terminalCreatedate.toLocaleDateString();
              let healthCheckCreateDate = new Date(
                terminal.healthchecks[0]?.updatedAt
              );
              let localHealthCheckCreateDate =
                healthCheckCreateDate.toLocaleString();
              return (
                <tr
                  style={{
                    backgroundColor:
                      hover?.rowIsActive === terminal.id
                        ? "rgba(0, 0, 0, 0.4)"
                        : "inherit",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => {
                    toggleHover(terminal.id);
                  }}
                  onMouseLeave={() => {
                    toggleHover(null);
                  }}
                  key={terminal.id}
                  onClick={() => {
                    showUpdate();
                    updateTerminals(terminal);
                  }}
                >
                  <td style={tableDataStyle}>{localTerminlCreateDate}</td>
                  <td style={tableDataStyle}>{terminal.name}</td>
                  <td style={tableDataStyle}>{terminal.serialNumber}</td>
                  <td style={tableDataStyle}>{terminal.model}</td>
                  <td style={tableDataStyle}>
                    <div
                      style={{
                        ...(currentStatus === "Online"
                          ? terminalStatusOnline
                          : currentStatus === "Offline"
                          ? terminalStatusOffline
                          : terminalStatusPending),
                        width: "40%",
                        margin: "auto",
                      }}
                    >
                      {currentStatus ? currentStatus : "Pending"}
                    </div>
                  </td>
                  <td style={tableDataStyle}>
                    {!currentStatus
                      ? "No Recent Status"
                      : localHealthCheckCreateDate}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
}

const button = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  borderWidth: "thin",
  cursor: "pointer",
  backgroundColor: "#3CA0B4",
  color: "white",
  fontWeight: "bold",
};

const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
  padding: "20px",
};

const tableHeaderStyle = {
  paddingTop: "12px",
  paddingBottom: "12px",
  textAlign: "left",
  backgroundColor: "#E6E9ED",
  color: "black",
  border: "1px solid #ddd",
  padding: "10px",
};

const tableDataStyle = {
  border: "1px solid #ddd",
  padding: "10px",
};

const centered = {
  padding: "5px",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const cardContent = {
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "bold",
};

const errorBanner = {
  padding: "15px",
  backgroundColor: "#f07067",
  color: "white",
  borderRadius: "5px",
  fontWeight: "bold",
};

const terminalStatusOnline = {
  color: "white",
  backgroundColor: "green",
  paddingLeft: "15px",
  paddingRight: "15px",
  borderRadius: "15px",
  paddingTop: "5px",
  paddingBottom: "5px",
  textAlign: "center",
  fontWeight: "bold",
};

const terminalStatusOffline = {
  color: "white",
  backgroundColor: "red",
  paddingLeft: "15px",
  paddingRight: "15px",
  borderRadius: "15px",
  paddingTop: "5px",
  paddingBottom: "5px",
  textAlign: "center",
  fontWeight: "bold",
};

const terminalStatusPending = {
  color: "white",
  backgroundColor: "#D5B800 ",
  paddingLeft: "15px",
  paddingRight: "22px",
  borderRadius: "15px",
  paddingTop: "5px",
  paddingBottom: "5px",
  textAlign: "center",
  fontWeight: "bold",
};
