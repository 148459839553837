import React, { setGlobal, useGlobal, addCallback, useEffect } from "reactn";
import "./App.css";
import Router from "./components/app/router";
import {
  blankState,
  storeGlobalState,
  getStoredState,
} from "./components/app/persistGate";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./api/client";
import styled from "styled-components/macro";
import Modal from "./components/app/modal";
import Snackbars from "./components/app/snackbars";
const AppWrapper = styled.div`
  min-height: 100%;
  display: flex;
`;

setGlobal(blankState);
addCallback((state) =>
  storeGlobalState(state, ["typeQueries", "loading", "modalState", "enterCode"])
);
function App() {
  const [appInitialized] = useGlobal("appInitialized");

  async function checkCache() {
    const globalState = await getStoredState();
    setGlobal({ ...blankState, ...globalState, appInitialized: true });
  }
  useEffect(() => {
    checkCache();
  }, []);
  if (!appInitialized) {
    return (
      <AppWrapper>
        <div styled={{ marginTop: 32 }}>
          <h3>...Loading</h3>
        </div>
      </AppWrapper>
    );
  }
  return (
    <AppWrapper>
      <Modal />
      <Snackbars />
      <ApolloProvider client={client}>
        <Router/>
      </ApolloProvider>
    </AppWrapper>
  );
}

export default App;
