import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { titleCase } from "./filterFuncs";
function useGenericCreateMutation({ entity }) {
  const MUTATION = gql`
    mutation create${entity}($data: ${entity}CreateInput!) {
        createOne${entity}(data: $data) {
        id
      }
    }
  `;
  return useMutation(MUTATION);
}
function useGenericUpdateMutation({ entity }) {
  const MUTATION = gql`
      mutation update${entity}($data: ${entity}UpdateInput! $where: ${entity}WhereUniqueInput!) {
          updateOne${entity}(data: $data where: $where) {
          id
        }
      }
    `;
  return useMutation(MUTATION);
}
function useGenericDeleteMutation({ entity }) {
  const MUTATION = gql`
        mutation delete${entity}($where: Prisma_2${entity}WhereUniqueInput!) {
            deleteOne${entity}(where: $where) {
            id
          }
        }
      `;
  return useMutation(MUTATION);
}
const mutations = {
  useGenericCreateMutation,
  useGenericUpdateMutation,
  useGenericDeleteMutation,
};
function useGenericMutation({ type, entity }) {
  return mutations[`useGeneric${titleCase(type)}Mutation`]({ entity });
}
export { useGenericMutation };
