import React, { useGlobal } from "reactn";
import Container from "../../../styledComponents/container";
import { Row } from "../../../styledComponents/grid";
import { TextArea } from "../../../styledComponents/form";

function Token() {
  const [token] = useGlobal("token");

  return (
    <Container>
      <Row>
        <h3>Firebase Token</h3>
      </Row>
      <Row>
        <TextArea disabled={true} value={token} style={{ height: 180 }} />
      </Row>
    </Container>
  );
}

export default Token;
