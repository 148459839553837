import React, { useGlobal } from "reactn";
import { FaCode, FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import {
  PET_PARENT_IMPERSONATION,
  useClinicPetParentsLazyQuery,
  UPDATE_CLINIC_PET_PARENT,
  REDACT_PET_PARENT,
} from "./petParentGraphQL";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import { useMutation } from "@apollo/react-hooks";
import handleCheckbox from "../Utils/handleCheckbox";
import config from "../../../api/config";

export default function PetParents() {
  const [, setJson] = useGlobal("json");
  let [modalState, setGlobalModalState] = useGlobal("modalState");
  const [impersonate] = useMutation(PET_PARENT_IMPERSONATION);
  const history = useHistory();
  const [updatePetParent] = useMutation(UPDATE_CLINIC_PET_PARENT, {
    errorPolicy: "all",
  });

  const [redactPetParent] = useMutation(REDACT_PET_PARENT, {
    errorPolicy: "all",
  });

  let queryVariables = { orderBy: { firstName: "asc" }, where: {}, take: 80 };

  function showImpersonateConfirm({ firstName, lastName, channelMembers, id }) {
    !channelMembers.length
      ? (modalState = {
          ...modalState,
          modalHeader: "No Conversations",
          modalContent: `Pet Parent ${firstName} ${lastName} has no active or inactive conversations so they can't be impersonated`,
          showModal: true,
        })
      : (modalState = {
          ...modalState,
          modalHeader: "Confirm Impersonation",
          modalContent: `Are you sure you want to impersonate ${firstName} ${lastName}`,
          showModal: true,
          modalConfirm: async () => {
            const {
              data: {
                generateTinyUrlAndToken: { token },
              },
            } = await impersonate({
              variables: { data: { clinicPetParentId: id } },
            });
            const careUrl = `${config.care}/conversations?token=${token}&impersonate=true`;
            window.open(careUrl, "_blank");
          },
        });

    setGlobalModalState(modalState);
  }

  const redactPetParentOnClick = (event, id, firstName, lastName) => {
    const confirmRedact = window.confirm(
      `Are you sure you want to redact information for ${firstName} ${lastName}`
    );

    event.target.checked = confirmRedact;

    if (confirmRedact) {
      redactPetParent({ variables: { clinicPetParentId: id } });
    }
  };

  return (
    <PrismaServerFilterTable
      title="Pet Parents"
      queryName="clinicPetParents"
      useQuery={useClinicPetParentsLazyQuery}
      queryVariables={queryVariables}
      prismaVersion={2}
      filterConfigs={[
        { property: "id", display: "ID" },
        { property: `phoneNumbers.some.number`, display: "Phone Number" },
        { property: "firstName", display: "First Name" },
        { property: "lastName", display: "Last Name" },
        { property: "email", display: "Email" },
        { property: "clinicId", display: "Clinic Id" },
        {
          property: "isActive",
          type: "select",
          options: [
            { value: "true", display: "True" },
            { value: "false", display: "False" },
          ],
          display: "Is Active",
        },
        {
          property: "isDeleted",
          type: "select",
          options: [
            { value: "true", display: "True" },
            { value: "false", display: "False" },
          ],
          display: "Is Deleted",
        },
      ]}
      columns={[
        {
          Header: "",
          hidefilter: true,
          blockSort: true,
          accessor: ({ data }) => {
            return (
              <div>
                <FaCode
                  color="#2ABA66"
                  size={20}
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  title="View Json"
                  onClick={() => {
                    setJson({ type: "petparents", data });
                    history.push("/json/view");
                  }}
                />

                <span onClick={() => showImpersonateConfirm(data)}>
                  <FaUser
                    size={20}
                    style={
                      `${data.channelMembers}`
                        ? { marginLeft: 8, cursor: "pointer", color: "blue" }
                        : { marginLeft: 8, cursor: "auto" }
                    }
                    title="Impersonate"
                  />
                </span>
              </div>
            );
          },
        },
        {
          Header: "IsActive",
          accessor: ({ data }) => {
            const whereClause = { id: data.id };
            return (
              <input
                type="checkbox"
                defaultChecked={data.isActive}
                onClick={(event) =>
                  handleCheckbox(
                    updatePetParent,
                    whereClause,
                    "isActive",
                    event
                  )
                }
                style={{ width: "100%", textAlign: "center" }}
              />
            );
          },
        },
        {
          Header: "IsDeleted",
          accessor: ({ data }) => {
            const whereClause = { id: data.id };
            return (
              <input
                type="checkbox"
                defaultChecked={data.isDeleted}
                onClick={(event) =>
                  handleCheckbox(
                    updatePetParent,
                    whereClause,
                    "isDeleted",
                    event
                  )
                }
                style={{ width: "100%", textAlign: "center" }}
              />
            );
          },
        },
        {
          Header: "Redacted",
          accessor: ({ data }) => {
            return (
              <input
                type="checkbox"
                disabled={data.isPrivacyProtected ? true : false}
                defaultChecked={data.isPrivacyProtected}
                onClick={(event) => {
                  redactPetParentOnClick(
                    event,
                    data.id,
                    data.firstName,
                    data.lastName
                  );
                }}
                style={{ width: "100%", textAlign: "center" }}
              />
            );
          },
        },
        { Header: "Creation Source", accessor: "creationSource" },
        { Header: "Firstname", accessor: "firstName" },

        { Header: "Lastname", accessor: "lastName" },
        { Header: "Email", accessor: "email" },
        {
          Header: "Clinic Name",
          accessor: ({ data: { clinic } }) => (clinic ? clinic.name : ""),
        },
        { Header: "Clinic Id", accessor: "clinicId" },
        {
          Header: "Phone Number",
          accessor: ({ data: { phoneNumbers } }) => {
            if (phoneNumbers.length) {
              return phoneNumbers[0].number === null
                ? ""
                : phoneNumbers[0].number;
            }
            return "";
          },
        },
      ]}
    />
  );
}
