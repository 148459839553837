import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const useCareData = (organizationId) => {
  return useQuery(
    gql`
      query getCareData($id: String!) {
        findUniqueOrganization(where: { id: $id }) {
          id
          name
          paymentProcessorClinic {
            name
            id
            integrations {
              id
              type
            }
          }
          careBenefits {
            id
            title
            description
            tangible
            editorNotes
            usageUnitOfMeasure
            organizationCareBenefitToClinicService {
              service {
                id
                name
                code
              }
            }
            usages {
              id
              quantityUsed
              usedAt
            }
            planBenefits {
              plan {
                title
              }
            }
            addonPackageBenefits {
              addOnPackage {
                title
              }
            }
          }
          carePlans {
            id
            title
            description
            published
            pricePerRenewal
            billingCycle
            renewalCycle
            stripePriceId
            earliestTargetPetAgeInMonths
            latestTargetPetAgeInMonths
            targetSpecies
            themeColor
            editorNotes
            planBenefits(orderBy: { displayOrder: asc}) {
              id
              planId
              benefit {
                id
                title
                tangible
                usageUnitOfMeasure
                description
              }
              projectedSavings
              includedUses
              reportingExpectedValue
              reportingExpectedUsages
            }
          }
          careAddonPackage {
            id
            title
            themeColor
            description
            published
            pricePerRenewal
            stripePriceId
            earliestTargetPetAgeInMonths
            latestTargetPetAgeInMonths
            billingCycle
            renewalCycle
            targetSpecies
            editorNotes
            addonPackageBenefit(orderBy: { displayOrder: asc})  {
              id
              addOnPackageId
              benefit {
                id
                title
                tangible
                description
              }
              projectedSavings
              includedUses
              reportingExpectedValue
              reportingExpectedUsages
            }
          }
        }
      }
    `,
    {
      variables: {
        id: organizationId
      },
      fetchPolicy: "cache-and-network"
    }
  );
}
