import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const CLINICS = gql`
  query clinics(
    $where: ClinicWhereInput
    $orderBy: [ClinicOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $take: Int
  ) {
    findManyClinic: findManyClinic(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      name
      clinicEmail
      integrationType
      integrationInfo
      integrationSystem
      stripeCustomerId
      featureFlags {
        key
      }
      integrations {
        id
        isActive
        capabilities {
          id
          lastSyncedAt
          capability
        }
        lastSyncedAt
        type
        vetdata {
          system
          installationId
        }
        ezyvet {
          client_id
          client_secret
        }
        neo {
          id
          createdAt
          branchId
          authToken
        }
        bitwerx {
          practiceId
        }
      }
      timezone
      city
      state
      hours
      phone
      logo
      isClinicActive
      subscribedAt
      subscriptionId
      responseTime
      firebaseId
      consultationCost
      followUpCost
      clinicPhoneNumber {
        capability
        isPrimary
        isActive
        number
      }
      featureFlags {
        id
      }
      lat
      long
      clinicSetting {
        id
        hasCustomizableFees
        campaignRegistryCampaigns {
          id
        }
        paymentFeeConfig {
          id
          onlineClientServiceFeePercent
          onlineProcessingFeePercent
          terminalClientServiceFeePercent
          terminalProcessingFeePercent
          onlineProcessingFeeFlat
          terminalProcessingFeeFlat
        }
      }
      fullAddress
      organization {
        name
      }

      roles(where: { role: { equals: ADMIN } }) {
        users(take: 1) {
          # VetInfo model
          user {
            # User model
            id
            firstName
            lastName
            firebaseId
            email
          }
        }
      }
    }
    findManyClinicCount: findManyClinicCount(where: $where)
  }
`;

const ONE_CLINIC = gql`
  query getClinicInfo($where: ClinicWhereUniqueInput!) {
    getClinicInfo: findUniqueClinic(where: $where) {
      id
      name
      addressLine1
      addressLine2
      fullAddress
      city
      state
      phone
      timezone
    }
  }
`;
const Cancel_Subscription = gql`
  mutation cancelSubscription(
    $data: ClinicCancellationInput!
    $where: ClinicWhereUniqueInput!
  ) {
    cancelClinicSubscription(data: $data, where: $where) {
      success
    }
  }
`;

const CREATE_FEES = gql`
  mutation createPaymentFeeConfiguration(
    $data: ClinicPaymentFeeConfigurationCreateInput!
  ) {
    createPaymentFeeConfiguration: createOneClinicPaymentFeeConfiguration(
      data: $data
    ) {
      id
      onlineProcessingFeePercent
      terminalProcessingFeePercent
      onlineClientServiceFeePercent
      terminalClientServiceFeePercent
      onlineProcessingFeeFlat
      terminalProcessingFeeFlat
    }
  }
`;

const UPDATE_STRIPE_ID = gql`
  mutation updateStripeID(
    $data: ClinicUpdateInput!
    $where: ClinicWhereUniqueInput!
  ) {
    updateOneClinic(data: $data, where: $where) {
      id
      name
      stripeCustomerId
    }
  }
`;

const UPDATE_FEES = gql`
  mutation updatePaymentFeeConfiguration(
    $where: ClinicPaymentFeeConfigurationWhereUniqueInput!
    $data: ClinicPaymentFeeConfigurationUpdateInput!
  ) {
    updatePaymentFeeConfiguration: updateOneClinicPaymentFeeConfiguration(
      where: $where
      data: $data
    ) {
      id
      onlineProcessingFeePercent
      terminalProcessingFeePercent
      onlineClientServiceFeePercent
      terminalClientServiceFeePercent
      onlineProcessingFeeFlat
      terminalProcessingFeeFlat
      feeCollection
      pricingModel
    }
  }
`;

const UPDATE_CLINIC_SETTING = gql`
  mutation updateClinicSetting(
    $where: ClinicSettingWhereUniqueInput!
    $data: ClinicSettingUpdateInput!
  ) {
    updateClinicSetting: updateOneClinicSetting(where: $where, data: $data) {
      hasCustomizableFees
    }
  }
`;

const UPDATE_OTC_DB = gql`
  mutation updateClinicDirectBookingSetting(
    $where: ClinicDirectBookingSettingWhereUniqueInput!
    $data: ClinicDirectBookingSettingUpdateInput!
  ) {
    otcUpdate: updateOneClinicDirectBookingSetting(where: $where, data: $data) {
      clinic {
        id
      }
      isOneTimePasswordEnabled
    }
  }
`;

const CREATE_CLINIC_SETTING = gql`
  mutation createClinicSetting($data: ClinicSettingCreateInput!) {
    createClinicSetting: createOneClinicSetting(data: $data) {
      id
      hasCustomizableFees
      hideAppointmentsWithoutPetOwner
      workflowsEnabled
      outOfOfficeHeadline
      outOfOfficeShowScheduleEnabled
      financialReportingConfig
      smsAutoResponseWhenOpen
      smsAutoResponseWhenClosed
    }
  }
`;

const PAYMENT_FEE_CONFIG = gql`
  query getClinicPaymentFeeConfiguration(
    $where: ClinicPaymentFeeConfigurationWhereUniqueInput!
  ) {
    getPaymentFeeConfiguration: findUniqueClinicPaymentFeeConfiguration(
      where: $where
    ) {
      id
      onlineProcessingFeePercent
      terminalProcessingFeePercent
      onlineClientServiceFeePercent
      terminalClientServiceFeePercent
      onlineProcessingFeeFlat
      terminalProcessingFeeFlat
      feeCollection
      pricingModel
      clinicSetting {
        hasCustomizableFees
      }
    }
  }
`;

const PAYMENT_FEE_COLLECTION = gql`
  query getPaymentFeeCollection(
    $where: ClinicPaymentFeeConfigurationWhereUniqueInput!
  ) {
    getPaymentFeeCollection: findUniqueClinicPaymentFeeConfiguration(
      where: $where
    ) {
      id
      feeCollection
      pricingModel
    }
  }
`;

const GET_CLINIC_SETTING = gql`
  query getClinicSetting($where: ClinicSettingWhereUniqueInput!) {
    getSetting: findUniqueClinicSetting(where: $where) {
      hasCustomizableFees
    }
  }
`;

const INTEGRATIONS = gql`
  query getClinicPimsIntegrations($where: ClinicPimsIntegrationWhereInput!) {
    getClinicPimsIntegrations: findManyClinicPimsIntegration(where: $where) {
      id
      isActive
      vetdata {
        system
      }
      clinic {
        name
        headUserId
        users {
          firstName
          lastName
          email
          phone
        }
      }
    }
    getClinicPimsIntegrationsCount: findManyClinicPimsIntegrationCount(
      where: $where
    )
  }
`;

function useClinicsQuery() {
  const [loadClinics, { called, loading, data }] = useLazyQuery(CLINICS, {
    fetchPolicy: "network-only",
  });
  return [loadClinics, { called, loading, data }];
}

function useOneClinicQuery() {
  const [loadClinic, { called, loading, data }] = useLazyQuery(ONE_CLINIC, {
    fetchPolicy: "network-only",
  });
  return [loadClinic, { called, loading, data }];
}

function usePaymentFeeConfigQuery() {
  const [loadFees, { called, loading, data }] = useLazyQuery(
    PAYMENT_FEE_CONFIG,
    {
      fetchPolicy: "network-only",
    }
  );

  return [loadFees, { called, loading, data }];
}

function usePimsIntegrationQuery() {
  const [loadFees, { called, loading, data }] = useLazyQuery(INTEGRATIONS, {
    fetchPolicy: "network-only",
  });

  return [loadFees, { called, loading, data }];
}

export {
  useClinicsQuery,
  useOneClinicQuery,
  usePaymentFeeConfigQuery,
  GET_CLINIC_SETTING,
  Cancel_Subscription,
  usePimsIntegrationQuery,
  UPDATE_FEES,
  UPDATE_CLINIC_SETTING,
  UPDATE_STRIPE_ID,
  PAYMENT_FEE_CONFIG,
  PAYMENT_FEE_COLLECTION,
  CREATE_FEES,
  CREATE_CLINIC_SETTING,
  UPDATE_OTC_DB,
};
