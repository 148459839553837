import React, { useState } from "react";

const Popup = (props) => {
  const [show, setShow] = useState(false);

  const showTooltip = () => {
    setShow(true);
  };

  const hideTooltip = () => {
    setShow(false);
  };

  return (
    <div onMouseOver={showTooltip} onMouseOut={hideTooltip}>
      {props.children}
      {show ? <div style={props.styles}>{props.content}</div> : ""}
    </div>
  );
};

export default Popup;
