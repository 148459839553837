import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { DASHBOARD } from "./reportingGraphQl";
import QuickSightReportingList from "./QuickSightReportingList";
import QuicksightReportIFrame from "./QuicksightReportIFrame";
import Loading from "../../app/loading";
import styled from "styled-components";

const Reporting = () => {
  const [dashboardName, setDashboardName] = useState(
    "Select a report to the left"
  );

  const [loading, setLoading] = useState(false);

  const { loading: dashboardLoading, data: dashboardData } =
    useQuery(DASHBOARD);

  if (dashboardLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <ReportListContainer>
        <QuickSightReportingList
          setDashboardName={setDashboardName}
          quickSightDashboardData={dashboardData}
          setLoading={setLoading}
        />
      </ReportListContainer>
      <Dashboard>
        <h1>{dashboardName}</h1>
        {loading ? <Loading /> : ""}
        <QuicksightReportIFrame />
      </Dashboard>
    </Container>
  );
};

export default Reporting;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;
const ReportListContainer = styled.div`
  flex: 0 0 auto;
  width: 250px;
  padding: 0px 20px;
  border-right: 1px solid rgb(232, 233, 233);
  overflow: auto;
  height: 100vh;
  display: block;
  font-weight: 600;
`;

const Dashboard = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 20px;
`;
