import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const CLINIC_PET_HISTORY = gql`
  query getClinicPetHistory($where: GetEntityHistoryInput!) {
    getClinicPetHistory(where: $where) {
      data
    }
  }
`;

function useClinicPetHistory() {
  const [getClinicPetHistory, { called, loading, data }] = useLazyQuery(
    CLINIC_PET_HISTORY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return [getClinicPetHistory, { called, loading, data }];
}
export { useClinicPetHistory, CLINIC_PET_HISTORY };
