import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useCareData } from "../query/useCareData";
import { reducer } from "./reducer";
const OrganizationCareContext = createContext();

const ACTIONS = { test: "test" };

export const useOrganizationCareContext = () =>
  useContext(OrganizationCareContext);

const initialState = {
  originalData: null,
  originalDataState: "Loading",
  draftBenefit: null,
};
export const OrganizationCareProvider = ({ orgId, children }) => {
  const { data, loading, refetch } = useCareData(orgId);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (!loading) {
      // Once we've finished loading
      console.log("loading");
      dispatch({
        type: "LoadData",
        data,
      });
    } else {
      console.log("set loading");
      dispatch({ type: "SetLoading" });
    }
  }, [data, loading]);
  useEffect(() => {
    console.log("effect", state.originalDataState);
    if (state.originalDataState === "Requesting") {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  useEffect(() => {
    window.dispatch = dispatch;
  }, [dispatch]);
  useEffect(() => {
    console.log("monitor", state.originalDataState, loading);
  }, [state, loading]);
  return (
    <OrganizationCareContext.Provider value={{ state, dispatch, ACTIONS }}>
      {children}
    </OrganizationCareContext.Provider>
  );
};
