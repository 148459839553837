import { memo } from 'react';
import styled from 'styled-components';

const deriveBackgroundColor = type => {
  if (type === 'success') {
    return '#66BB6A';
  }

  if (type === 'error') {
    return '#b00020';
  }

  return '#4287f5';
};

const SnackbarContent = memo(styled.div`
  display: flex;
  align-items: center;
  color: white;
  background-color: ${({ type }) => deriveBackgroundColor(type)};
  box-shadow: 0 3px 0 black;
  border-radius: 0.5em;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.9em;
  font-family: 'Roboto';

  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px 0 rgba(0, 0, 0, 0.05);

  transition: transform 250ms ease-in;
  transform: translateY(1rem);

  &.snackbar-content-enter-active {
    transform: translateY(0);
  }

  &.snackbar-content-enter-done {
    transform: translateY(0);
  }

  & + & {
    margin-top: 1em;
  }
`);

export default SnackbarContent;
