import moment from "moment";
import * as _ from "lodash";
function titleCase(str) {
  if (!str || typeof str !== typeof "") return str;
  let string = str.toLowerCase().split(" ");
  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(" ");
}

function customDeepen(key, inputValue) {
  const query = {};
  let currentNested = query;

  const keySegments = key.split(".");
  for (const keySegment of keySegments) {
    currentNested[keySegment] = {};
    currentNested = currentNested[keySegment];
  }

  if (
    typeof inputValue === "boolean" ||
    inputValue === "true" ||
    inputValue === "false"
  ) {
    currentNested["equals"] = inputValue === true || inputValue === "true";
  } else if (key.includes("At")) {
    if (inputValue.start) {
      currentNested["gte"] = inputValue.start;
    }
    if (inputValue.end) {
      currentNested["lte"] = inputValue.end || null;
    }
  } else {
    currentNested["contains"] = `${inputValue}`;
    currentNested["mode"] = "insensitive";
  }

  return query;
}

function transformStringFilters(filters) {
  if (!Object.keys(filters).length) return {};
  let returnFilters = { AND: [] };
  Object.keys(filters).forEach((key) => {
    const inputValue = filters[key] || "";

    if (!inputValue) return;
    var vals = [
      inputValue,
      inputValue.toLowerCase(),
      inputValue.toUpperCase(),
      titleCase(inputValue),
    ];
    var ors = [];
    vals.forEach((va) => {
      let or = {};
      or[`${key}_contains`] = va;
      ors.push(deepen(or));
    });
    returnFilters.AND.push({ OR: ors });
  });
  return returnFilters;
}
// export const cleanPhoneNumber = (phoneNumber) => {
//   phoneNumber = phoneNumber.replace(/\D+/g, “”);
//   return phoneNumber;
// };

function transformStringFiltersPrisma2(filters) {
  if (!filters) return {};
  if (!Object.keys(filters).length) return {};
  let returnFilters = {};
  Object.keys(filters).forEach((key) => {
    const inputValue = filters[key] || "";
    if (!inputValue) return;
    if (key.includes(".") && filters[key] && inputValue !== {}) {
      const query = customDeepen(key, inputValue);
      returnFilters = _.merge(returnFilters, query);
    } else {
      const inputToFloat = parseFloat(inputValue) && null;
      if (inputValue === "true" || inputValue === "false") {
        let setToBool = inputValue === "true";
        returnFilters[key] = { equals: setToBool };
      } else if (inputToFloat || inputToFloat === 0) {
        returnFilters[key] = { contains: inputToFloat };
      } else if (
        // eslint-disable-next-line valid-typeof
        (typeof inputValue === "date" || key === "createdAt") &&
        Object.keys(inputValue).length > 0
      ) {
        if (
          (Object.keys(inputValue).includes("start") &&
            Object.keys(inputValue).includes("end") &&
            inputValue["start"]) ||
          inputValue["end"]
        ) {
          let gte = inputValue["start"] ?? null;
          let lte = inputValue["end"] ?? null;
          if (gte && lte) {
            returnFilters[key] = { gte, lte };
          } else if (gte) {
            returnFilters[key] = { gte };
          } else if (lte) {
            returnFilters[key] = { lte };
          }
        } else if (
          !Object.keys(inputValue).includes("start") &&
          !Object.keys(inputValue).includes("end")
        ) {
          returnFilters[key] = { equals: inputValue };
        }
      } else if (
        inputValue &&
        inputValue !== {} &&
        Object.keys(inputValue).length > 0
      ) {
        if (returnFilters["AND"]) {
          const existingFilters = returnFilters["AND"].map((or, i) =>
            or["OR"].map((field) => {
              return {
                key: Object.keys(field)[0],
                index: i,
              };
            })
          );
          const existingFilterNames = existingFilters.map(
            (filter) => filter.key
          );
          if (existingFilterNames.includes(key)) {
            returnFilters["AND"][existingFilterNames.indexOf(key)]["OR"].push({
              [key]: { contains: inputValue, mode: "insensitive" },
            });
          } else {
            returnFilters["AND"].push({
              OR: inputValue.split(",").map((value) => {
                return {
                  [key]: {
                    contains: value.trim(),
                    mode: "insensitive",
                  },
                };
              }),
            });
          }
        } else {
          returnFilters["AND"] = [
            {
              OR: inputValue.split(",").map((value) => {
                return {
                  [key]: {
                    contains: value.trim(),
                    mode: "insensitive",
                  },
                };
              }),
            },
          ];
        }
      }
    }
  });
  return returnFilters;
}

function deepen(o) {
  var oo = {},
    t,
    parts,
    part;
  for (var k in o) {
    t = oo;
    parts = k.split(".");
    var key = parts.pop();
    while (parts.length) {
      part = parts.shift();
      t = t[part] = t[part] || {};
    }
    t[key] = o[k];
  }
  return oo;
}

function handleFilterConfigs({ filters, filterConfigs }) {
  const selects = {};
  const booleans = {};
  filterConfigs.forEach((config) => {
    if (config.type === "select" && filters[config.property]) {
      selects[config.property] = filters[config.property];
      delete filters[config.property];
    }
    if (config.type === "radio" && filters[config.property]) {
      if (config.handlePropertyFilter) {
        config.handlePropertyFilter({ filterEdit: booleans, filters });
      } else {
        booleans[config.property] = filters[config.property];
      }
      delete filters[config.property];
    }
    if (config.type === "checkbox" && filters[config.property]) {
      if (config.handlePropertyFilter) {
        config.handlePropertyFilter({ filterEdit: booleans, filters });
      } else {
        booleans[config.property] = filters[config.property];
      }
      delete filters[config.property];
    }
    if (config.type === "date" && filters[config.property]) {
      if (filters[config.property].start) {
        selects[`${config.property}`] = {
          ...selects[`${config.property}`],
          gte: new Date(filters[config.property].start),
        };
      }
      if (filters[config.property].end) {
        selects[`${config.property}`] = {
          ...selects[`${config.property}`],
          lte: moment(new Date(filters[config.property].end))
            .endOf("day")
            .toDate(),
        };
      }
      delete filters[config.property];
    }
  });
  return {
    ...transformStringFiltersPrisma2(filters),
    ...deepen(selects),
    ...deepen(booleans),
  };
}
export {
  titleCase,
  transformStringFilters,
  handleFilterConfigs,
  transformStringFiltersPrisma2,
};
