import React, { useGlobal } from "reactn";
import { FaCode } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { usePetsQuery, UPDATE_CLINIC_PET } from "./petGraphQL";
import { useMutation } from "@apollo/react-hooks";
import handleCheckbox from "../Utils/handleCheckbox";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
export default function Pets() {
  const [, setJson] = useGlobal("json");
  const history = useHistory();
  const [updatePet] = useMutation(UPDATE_CLINIC_PET, {
    errorPolicy: "all"
  });

  return (
    <PrismaServerFilterTable
      title="Pets"
      prismaVersion={2}
      queryName="findManyClinicPet"
      useQuery={usePetsQuery}
      queryVariables={{ orderBy: { name: "asc" }, where: {OR: [{isActive:{equals:true}}, {isActive: {equals: false}}]}, take: 80 }}
      filterConfigs={[
        { property: 'id', display: "ID"},
        { property: "name", display: "Name" },
        { property: "breed", display: "Breed" },
        { property: "petParents.some.firstName", display: "Owner FirstName" },
        { property: "petParents.some.lastName", display: "Owner LastName" },
        { property: "clinic.name", display: "Clinic" },
        {
          property: "isActive",
          type: "select",
          options: [
            { value: "true", display: "True" },
            { value: "false", display: "False" },
          ],
          display: "Is Active",
        },
        {
          property: "isDeleted",
          type: "select",
          options: [
            { value: "true", display: "True" },
            { value: "false", display: "False" },
          ],
          display: "Is Deleted",
        },
      ]}
      columns={[
        {
          Header: "",
          hidefilter: true,
          blockSort: true,
          accessor: ({ data }) => {
            return (
              <div>
                <FaCode
                  color="#2ABA66"
                  size={20}
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  title="View Json"
                  onClick={() => {
                    setJson({ type: "pets", data });
                    history.push("/json/view");
                  }}
                />
              </div>
            );
          },
        },
        {
          Header: "PIMS ID",
          accessor: ({ data }) => {
            return (
              data.pimsId ? data.pimsId : ''
            )
          }
        },
        {
          Header: "IsActive",
          accessor: ({ data }) => {
            const whereClause = {id: data.id}
            return (
              <input
                type="checkbox"
                defaultChecked={data.isActive}
                onClick={(event) =>
                  handleCheckbox(updatePet, whereClause, "isActive", event)
                }
                style={{ width: "100%", textAlign: "center" }}
              />
            );
          },
        },
        {
          Header: "IsDeleted",
          accessor: ({ data }) => {
            const whereClause = {id: data.id}
            return (
              <input
                type="checkbox"
                defaultChecked={data.isDeleted}
                onClick={(event) =>
                  handleCheckbox(updatePet, whereClause, "isDeleted", event)
                }
                style={{ width: "100%", textAlign: "center" }}
              />
            );
          },
        },
        { Header: "Name", accessor: "name" },
        { Header: "Gender", accessor: "gender" },
        { Header: "Breed", accessor: "breed" },
        { Header: "Born", accessor: "dateOfBirth" },
        {
          Header: "Clinic",
          accessor: ({ data: { clinic } }) => (clinic ? clinic.name : ""),
        },
        {
          Header: "Owner",
          accessor: ({ data: { petParents } }) =>
            petParents
              ? petParents
                  .map(
                    (petParent) =>
                      `${petParent.firstName} ${petParent.lastName}`
                  )
                  .join(", ")
              : "",
        },
        // {
        //   Header: "Consultations",
        //   accessor: ({ data: { consultations } }) => consultations.length,
        // },
      ]}
    />
  );
}
