/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
import React, { useState } from "reactn";
import { TwitterPicker as ColorPicker } from "react-color";
import { careThemeColors } from "../colors";
export default function CareAddon({ onSave: _onSave, addon, benefits }) {
  const stripeEnv = process.env.REACT_APP_ENV !== "production" ? "test/" : "";
  const baseStripeUrl = `https://dashboard.stripe.com/${stripeEnv}`;

  const id = addon?.id || "";
  const title = useInput(addon?.title || "");
  const description = useInput(addon?.description, "");
  const published = useInput(addon?.published || false);
  const stripePriceId = useInput(addon?.stripePriceId || "");
  const pricePerRenewal = useInput(addon?.pricePerRenewal || 0);
  const billingCycle = useInput(addon?.billingCycle || "Monthly");
  const renewalCycle = useInput(addon?.renewalCycle || "Monthly");
  const targetSpecies = useInput(addon?.targetSpecies || null);
  const [themeColor, setThemeColor] = useState(null);
  const earliestTargetPetAgeInMonths = useInput(
    addon?.earliestTargetPetAgeInMonths || null
  );
  const latestTargetPetAgeInMonths = useInput(
    addon?.latestTargetPetAgeInMonths || null
  );
  const [activeAddonBenefits, setActiveAddonBenefits] = useState([]);

  useEffect(() => {
    setActiveAddonBenefits(addon.addonPackageBenefit || []);
    title.setValue(addon?.title || "");
    description.setValue(addon?.description || "");
    published.setValue(addon?.published || false);
    stripePriceId.setValue(addon?.stripePriceId || "");
    setThemeColor(addon.themeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addon, benefits]);

  const onSave = _onSave || (() => {});

  const save = () => {
    onSave({
      id: id,
      title: title.value,
      description: description.value,
      published: published.value || false,
      stripePriceId: stripePriceId.value,
      pricePerRenewal: +pricePerRenewal.value || 0,
      billingCycle: billingCycle.value,
      renewalCycle: renewalCycle.value,
      earliestTargetPetAgeInMonths: earliestTargetPetAgeInMonths.value
        ? +earliestTargetPetAgeInMonths.value
        : null,
      latestTargetPetAgeInMonths: latestTargetPetAgeInMonths.value
        ? +latestTargetPetAgeInMonths.value
        : null,
      targetSpecies:
        targetSpecies.value === "none" ? null : targetSpecies.value,
      activeAddonBenefits,
      themeColor,
    });
  };

  const swapOrder = (idx1, idx2) => {
    setActiveAddonBenefits((bs) => {
      if (idx1 < 0 || idx1 >= bs.length || idx2 < 0 || idx2 >= bs.length) {
        return bs;
      }

      const newBs = [...bs];
      const temp = newBs[idx1];
      newBs[idx1] = newBs[idx2];
      newBs[idx2] = temp;
      return newBs;
    });
  };
  const addAddonBenefit = (benefit) => {
    setActiveAddonBenefits((prior) => [
      ...prior,
      {
        addonId: addon.id,
        includedUses: benefit.tangible ? 1 : null,
        benefit: benefit,
      },
    ]);
  };
  const removeAddonBenefit = (index) => {
    setActiveAddonBenefits((items) => items.filter((_, idx) => idx !== index));
  };
  const otherBenefits = benefits.filter(
    (benefit) => !activeAddonBenefits.find((b) => b.benefit.id === benefit.id)
  );
  const addonBenefits = activeAddonBenefits;

  const updateIncludedUses = (index, value) => {
    setActiveAddonBenefits((prev) => {
      return prev.map((b, _idx) => {
        if (index === _idx) {
          return { ...b, includedUses: value };
        } else {
          return b;
        }
      });
    });
  };

  const updateProjectedSavings = (index, value) => {
    setActiveAddonBenefits((prev) => {
      return prev.map((b, _idx) => {
        if (index === _idx) {
          return { ...b, projectedSavings: value };
        } else {
          return b;
        }
      });
    });
  };
  console.log(addonBenefits);

  return (
    <div style={{ background: "#DDDDFF", minWidth: 300, minHeight: 300 }}>
      <div>
        <label>Title</label>
        <input {...title} />
      </div>
      <div>
        <label>Description</label>
        <textarea {...description} />
      </div>
      <div>
        <label>Published</label>
        <input type="checkbox" {...published} checked={published.value} />
      </div>
      <div>
        <label>Stripe Price Id - (Copy over from the Stripe Dashboard)</label>
        <input {...stripePriceId} />
        {!addon.stripePriceId ? (
          <a
            href="https://dashboard.stripe.com/test/products/create"
            target="_blank"
          >
            Create
          </a>
        ) : (
          <a
            href={`${baseStripeUrl}prices/${addon.stripePriceId}`}
            target="_blank"
          >
            Update
          </a>
        )}
      </div>
      <div>
        <label>Price Per Renewal (in cents)</label>
        <input {...pricePerRenewal} type="number" />
      </div>
      <div>
        <label>Billing Cycle</label>
        <select {...billingCycle}>
          <option value="Monthly">Monthly</option>
          <option value="Semianually">Semianually</option>
          <option value="Annually">Annually</option>
        </select>
      </div>
      <div>
        <label>Renewal Cycle</label>
        <select {...renewalCycle}>
          <option value="Monthly">Monthly</option>
          <option value="Semianually">Semianually</option>
          <option value="Annually">Annually</option>
        </select>
      </div>
      <div>
        <label>Target Species</label>
        <select {...targetSpecies}>
          <option value="none">All</option>
          <option value="Feline">Feline</option>
          <option value="Canine">Canine</option>
          <option value="Avian">Avian</option>
          <option value="Bovine">Bovine</option>
          <option value="Equine">Equine</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div>
        <label>Earliest Target Pet Age in Months</label>
        <input type="number" {...earliestTargetPetAgeInMonths} />
      </div>
      <div>
        <label>Latest Target Pet Age in Months</label>
        <input type="number" {...latestTargetPetAgeInMonths} />
      </div>
      <div>
        <div
          style={{
            width: 50,
            height: 50,
            border: "1px solid black",
            backgroundColor: themeColor,
            marginBottom: "5px",
          }}
        />
        <ColorPicker
          colors={careThemeColors}
          color={themeColor || "#FFF"}
          onChange={(color) => {
            setThemeColor(color.hex);
          }}
        />
        <button onClick={() => setThemeColor(null)}>Clear</button>
      </div>

      <h3>Addon Benefits</h3>
      <div>
        {addonBenefits.map((pb, idx) => {
          return (
            <div style={{ margin: 10 }}>
              {pb.benefit.title} -{" "}
              {pb.benefit.tangible ? (
                <input
                  type="number"
                  onChange={(e) => {
                    updateIncludedUses(idx, e.target.value);
                  }}
                  value={pb.includedUses}
                />
              ) : (
                "included"
              )}
              <button onClick={() => swapOrder(idx, idx - 1)}>Move Up</button>
              <button onClick={() => swapOrder(idx, idx + 1)}>Move Down</button>
              <button onClick={() => removeAddonBenefit(idx)}>Remove</button>
              <div>
                Projected Savings{" "}
                <input
                  type="string"
                  onChange={(e) => {
                    updateProjectedSavings(
                      idx,
                      e.target.value.trim() === "" ? null : e.target.value
                    );
                  }}
                  value={pb.projectedSavings}
                />
              </div>
            </div>
          );
        })}
      </div>
      <h3>Available</h3>
      {otherBenefits.map((benefit) => {
        return (
          <div>
            {benefit.title} -{" "}
            <button
              onClick={() => {
                addAddonBenefit(benefit);
              }}
            >
              Add
            </button>
          </div>
        );
      })}
      <button
        onClick={() => {
          save();
        }}
      >
        Save
      </button>
    </div>
  );
}

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setValue((value) => !value);
    } else {
      setValue(event.target.value);
    }
  };
  return {
    value,
    setValue,
    onChange: handleChange,
  };
};
