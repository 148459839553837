import React, { useState, useGlobal } from "reactn";
import { useHistory } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_FEATURE_FLAG, FIND_CLINIC } from "../queries";
import { UPDATE_FEATURE_FLAG } from "../mutations";
import styled from "styled-components/macro";
import { Row } from "../../../../styledComponents/grid";
import { Form, Input, Button } from "../../../../styledComponents/form";
import { FaArrowLeft, FaFileExcel } from "react-icons/fa";
import { exportToCSV, mapExportData } from "../../Utils/exportCsv";
import { useCallback } from "react";
import { format } from "date-fns";

const ManageClinicFeatureFlags = ({ match }) => {
  const history = useHistory();
  const [, setClinicName] = useGlobal("clinicName");

  const featureFlagId = match.params.id;
  const [formState, setFormState] = useState({
    id: "",
    name: "",
  });
  const { data: featureFlagData } = useQuery(GET_FEATURE_FLAG, {
    skip: !featureFlagId,
    variables: {
      where: { id: featureFlagId },
    },
  });

  const { clinics, key: flagName, description } = featureFlagData?.featureFlag || {};

  const [modalState, setModalState] = useGlobal("modalState");

  const [updateFeatureFlag, { loading: updateFeatureFlagLoading }] =
    useMutation(UPDATE_FEATURE_FLAG, {
      errorPolicy: "all",
    });

  const [findClinics, { data: clinicData }] = useLazyQuery(FIND_CLINIC);

  const onChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const clinicIdsInFeatureFlag = (
      clinics || []
    ).map((clinic) => {
      return clinic.id;
    });

    let where = {
      id: { not: { in: clinicIdsInFeatureFlag } },
    };

    if (formState.name) {
      where = {
        ...where,
        name: { contains: formState.name, mode: "insensitive" },
      };
    }

    if (formState.id) {
      where = {
        ...where,
        id: {
          in: formState.id.replace(/\s/gm, "").split(","),
        },
      };
    }

    findClinics({
      variables: {
        where,
      },
    });
  };

  const onAdd = async (id) => {
    await updateFeatureFlag({
      variables: {
        where: {
          id: featureFlagId,
        },
        data: {
          clinics: { connect: { id } },
        },
      },
    });
  };

  const onRemove = async (id) => {
    await updateFeatureFlag({
      variables: {
        where: {
          id: featureFlagId,
        },
        data: {
          clinics: { disconnect: { id } },
        },
      },
    });
  };

  const showBatchAddModal = (idArray) => {
    setModalState({
      ...modalState,
      modalHeader: "Confirm Mass Flagging",
      modalContent: (
        <p>
          Are you sure you want to add all {idArray.length} clinics to this
          feature flag? Make doubly certain all of these clinics fit the
          criteria for the feature flag. <br />
          <br />
          <strong>{flagName}</strong> :{" "}
          {description}
        </p>
      ),
      showModal: true,
      hideButtons: false,
      modalConfirm: () => batchAdd(idArray),
    });
  };

  const showBatchRemoveModal = (idArray) => {
    setModalState({
      ...modalState,
      modalHeader: "Confirm Mass Flag Removal",
      modalContent: (
        <p>
          Are you sure you want to remove all {idArray.length} clinics from this
          feature flag? This will not remove all{" "}
          {clinics.length} clinics from the
          feature flag, only the {idArray.length} clinics you've selected.
          <br />
          <br />
          <strong>{flagName}</strong> :{" "}
          {description}
        </p>
      ),
      showModal: true,
      hideButtons: false,
      modalConfirm: () => batchRemove(idArray),
    });
  };

  const batchRemove = async (idArray) => {
    await updateFeatureFlag({
      variables: {
        where: {
          id: featureFlagId,
        },
        data: {
          clinics: {
            disconnect: idArray.map((id) => ({ id })),
          },
        },
      },
    });
  };

  const batchAdd = async (idArray) => {
    await updateFeatureFlag({
      variables: {
        where: {
          id: featureFlagId,
        },
        data: {
          clinics: {
            connect: idArray.map((id) => ({ id })),
          },
        },
      },
    });
  };

  const exportCSV = useCallback(() => {
    const data = clinics?.map((d) => mapExportData(d, [
      {Header: 'Id', accessor: 'id'},
      {Header: 'Name', accessor: 'name'},
    ]));
    const date = format(new Date(), 'yyyy-MM-dd');
    const name = `${flagName}_${date}`;
    exportToCSV(data, name, name);
  }, [clinics, flagName]);

  return (
    <Container>
      <Row>
        <Header>
          <h1>
            <BackArrow onClick={() => history.push("/feature-flags")} /> Manage
            clinic to feature flag
          </h1>
        </Header>
      </Row>
      <ContentContainer>
        <ContentItem>
          <ContentHeader>
            <H1>Find a Clinic</H1>
          </ContentHeader>
          <Form onSubmit={onSearch}>
            <InputWrapper>
              <Label>Search by Id</Label>
              <Input
                name="id"
                type="text"
                value={formState.id}
                onChange={onChange}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Search by Name</Label>
              <Input
                name="name"
                type="text"
                value={formState.name}
                onChange={onChange}
              />
            </InputWrapper>
            <ActionButton 
              style={{'margin-bottom': '5px'}} 
              type="submit">
                Search
            </ActionButton>
          </Form>
          <Table>
            {clinicData?.findManyClinic && (
              <TableRow>
                <TableHeaderItem></TableHeaderItem>
                <TableHeaderItem>Id</TableHeaderItem>
                <TableHeaderItem>Name</TableHeaderItem>
              </TableRow>
            )}
            {(clinicData?.findManyClinic || []).map((clinic) => {
              if (
                clinics?.find(
                  ({ id }) => clinic.id === id
                )
              ) {
                return (
                  <TableRow
                    style={{ fontStyle: "italic", color: "grey" }}
                    key={clinic.id}
                  >
                    <TableItem>
                      <ActionButton type="button" disabled={true}>
                        Added
                      </ActionButton>
                    </TableItem>
                    <TableItem>{clinic.id}</TableItem>
                    <TableItem>{clinic.name}</TableItem>
                  </TableRow>
                );
              }

              return (
                <TableRow key={clinic.id}>
                  <TableItem>
                    <ActionButton
                      type="button"
                      disabled={updateFeatureFlagLoading}
                      onClick={() => onAdd(clinic.id)}
                    >
                      Add
                    </ActionButton>
                  </TableItem>
                  <TableItem>{clinic.id}</TableItem>
                  <TableItem>{clinic.name}</TableItem>
                </TableRow>
              );
            })}
          </Table>
            {clinicData?.findManyClinic.length > 0 ? (
              <ButtonsWrapper>
                <ActionButton
                  type="button"
                  disabled={
                    updateFeatureFlagLoading ||
                    !clinicData?.findManyClinic.length
                  }
                  onClick={() =>
                    showBatchAddModal(
                      clinicData?.findManyClinic.map(({ id }) => id)
                    )
                  }
                >
                  Add All
                </ActionButton>
                <ActionButton
                  type="button"
                  style={{
                    backgroundColor: "rgb(175,0,0)",
                    border: "none",
                    marginLeft: "15px",
                  }}
                  disabled={
                    updateFeatureFlagLoading ||
                    !clinicData?.findManyClinic.length
                  }
                  onClick={() =>
                    showBatchRemoveModal(
                      clinicData?.findManyClinic.map(({ id }) => id)
                    )
                  }
                >
                  Remove All
                </ActionButton>
              </ButtonsWrapper>
            ) : null}
        </ContentItem>
        <ContentItem style={{ flex: 2, 'overflow-x': 'scroll' }}>
          <Header>
            <TableTitleContainer>
              <H1>{`Current Clinics in ${flagName}`}</H1>
              <ActionButton 
                onClick={() => exportCSV()}
                disabled={!clinics?.length}
                style={{marginLeft: 'auto'}}>
                <FaFileExcel size={18} color="green" title="Export" />
              </ActionButton>
            </TableTitleContainer>
            <Table>
              {clinics?.length > 0 && (
                <TableRow>
                  <TableHeaderItem></TableHeaderItem>
                  <TableHeaderItem></TableHeaderItem>
                  <TableHeaderItem>Id</TableHeaderItem>
                  <TableHeaderItem>Name</TableHeaderItem>
                </TableRow>
              )}

              {(clinics || []).map((clinic, index) => {
                return (
                  <TableRow key={clinic.id}>
                    <TableItem >{index + 1}</TableItem>
                    <TableItem>
                      <ActionButton
                        type="button"
                        disabled={updateFeatureFlagLoading}
                        onClick={() => onRemove(clinic.id)}
                      >
                        Remove
                      </ActionButton>
                    </TableItem>
                    <TableItem style={{ flex: "1" }}>{clinic.id}</TableItem>
                    <TableItem>
                      <ActionButton
                        onClick={() => {
                          history.push(`/feature-flags/clinic/${clinic.id}`);
                          setClinicName(clinic.name);
                        }}
                      >
                        {clinic.name}
                      </ActionButton>
                    </TableItem>
                  </TableRow>
                );
              })}
            </Table>
          </Header>
        </ContentItem>
      </ContentContainer>
    </Container>
  );
};

export default ManageClinicFeatureFlags;

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  margin-top: 10px;
  text-align: left;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const ContentItem = styled.div`
  position: relative;
  flex: 1;
  margin: 1em;
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;
  }
`;

const TableTitleContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const ContentHeader = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;

const Label = styled.label`
  font-size: 1em;
  color: #434a54;
  padding-bottom: 5px;
  display: block;
`;

const H1 = styled.h1`
  font-size: 1.1em;
  font-weight: 600;
`;

const Table = styled.table`
  width: 100%;
  tr:nth-child(even) {
    background-color: #fefefe;
  }
`;

const TableRow = styled.tr`
`;

const TableItem = styled.td`
  padding: 0.5em;
`;

const TableHeaderItem = styled.th`
  padding: 0.5em;
  font-weight: 600;
  text-align: left;
  background-color: #e6e9ed;
`;

const ActionButton = styled(Button)`
  float: none;
`;

const BackArrow = styled(FaArrowLeft)`
  position: relative;
  top: 2px;
  cursor: pointer;
  font-size: 0.8em;
`;

const ButtonsWrapper = styled.div`
  margin-top: 5px;
`;
