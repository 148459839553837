import React, { useState } from "react";
import styled from "styled-components/macro";
import { useMutation } from "@apollo/react-hooks";
import Container from "../../../styledComponents/container";
import { Column1 } from "../../../styledComponents/grid";
import {
  TextArea,
  Form,
  Select,
  SaveButton,
} from "../../../styledComponents/form";
import { SEND_UI_REFRESH_EVENT } from "./mutations/sendUIRefreshEvent";

export const UIRefresh = () => {
  const [form, setForm] = useState({ refreshType: "SuggestRefresh" });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [sendUIRefreshEvent, { loading, error, data }] = useMutation(
    SEND_UI_REFRESH_EVENT
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });

    if (name === "message" && value !== "") {
      setIsSubmitDisabled(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendUIRefreshEvent({
      variables: {
        data: {
          refreshType: form.refreshType,
          message: form.message,
        },
      },
    });
  };

  return (
    <Container>
      <Column1>
        <Title>UI Refresh</Title>
        <Subtitle>
          A Suggest Refresh sends a notification to clinics whenever we need
          them to refresh their browser. While a Hard Refresh will automatically
          refresh their browsers for them.
        </Subtitle>
      </Column1>
      <Form onSubmit={handleSubmit}>
        <Column1>
          <Label className="required">Refresh Type</Label>
          <Select
            name="refreshType"
            value={form.refreshType}
            onChange={handleChange}
          >
            <option value="SuggestRefresh">Suggest Refresh</option>
            <option value="HardRefresh">Hard Refresh</option>
          </Select>
        </Column1>
        <Column1>
          <Label className="required">Message</Label>
          <TextArea
            name="message"
            value={form.message}
            onChange={handleChange}
          />
        </Column1>
        <ActionsContainer>
          <SaveButton type="submit" disabled={isSubmitDisabled || loading}>
            Trigger Refresh
          </SaveButton>
        </ActionsContainer>
      </Form>
      {error && (
        <p>There was an error triggering the refresh, please try again.</p>
      )}
      {data && <p>Refresh triggered succesfully!</p>}
    </Container>
  );
};

export default UIRefresh;

export const Title = styled.h3`
  margin-bottom: 0;
`;

const Subtitle = styled.p`
  margin: 10px 0 50px;
`;

const Label = styled.p`
  font-weight: 600;

  &.required::after {
    color: red;
    content: " *";
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 30px;
  width: 100%;
`;
