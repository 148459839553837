import React from "reactn";
import styled from "styled-components/macro";

const List = (props) => {
  const { celled, children } = props;
  const Item = styled.div`
    font-size: 1em;
    list-style-type: none;
    margin: 1em 0;
    padding: 0 0;
    color: hsl(227deg 26% 26%);
    ${celled ? "border: 1px solid rgba(34, 36, 38, 0.4);" : ""}
  `;
  return <Item {...props}>{children}</Item>;
};

const ListItem = (props) => {
  const { celled, children, selected } = props;
  const Item = styled.div`
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    ${celled ? "border: 1px solid rgba(34, 36, 38, 0.15);" : ""}
    padding: 0.5em;
    cursor: pointer;
    ${selected ? "background-color: #656D78; color: #F5F7FA;" : ""}
  `;
  return <Item {...props}>{children}</Item>;
};
const ListItemAvatar = styled.img`
  width: 2em;
  height: 2em;
  border-radius: 500rem;
`;

const ListItemHeader = styled.div`
  display: block;
  margin: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700;
  svg {
    position: relative;
    top: 4px;
    padding-right: 8px;
  }
`;
const ListItemContent = styled.div`
  display: block;
  margin-top: 12px;
`;
export { List, ListItem, ListItemAvatar, ListItemHeader, ListItemContent };
